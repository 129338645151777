import React, { useEffect, useState, useRef } from 'react';
import ReactPlayer from 'react-player';
import { isIOS, deviceType } from 'react-device-detect';
import { Box } from '@material-ui/core';
import SOMACONFIG from '../../../config';
import { useEventDispatch, somaEvents, gtmEvents } from '../../../events';
import { usePlayerWrapper } from './styles';
import { useVideoContext } from '../../../contexts/videoContext';
import { useEvent } from '../../../events/useEvent';

const SomaTube = (props) => {
  const { setPlayer, url, rerunStartTime, isRerun } = props;
  const videoPlayedProgressCheckpoints = [25, 50, 75];
  const percentEventsFired = useRef(new Set());
  const [playing, setPlaying] = useState(false);
  const timeTracker = useRef({ datetime: new Date(), intervalId: NaN });
  const dispatchEvent = useEventDispatch();
  const player = useRef(null);
  const playerWrapper = usePlayerWrapper();
  const { setPlayedSeconds } = useVideoContext();

  useEffect(() => {
    setPlayer({
      play: () => {
        setPlaying(true);
        dispatchEvent(somaEvents.onVideoStart);
        timeTracker.current.datetime = new Date();
      },
      pause: () => {
        setPlaying(false);
        dispatchEvent(somaEvents.onVideoPause);
        clearInterval(timeTracker.current.intervalId);
      },
    });
  }, [setPlayer, dispatchEvent]);

  useEffect(() => {
    if (playing) {
      timeTracker.current.intervalId = setInterval(() => {
        const diff = new Date() - timeTracker.current.datetime;
        const elapsedSeconds = Math.round(diff / 1000);
        dispatchEvent(somaEvents.onVideoElapsedTime, elapsedSeconds);
      }, SOMACONFIG.videoTrackingInterval);
    }
    const { intervalId } = timeTracker.current;
    return () => {
      clearInterval(intervalId);
    };
  }, [dispatchEvent, playing]);

  const playerStartTime = () => {
    if (!player.current && !player.current.props) return;

    player.current.seekTo(player.current.props.time || 0, 'seconds');
  };

  const reactPlayerConfig = isIOS && deviceType === 'mobile' ? {} : { file: { forceHLS: true } };

  useEvent(somaEvents.onSeekToProductMoment, (seconds) => player.current.seekTo(seconds, 'seconds'));

  return (
    <Box
      classes={playerWrapper}
      onClick={() => {
        if (!playing) setPlaying(true);
      }}
    >
      <ReactPlayer
        ref={player}
        style={{
          position: 'absolute',
          top: 0,
          left: 0,
        }}
        url={url}
        config={reactPlayerConfig}
        volume={1}
        playing={playing}
        playsinline
        width="100%"
        height="100%"
        controls={isRerun === 1}
        time={rerunStartTime}
        onStart={playerStartTime}
        onPause={() => {
          dispatchEvent(gtmEvents.onVideoPause);
        }}
        onProgress={(progress) => {
          const { played = 0, playedSeconds } = progress;
          const progressPercent = parseInt(played * 100, 10);
          const shouldDispatch =
            videoPlayedProgressCheckpoints.includes(progressPercent) &&
            !percentEventsFired.current.has(progressPercent);
          const playedSecondsMs = playedSeconds * 1000;
          setPlayedSeconds(playedSecondsMs);

          if (shouldDispatch) {
            percentEventsFired.current.add(progressPercent);
            dispatchEvent(gtmEvents.onVideoProgress, {
              video_current_time: parseInt(playedSeconds, 10),
              video_percent: progressPercent,
            });
          }
        }}
      />
    </Box>
  );
};

export default SomaTube;
