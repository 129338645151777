import React from 'react';
import { useTheme } from '@material-ui/core/styles';
import {
  Dialog,
  DialogContent,
  DialogTitle,
  DialogContentText,
  DialogActions,
  Card,
  CardContent,
  Typography,
  Box,
  Button,
} from '@material-ui/core/';
import { Close } from '@material-ui/icons';
import Style from './style';

const FaqContent = ({ title, description }) => {
  const cardClasses = Style.useCardStyle();

  return (
    <Card className={cardClasses.root}>
      <CardContent className={cardClasses.container}>
        <Typography className={cardClasses.title}>{title}</Typography>
        <Typography className={cardClasses.text}>{description}</Typography>
      </CardContent>
    </Card>
  );
};

const SomaFAQ = ({ open, setOpen, noDialog = true }) => {
  const theme = useTheme();
  const { content } = theme.faq;

  return (
    <Box style={{ overflow: 'auto' }}>
      {noDialog && content?.map((question) => <FaqContent key={question.title} {...question} />)}

      {!noDialog && (
        <Dialog open={open}>
          <DialogActions>
            <Button onClick={() => setOpen(false)}>
              <Close fontSize="small" />
            </Button>
          </DialogActions>
          <DialogContent>
            {content?.map((question) => (
              <React.Fragment key={question.title}>
                <DialogTitle component="div">
                  <Box fontWeight="fontWeightBold">{question.title}</Box>
                </DialogTitle>
                <DialogContentText>{question.description}</DialogContentText>
              </React.Fragment>
            ))}
          </DialogContent>
        </Dialog>
      )}
    </Box>
  );
};

export default SomaFAQ;
