import React, { useContext, useEffect, useState } from 'react';
import { Grid } from '@material-ui/core';
import { useTheme } from '@material-ui/core/styles';
import PropTypes from 'prop-types';
import { Send } from '@mui/icons-material';
import { SomaButton, InfoCheckout, SomaCartUrlInput } from '../../atoms';
import SomaInstallmentText from '../InstallmentText';
import Styles from './style';
import { CartSimulationContext, SalesWomanContext, StreamContext } from '../../../hooks/contexts';
import { useEventDispatch, newRelicEvents } from '../../../events';

const Checkout = (props) => {
  const { discountValue, subtotalValue, totalValue, onCheckout, onCopyClick, bagUrl, portrait } = props;
  const [showUrlShare, setShowUrlShare] = useState(false);
  const [installments, setInstallments] = useState();

  const theme = useTheme();
  const { saleswomanCouponCode } = useContext(SalesWomanContext);
  const cartSimulationContext = useContext(CartSimulationContext);
  const { sendSnack } = useContext(StreamContext);
  const buttonClasses = Styles.useButtonStyle();

  const dispatchEvent = useEventDispatch();

  useEffect(() => {
    try {
      const getInstallmentOptions = cartSimulationContext.paymentData.installmentOptions;
      const installmentsCount = getInstallmentOptions.map((prod) => ({
        installments: prod.installments[prod.installments.length - 1].count,
        value: prod.installments[prod.installments.length - 1].value / 100,
      }));
      const maxInstallments = installmentsCount.reduce((prevIns, currIns) =>
        prevIns.installments > currIns.installments ? prevIns : currIns
      );
      setInstallments(maxInstallments);
    } catch (error) {
      dispatchEvent(newRelicEvents.log, {
        type: 'Error',
        message: error.message,
        path: 'Checkout/index.js -> useEffect[0]',
      });
      setInstallments({});
    }
  }, [cartSimulationContext, dispatchEvent]);

  const { discountText, totalText, subtotalText } = theme.checkout;
  const buttonCheckout = {
    label: theme.checkout.button.text,
    variant: theme.checkout.button.variant,
    color: theme.checkout.button.color,
    onClick: onCheckout,
  };

  const buttonShareUrl = {
    variant: theme.checkout.button.variant,
    color: theme.bag.urlSharer.background,
    borderColor: theme.bag.urlSharer.color,
    textColor: theme.bag.urlSharer.color,
    fontWeight: theme.bag.urlSharer.buttonFontWeight,
  };

  const inputShareUrl = {
    ...buttonShareUrl,
    sendSnack,
    successCopyText: theme.bag.urlSharer.successCopyText,
    errorCopyText: theme.bag.urlSharer.errorCopyText,
    label: theme.bag.urlSharer.textSecondStep,
    onClick: onCopyClick,
    url: bagUrl,
  };

  const buttonShowInputShare = {
    ...buttonShareUrl,
    label: theme.bag.urlSharer.textFirstStep,
    onClick: () => setShowUrlShare(true),
    startIcon: <Send />,
  };

  const cupomValue = saleswomanCouponCode || theme.checkout.cupomCodeValue;
  const cupomCodeText = theme.checkout.cupomCodeText.replace('<CUPOMCODEVALUE>', cupomValue.toUpperCase());

  const formattedPrice = (price) =>
    new Intl.NumberFormat(theme.i18n.locale, {
      style: 'currency',
      currency: theme.i18n.currency,
      maximumFractionDigits: 2,
    }).format(price);

  const config = {
    align: portrait ? 'center' : 'right',
  };

  const alignPortrait = {
    justify: portrait ? 'flex-end' : 'flex-end',
  };

  return (
    <Grid container spacing={1}>
      <Grid item xs={12}>
        <InfoCheckout textValue={subtotalText} priceValue={formattedPrice(subtotalValue)} />
      </Grid>
      <Grid item xs={12}>
        <InfoCheckout textValue={discountText} priceValue={formattedPrice(discountValue)} />
      </Grid>
      {!!cupomValue && (
        <Grid item xs={12}>
          <InfoCheckout contentAlign="center" couponValue={cupomCodeText} />
        </Grid>
      )}
      <Grid item xs={12}>
        <InfoCheckout textValue={totalText} priceValue={formattedPrice(totalValue)} />
      </Grid>
      {installments?.value && (
        <Grid item container xs={12} justify={alignPortrait.justify}>
          <SomaInstallmentText value={totalValue} align={config.align} bagInstallments={installments} />
        </Grid>
      )}
      <Grid item xs={12}>
        <SomaButton {...buttonCheckout} classes={buttonClasses} />
      </Grid>
      <Grid item xs={12}>
        {showUrlShare ? <SomaCartUrlInput {...inputShareUrl} /> : <SomaButton {...buttonShowInputShare} />}
      </Grid>
    </Grid>
  );
};

Checkout.defaultProps = {
  totalText: 'Total',
  subtotalText: 'Subtotal',
  discountText: 'Desconto',
  coupon: true,
};

Checkout.propTypes = {
  totalText: PropTypes.string,
  totalValue: PropTypes.number.isRequired,
  subtotalText: PropTypes.string,
  subtotalValue: PropTypes.number.isRequired,
  discountText: PropTypes.string,
  discountValue: PropTypes.number.isRequired,
  coupon: PropTypes.bool,
};

export default Checkout;
