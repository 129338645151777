import { ptBR } from '@material-ui/core/locale';
import { themeTemplate, colorTransform } from './somalive';
import config from '../config';

const welcomeBackgroundDesktop = `${config.assetsUrl}/fabula/images/welcomeBg.jpg`;
const welcomeBackgroundMobile = `${config.assetsUrl}/fabula/images/welcomeBg.jpg`;
const welcomeIcon = `${config.assetsUrl}/fabula/images/welcomeIcon.svg`;
const faqIcon = `${config.assetsUrl}/fabula/icons/ajuda.svg`;
const bagIcon = `${config.assetsUrl}/fabula/icons/sacola.svg`;
const productIcon = `${config.assetsUrl}/fabula/icons/produtos.svg`;
const chatIcon = `${config.assetsUrl}/fabula/icons/chat.svg`;
const couponImage = `${config.assetsUrl}/fabula/images/cupom.png`;

const faviconFabula = `${config.assetsUrl}/common/favicons/fabula.png`;

const colorIntensity = 50;
const template = themeTemplate({
  mainColor: '#5F5F5F',
  secondaryColor: '#E98258',
  fontFamily: 'HeroNew',
  badgeBgColor: '#E98258',
  badgeColor: 'white',
  buttonsBorderRadius: 0,
});

const main = {
  palette: {
    primary: colorTransform(template.mainColor, colorIntensity),
    secondary: colorTransform(template.secondaryColor, colorIntensity),
  },
  typography: {
    fontFamily: template.fontFamily,
  },
  overrides: {
    MuiTypography: {
      root: {
        color: template.mainColor,
      },
    },
    MuiButton: {
      root: {
        borderRadius: template.buttonsBorderRadius,
      },
    },
    MuiAlert: {
      root: {
        color: template.mainColor,
      },
      standardSuccess: {
        color: template.mainColor,
        backgroundColor: '#fff',
        '& $icon': {
          color: template.mainColor,
        },
      },
      standardInfo: {
        color: template.mainColor,
        backgroundColor: '#fff',
        '& $icon': {
          color: template.mainColor,
        },
      },
    },
  },
  faq: {
    text: 'Ajuda',
    icon: {
      src: faqIcon,
      alt: 'Ajuda',
    },
    content: [
      {
        title: 'o que é a lojinha fábula?',
        description: 'lojinha fábula é a nossa primeira loja virtual 100% digital e interativa.',
      },
      {
        title: 'posso tirar minhas dúvidas?',
        description: 'sim! você pode interagir ou enviar uma mensagem ao vivo pelo ícone "chat"',
      },
      {
        title: 'como vejo os produtos apresentados?',
        description:
          'clique no ícone "produtos" no canto esquerdo da tela e navegue pelas categorias pra melhor visualização.',
      },
      {
        title: 'posso comprar em tempo real?',
        description:
          'sim! escolha o produto, clique em "adicionar à sacola" e em seguida selecione o seu tamanho. o produto será imediatamente adicionado à sacola, que está localizada no canto superior direito da tela.',
      },
      {
        title: 'como finalizo minha compra?',
        description:
          'clique no ícone "sacola" no canto superior direito da tela e depois em "finalizar compra". a gente vai te direcionar pro nosso site pra efetuar o pagamento de forma rápida e segura.',
      },
    ],
  },
  bag: {
    text: 'Sacola',
    emptyBag: 'Sua sacola está vazia',
    emptyBagColor: 'secondary',
    icon: {
      src: bagIcon,
      alt: 'Sacola',
    },
  },
  products: {
    text: 'Produtos',
    icon: {
      src: productIcon,
      alt: 'Produtos',
    },
    size: {
      text: 'Selecione o Tamanho:',
      typography: {
        variant: 'subtitle1',
      },
    },
    details: {
      text: 'Detalhes do Produto',
      button: {
        variant: 'text',
        fullWidth: false,
      },
    },
    button: {
      text: 'adicionar à sacola',
      variant: 'outlined',
      color: 'primary',
    },
    composition: {
      text: 'Composição',
    },
    name: {
      color: 'primary',
    },
    price: {
      sale: {
        color: 'primary',
      },
    },
    quantity: {
      variant: 'subtitle1',
    },
    notAvaiable: {
      text: 'Produto Indisponível',
    },
    search: {
      title: 'Pesquisar produtos',
      notFound: 'Produto não encontrado',
      emptyBrand: 'É necessário fornecer uma brand para pesquisar o produto',
    },
  },
  welcomePage: {
    paper: {
      backgroundImageDesktop: `url(${welcomeBackgroundDesktop})`,
      backgroundImageMobile: `url(${welcomeBackgroundMobile})`,
      backgroundPosition: 'left top',
    },
    container: {
      justify: 'center',
      header: {
        maxWidthDesktop: '20%',
        welcomeIcon: {
          src: welcomeIcon,
          alt: 'fabula-welcome-background',
        },
      },
      content: {
        main: {
          text: '',
        },
        additional: {
          text: 'Obrigada por ter visitado a nossa Lojinha! Se quiser assiste de novo, é só clicar no botão "assistir"! A gravação vai estar disponível até sábado, às 14h :)',
        },
        footer: {
          text: '',
        },
      },
      callToAction: {
        button: {
          text: 'CLIQUE AQUI PARA INGRESSAR NA LIVE',
          color: 'secondary',
          variant: 'outlined',
        },
      },
    },
  },
  checkout: {
    baseUrl: 'https://www.afabula.com.br',
    path: '/checkout/cart/add',
    button: {
      text: 'finalizar compra',
    },
    discountText: 'Outros Descontos',
    totalText: 'Total',
    couponText: 'CUPOM DE 15% OFF',
    subtotalText: 'Subtotal',
    installmentNumber: 10,
    installmentMinValue: 5,
    couponImage,
  },
  chat: {
    active: true,
    text: 'Chat',
    icon: {
      src: chatIcon,
      alt: 'Chat',
    },
    mainBackgroundColor: template.mainColor,
    headerTextColor: '#FFF',
    headerText: 'Chat!',
    setNameText: 'seja bem-vinda ao nosso atendimento! Para começar digite o seu e-mail abaixo.',
    buttonTextTheme: 'primary',
    setUserNameButtonText: 'Enviar',
    colorTextChatPortrait: '#64473F',
    mainColor: template.mainColor,
    headerBackgroundColor: template.mainColor,
    tooltipEmailText: 'E-mail',
    tooltipMessageText: 'Mensagem',
    emptyMessageErrorText: 'Por favor insira uma mensagem!',
    messageSuccessText: 'Mensagem enviada com sucesso!',
    emptyNameErrorText: 'Por favor insira um nome!',
  },
  drawer: {
    header: {
      button: {
        size: 'small',
      },
    },
    padding: 10,
    width: '40%',
  },
  somaNavigation: {
    appBar: {
      position: 'static',
      elevation: 0,
      color: 'transparent',
    },
    tab: {
      textColor: 'primary',
      variant: 'fullWidth',
      indicatorColor: 'primary',
    },
    quantity: {
      box: {
        style: {
          display: 'flex',
          alignItems: 'center',
        },
      },
      button: {
        style: {
          minWidth: 50,
          fontSize: 18,
          margin: 5,
          '&:hover': {
            backgroundColor: 'transparent',
          },
        },
      },
      variant: 'subtitle1',
    },
  },
  realtimeProduct: {
    styles: {
      padding: 10,
    },
    title: 'Apresentando:',
    titleVariant: 'subtitle2',
  },
  i18n: {
    locale: 'pt-BR',
    currency: 'BRL',
  },
  operator: {},
  system: {
    messages: {
      lgpd: {
        button: {
          variant: 'outlined',
          style: {
            backgroundColor: 'transparent',
            color: '#FFFFFF',
            border: '1px solid',
          },
        },
        box: { background: 'rgba(233,130,88, 0.8)' },
        text: {
          style: { color: '#FFFFFF' },
          content: [
            {
              value:
                'Possuímos uma política de cookies para garantir uma melhor experiência para você! Caso continue, vamos supor que você está de acordo com o nosso ',
              type: 'text',
            },
            {
              value: 'Aviso de Privacidades.',
              type: 'link',
              href: 'https://www.afabula.com.br/institucional/aviso-de-privacidade',
              title: 'Política de privacidade',
            },
            {
              value: '',
              type: 'text',
            },
            {
              buttonText: 'aceitar e fechar',
              type: 'text',
            },
          ],
        },
      },
    },
  },
  favicon: faviconFabula,
  title: 'Live Fábula',
};

export default { ...main, ...ptBR };

export { themeTemplate, colorTransform };
