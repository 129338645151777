import { makeStyles } from '@material-ui/core';

const useWrapperStyle = makeStyles((theme) => ({
  root: {
    position: 'fixed',
    bottom: 0,
    left: 0,
    zIndex: '999',
    width: '100%',
    padding: `${theme.spacing(1)}px ${theme.spacing(1)}px`,
    background: theme.system.messages.lgpd.box.background,
    transition: `transform 0.15s cubic-bezier(0.4, 0.0, 0.2, 1)`,
    willChange: 'transform',
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'center',
    '@media(orientation: portrait)': {
      flexDirection: 'column',
    },
    alignItems: 'center',
  },
}));

const useTextStyle = makeStyles((theme) => ({
  root: {
    color: theme.system.messages.lgpd.text.style.color,
    fontWeight: theme.system.messages.lgpd.text.style.fontWeight,
    '@media(orientation: portrait)': {
      margin: '0.5rem',
      fontSize: '14px',
    },
    '@media(orientation: landscape)': {
      fontSize: '14px',
      marginTop: 0,
      marginLeft: '55px',
    },
    '@media(min-width:1000px)': {
      fontSize: '14px',
    },
  },
}));

const useLinkStyle = makeStyles((theme) => ({
  root: {
    color: theme.system.messages.lgpd.text.style.color,
    fontWeight: theme.system.messages.lgpd.text.style.fontWeight,
    display: 'inline-block',
    textDecoration: 'underline',
    '&:hover': {
      cursor: 'pointer',
    },
  },
}));

const useButtonAcceptStyle = makeStyles((theme) => ({
  root: {
    height: '2.5rem',
    width: '16rem',
    marginLeft: '55px',
    fontSize: '14px',
    color: theme.system.messages.lgpd.button.style.color,
    backgroundColor: theme.system.messages.lgpd.button.style.backgroundColor,
    '&:hover': {
      backgroundColor: theme.system.messages.lgpd.button.style.backgroundColor,
    },
    border: theme.system.messages.lgpd.button.style.border,
    '@media(orientation: portrait)': {
      width: '100%',
      marginTop: '8px',
      marginLeft: 0,
    },
    '@media(orientation: landscape)': {
      marginTop: '3px',
    },
  },
}));

export { useWrapperStyle, useTextStyle, useLinkStyle, useButtonAcceptStyle };
