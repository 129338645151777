import React, { useEffect, useState } from 'react';
import Textfield from '@material-ui/core/TextField';
import PropTypes from 'prop-types';
import { InputAdornment } from '@material-ui/core';
import ArrowForwardIcon from '@material-ui/icons/ArrowForward';
import IconButton from '@material-ui/core/IconButton';
import { useEmailFieldStyles, useIconStyles, useInputStyles, useFormHelperTextStyles } from './styles';
import { emailRegexTest } from '../../../helpers/emailHelper';
import { useEventDispatch, newRelicEvents } from '../../../events';

const EmailField = ({ themeStyles, sendEmail: save, onSuccess, email: defaultValue }) => {
  const { fullWidth, fontWeight, variant, color, textColor, fontSize, letterSpacing, text } = themeStyles;
  const emailClasses = useEmailFieldStyles({
    textColor,
    color,
    variant,
    letterSpacing,
  });
  const iconClasses = useIconStyles();
  const formHelperTextClasses = useFormHelperTextStyles();
  const inputClasses = useInputStyles();

  const [value, setValue] = useState(defaultValue || '');
  const [helperText, setHelperText] = useState();
  const [emailFieldError, setEmailFieldError] = useState(false);
  const [emailSendButtonDisable, setEmailSendButtonDisable] = useState(!defaultValue);
  const dispatchEvent = useEventDispatch();

  const onHandleChange = (e) => {
    setValue(e.target.value);
    setEmailFieldError(false);
    setHelperText(null);
    setEmailSendButtonDisable(!emailRegexTest(e.target.value));
  };

  const sendEmail = () => {
    if (emailSendButtonDisable) return;

    setEmailSendButtonDisable(true);
    save(value)
      .then(() => {
        setHelperText(null);
        setEmailFieldError(false);
        setEmailSendButtonDisable(false);
        onSuccess();
      })
      .catch((error) => {
        setHelperText(error.message);
        setEmailFieldError(true);
        setEmailSendButtonDisable(false);
        dispatchEvent(newRelicEvents.log, {
          type: 'Error',
          message: error.message,
          path: 'EmailField/index.js -> sendEmail()',
        });
      });
  };

  useEffect(() => {
    if (defaultValue) {
      setValue(defaultValue);
      setEmailSendButtonDisable(!emailRegexTest(defaultValue));
    }
  }, [defaultValue]);

  return (
    <Textfield
      value={value}
      onChange={onHandleChange}
      required
      type="email"
      error={emailFieldError}
      helperText={helperText}
      FormHelperTextProps={{
        classes: formHelperTextClasses,
      }}
      classes={emailClasses}
      fullWidth={fullWidth}
      variant={variant}
      color={textColor ? 'default' : color}
      fontWeight={fontWeight}
      fontSize={fontSize}
      placeholder={text}
      InputProps={{
        classes: inputClasses,
        endAdornment: (
          <InputAdornment position="end">
            <IconButton classes={iconClasses} onClick={sendEmail} disabled={emailSendButtonDisable}>
              <ArrowForwardIcon />
            </IconButton>
          </InputAdornment>
        ),
      }}
      onKeyUp={(event) => {
        if (event.key === 'Enter') sendEmail();
      }}
    />
  );
};

EmailField.defaultProps = {
  variant: 'filled',
  color: 'primary',
  fullWidth: false,
  text: 'Campo de E-mail',
};

EmailField.propTypes = {
  variant: PropTypes.oneOf(['filled', 'outlined', 'standard']),
  color: PropTypes.string,
  fullWidth: PropTypes.bool,
  text: PropTypes.string,
};

export default EmailField;
