import React from 'react';
import ExpandLessIcon from '@material-ui/icons/ExpandLess';
import { IconButton, useTheme } from '@material-ui/core';
import { scrollButtonStyles, iconButtonStyles } from './styles';

const SomaIconButton = (props) => {
  const theme = useTheme();
  const scrollButtonClasses = scrollButtonStyles(theme);
  const iconButtonClasses = iconButtonStyles(theme);
  const { onClick } = props;
  return (
    <IconButton classes={scrollButtonClasses} onClick={onClick}>
      <ExpandLessIcon classes={iconButtonClasses} />
      {theme.SomaIconButton.text}
    </IconButton>
  );
};

export default SomaIconButton;
