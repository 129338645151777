import React, { useEffect, useState } from 'react';
import { useTheme } from '@material-ui/core/styles';
import { Paper, Grid } from '@material-ui/core';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import { get } from 'lodash';
import PropTypes from 'prop-types';
import Styles from './styles';
import SomaButton from '../../atoms/SomaButton';
import EmailField from '../../molecules/EmailField';
import { useEventDispatch, gtmEvents, somaEvents, newRelicEvents } from '../../../events';

const Welcome = (props) => {
  const { onClick, liveActive, justify, sendEmail, email } = props;
  const portrait = useMediaQuery('(orientation: portrait)');
  const isMobilePortrait = useMediaQuery('(max-width:480px)');
  const isMobileLandscape = useMediaQuery('(max-width:926px)');
  const isDesktop = useMediaQuery('(min-width:1024px)');

  const theme = useTheme();
  const [currentClass, setCurrentClass] = useState();

  const { container: welcomeContainer, paper } = theme.welcomePage;
  const { callToAction } = welcomeContainer;

  const themeButtonText = get(callToAction.button, 'text', 'Texto do Botão');
  const streamEmailRequired = get(callToAction, 'mode');
  const inputStyleProps = get(callToAction, 'input');

  const dispatchEvent = useEventDispatch();

  const {
    usePaperStyle,
    useContainerStyle,
    useCallToActionPortraitStyles,
    useCallToActionLandscapeStyles,
    useCallToActionDesktopStyles,
  } = Styles({ portrait, paper });

  const paperClasses = usePaperStyle();
  const containerClasses = useContainerStyle();
  const callToActionClassesPortrait = useCallToActionPortraitStyles();
  const callToActionClassesLandscape = useCallToActionLandscapeStyles();
  const callToActionClassesDesktop = useCallToActionDesktopStyles();

  useEffect(() => {
    if (isMobilePortrait) {
      setCurrentClass(callToActionClassesPortrait);
      return;
    }
    if (isMobileLandscape) {
      setCurrentClass(callToActionClassesLandscape);
      return;
    }
    if (isDesktop) {
      setCurrentClass(callToActionClassesDesktop);
    }
  }, [
    callToActionClassesPortrait,
    callToActionClassesLandscape,
    callToActionClassesDesktop,
    isMobilePortrait,
    isMobileLandscape,
    isDesktop,
  ]);

  return (
    <Paper elevation={0} classes={paperClasses}>
      <Grid container justify={welcomeContainer.justify ?? justify} classes={containerClasses} direction="column">
        {liveActive && (
          <Grid container classes={currentClass} justify="center">
            <>
              {streamEmailRequired === 'input' && (
                <Grid item>
                  <EmailField
                    themeStyles={inputStyleProps}
                    sendEmail={sendEmail}
                    onSuccess={() => {
                      onClick();
                      dispatchEvent(gtmEvents.onSignUp, { method: 'email' });
                    }}
                    email={email}
                  />
                </Grid>
              )}
              {streamEmailRequired === 'button' && (
                <Grid item classes={currentClass}>
                  <SomaButton
                    textColor={callToAction.button.textColor}
                    color={callToAction.button.color}
                    onClick={() => {
                      try {
                        dispatchEvent(gtmEvents.onEnter);
                        dispatchEvent(somaEvents.onEnter);
                        dispatchEvent(newRelicEvents.log, {
                          type: 'Device Measures',
                          width: window.innerWidth,
                          height: window.innerHeight,
                          path: 'Welcome/index.js -> <SomaButton />',
                        });

                        onClick();
                      } catch (error) {
                        dispatchEvent(newRelicEvents.log, {
                          type: 'Error',
                          message: error.message,
                          path: 'Welcome/index.js -> <SomaButton />',
                        });
                      }
                    }}
                    variant={callToAction.button.variant}
                    label={themeButtonText}
                    fullWidth={callToAction.button.fullWidth}
                    fontWeight={callToAction.button.weight}
                    letterSpacing={callToAction.button.letterSpacing}
                  />
                </Grid>
              )}
            </>
          </Grid>
        )}
      </Grid>
    </Paper>
  );
};

Welcome.defaultProps = {
  justify: 'flex-start',
  liveActive: true,
  onClick: () => {},
};

Welcome.propTypes = {
  justify: PropTypes.oneOf(['space-between', 'center', 'space-evenly', 'space-around', 'flex-start', 'flex-end']),
  liveActive: PropTypes.bool,
  onClick: PropTypes.func,
};

export default Welcome;
