import React, { useState, useEffect } from 'react';
import { Dialog, DialogContent, DialogTitle, Box, Grid, IconButton, useTheme, Button } from '@material-ui/core';
import CloseIcon from '@material-ui/icons/Close';
import { SomaButton } from '../../atoms';
import { useButtonStyle, useDialogContentStyle, useDialogTitleStyle, useCloseIconButtonStyles } from './styles';
import { useSocketContext } from '../../../contexts/socketContext';

const Poll = () => {
  const { socket, receivedSurvey } = useSocketContext();
  const [poll, setPoll] = useState();
  const [showDialog, setShowDialog] = useState();

  const theme = useTheme();

  const dialogContentClasses = useDialogContentStyle();
  const dialogTitleClasses = useDialogTitleStyle();
  const pollButtonClasses = useButtonStyle();
  const CloseIconButtonClasses = useCloseIconButtonStyles(theme);

  const updatePoll = (data) => {
    if (!data) return;

    if (!data.options || data.active === false) {
      setShowDialog(false);
      setPoll(null);
    } else {
      setPoll(data);
    }
  };

  useEffect(() => {
    if (!socket) return;
    socket.emit('getLastPoll', updatePoll);
  }, [socket]);

  useEffect(() => {
    updatePoll(receivedSurvey);
  }, [receivedSurvey]);

  const vote = (option) => {
    socket.emit('voteSurvey', { questionId: poll.id, optionId: option.id });
    setShowDialog(false);
    setPoll(null);
  };

  if (!poll) return null;

  const renderDialog = () => (
    <Dialog open={showDialog} fullWidth>
      <Grid container direction="column" alignItems="center">
        <Grid container justify="flex-end">
          <Button
            startIcon={<CloseIcon classes={CloseIconButtonClasses} />}
            variant="text"
            onClick={() => {
              setPoll(false);
              setShowDialog(false);
            }}
          />
        </Grid>
        <DialogTitle classes={dialogTitleClasses}>{poll.question}</DialogTitle>
        <DialogContent classes={dialogContentClasses}>
          <Grid container direction="column" spacing={1}>
            {poll.options.map((option) => (
              <Grid item key={option.id} xs={12}>
                <SomaButton
                  onClick={() => vote(option)}
                  label={option.text}
                  color={theme.survey.buttonChoiceColor}
                  textColor={theme.survey.buttonChoiceTextColor}
                />
              </Grid>
            ))}
          </Grid>
        </DialogContent>
      </Grid>
    </Dialog>
  );

  return (
    <Box>
      {poll && (
        <IconButton classes={pollButtonClasses} onClick={() => setShowDialog(true)}>
          <img src={theme.survey.icon.src} alt="Poll-Icon" fontSize="large" width="63" height="63" />
        </IconButton>
      )}
      {showDialog && renderDialog()}
    </Box>
  );
};

export default Poll;
