import React from 'react';
import { Grid, Typography, Badge } from '@material-ui/core';
import PropTypes from 'prop-types';
import Styles from './styles';

const SomaBadge = (props) => {
  const { icon, text, direction, alignItems, justifyContent, count, horizontal, vertical, alignText } = props;

  const iconTextStyle = Styles.useIconTextStyles();
  const iconStyle = Styles.useIconStyles();
  const badgeStyle = Styles.useBadgeStyles();

  return (
    <Grid container>
      <Grid item container direction={direction} alignItems={alignItems} justify={justifyContent}>
        <Badge badgeContent={count} anchorOrigin={{ horizontal, vertical }} classes={badgeStyle}>
          <img key={icon.src} src={icon.src} alt={icon.alt} className={iconStyle.root} />
        </Badge>
      </Grid>
      <Grid item container direction={direction} alignItems={alignItems} justify={justifyContent}>
        <Typography variant="body1" color="primary" align={alignText} classes={iconTextStyle}>
          {text}
        </Typography>
      </Grid>
    </Grid>
  );
};

SomaBadge.defaultProps = {
  icon: {},
  direction: 'column',
  alignItems: 'center',
  justifyContent: 'center',
  count: 1,
  horizontal: 'right',
  vertical: 'top',
  alignText: 'center',
};

SomaBadge.propTypes = {
  icon: PropTypes.shape({
    src: PropTypes.string,
    alt: PropTypes.string,
  }),
  text: PropTypes.string.isRequired,
  direction: PropTypes.oneOf(['column', 'row']),
  alignItems: PropTypes.oneOf(['center', 'flex-start', 'flex-end']),
  justifyContent: PropTypes.oneOf(['center', 'flex-start', 'flex-end']),
  count: PropTypes.number,
  horizontal: PropTypes.oneOf(['right', 'left']),
  vertical: PropTypes.oneOf(['top', 'bottom']),
  alignText: PropTypes.oneOf(['center', 'left', 'right']),
};

export default SomaBadge;
