import { ptBR } from '@material-ui/core/locale';
import config from '../config';
import { themeTemplate, colorTransform } from './somalive';

const welcomeBackgroundDesktop = `${config.assetsUrl}/offpremium/welcomeBackground.png`;
const welcomeBackgroundMobile = `${config.assetsUrl}/offpremium/welcomeBackground.png`;
const welcomeIcon = `${config.assetsUrl}/offpremium/welcomeLogo.png`;
const faqIcon = `${config.assetsUrl}/offpremium/icon/ajudaicon.svg`;
const bagIcon = `${config.assetsUrl}/offpremium/icon/sacolaicon.svg`;
const productIcon = `${config.assetsUrl}/offpremium/icon/products.svg`;
const chatIcon = `${config.assetsUrl}/offpremium/icon/chaticon.svg`;

const faviconOffPremium = `${config.assetsUrl}/common/favicons/offpremium.png`;

const colorIntensity = 50;
const template = themeTemplate({
  mainColor: '#000',
  secondaryColor: '#CC2328',
  fontFamily: 'Lato',
  badgeBgColor: '#000',
  badgeColor: '#fff',
  buttonsBorderRadius: 0,
});

const main = {
  palette: {
    primary: colorTransform(template.mainColor, colorIntensity),
    secondary: colorTransform(template.secondaryColor, colorIntensity),
  },
  typography: {
    fontFamily: template.fontFamily,
  },
  overrides: {
    MuiTypography: {
      root: {
        color: template.mainColor,
      },
    },
    MuiButton: {
      root: {
        borderRadius: template.buttonsBorderRadius,
      },
    },
    MuiAlert: {
      root: {
        color: template.mainColor,
      },
      standardSuccess: {
        color: template.mainColor,
        backgroundColor: '#fff',
        '& $icon': {
          color: template.mainColor,
        },
      },
      standardInfo: {
        color: template.mainColor,
        backgroundColor: '#fff',
        '& $icon': {
          color: template.mainColor,
        },
      },
    },
  },
  faq: {
    text: 'Ajuda',
    icon: {
      src: faqIcon,
      alt: 'Ajuda',
    },
    content: [
      {
        title: 'O que é LIVE OFFPREMIUM?',
        description:
          'LIVE OFFPREMIUM é nossa loja virtual 100% interativa. Nosso objetivo é reproduzir a experiência de compra em loja na sua casa. Agora a OFFPREMIUM está em todo lugar! Aqui você pode interagir com a gente, tirar suas dúvidas com o nosso produtor de moda e comprar em tempo real. ',
      },
      {
        title: 'Posso tirar minhas dúvidas?',
        description:
          'Sim! Clique no ícone "chat" no canto direito da sua tela e deixe sua mensagem. Ela pode ser respondida ao vivo pelos nossos apresentadores ou individualmente pelo nosso time.',
      },
      {
        title: 'Como vejo os produtos apresentados?',
        description:
          'Clique no ícone "produtos" no canto esquerdo da sua tela e navegue por categorias para melhor visualização.',
      },
      {
        title: 'Posso comprar em tempo real? ',
        description:
          'Sim! Escolha o produto, clique em "adicionar à sacola" e em seguida selecione o seu tamanho. O produto será imediatamente adicionado à sacola, que está localizada no canto superior direito da tela.',
      },
      {
        title: 'Como finalizo minha compra?',
        description:
          'Clique no ícone "sacola"  no canto superior direito da tela e depois em "finalizar compra". Você será redirecionada para a página de checkout da e-store, para realizar o pagamento de forma rápida e segura.',
      },
    ],
  },
  bag: {
    text: 'Sacola',
    emptyBag: 'Sua sacola está vazia',
    emptyBagColor: 'secondary',
    icon: {
      src: bagIcon,
      alt: 'Sacola',
    },
  },
  products: {
    text: 'Produtos',
    icon: {
      src: productIcon,
      alt: 'Produtos',
    },
    size: {
      text: 'Selecione o Tamanho:',
      typography: {
        variant: 'subtitle1',
      },
    },
    details: {
      text: 'Detalhes do Produto',
      button: {
        variant: 'text',
        fullWidth: false,
      },
    },
    button: {
      text: 'Adicionar à sacola',
      variant: 'contained',
      color: 'primary',
    },
    composition: {
      text: 'Composição',
    },
    name: {
      color: 'primary',
    },
    price: {
      sale: {
        color: 'primary',
      },
    },
    quantity: {
      variant: 'subtitle1',
    },
    notAvaiable: {
      text: 'Produto Indisponível',
    },
    search: {
      title: 'Pesquisar produtos',
      notFound: 'Produto não encontrado',
      emptyBrand: 'É necessário fornecer uma brand para pesquisar o produto',
    },
  },
  welcomePage: {
    paper: {
      backgroundImageDesktop: `url(${welcomeBackgroundDesktop})`,
      backgroundImageMobile: `url(${welcomeBackgroundMobile})`,
    },
    container: {
      justify: 'center',
      header: {
        maxWidthDesktop: '20%',
        welcomeIcon: {
          src: welcomeIcon,
          alt: 'offpremium-welcome-background',
        },
      },
      content: {
        spacing: 2,
        main: {
          text: 'CONVIDAMOS PARA NOSSA TRANSMISSÃO TOTALMENTE INTERATIVA',
        },
        additional: {
          text: 'Teremos descontos exclusivos e produções incríveis para o verão. Fique com a gente e aguarde surpresas',
        },
        footer: {
          text: '29/01 - 18H',
        },
      },
      callToAction: {
        button: {
          text: 'ASSISTA AGORA',
          color: 'primary',
          variant: 'outlined',
        },
      },
    },
  },
  checkout: {
    baseUrl: 'https://www.offpremium.com.br',
    path: '/checkout/cart/add',
    button: {
      text: 'Finalizar Compra',
    },
    discountText: 'Outros Descontos',
    totalText: 'Total',
    couponText: 'adicione o cupom "LIVE30" no checkout',
    subtotalText: 'Subtotal',
    installmentNumber: 10,
  },
  chat: {
    active: true,
    text: 'Chat',
    icon: {
      src: chatIcon,
      alt: 'Chat',
    },
    mainBackgroundColor: template.mainColor,
    headerTextColor: '#FFF',
    headerText: 'Chat!',
    setNameText: 'seja bem-vinda ao nosso atendimento! Para começar digite o seu e-mail abaixo.',
    buttonTextTheme: 'primary',
    setUserNameButtonText: 'Enviar',
    colorTextChatPortrait: '#CC2328',
    mainColor: template.mainColor,
    headerBackgroundColor: template.mainColor,
    tooltipEmailText: 'E-mail',
    tooltipMessageText: 'Mensagem',
    emptyMessageErrorText: 'Por favor insira uma mensagem!',
    messageSuccessText: 'Mensagem enviada com sucesso!',
    emptyNameErrorText: 'Por favor insira um nome!',
  },
  drawer: {
    header: {
      button: {
        size: 'small',
      },
    },
    padding: 10,
    width: '40%',
  },
  somaNavigation: {
    appBar: {
      position: 'static',
      elevation: 0,
      color: 'transparent',
    },
    tab: {
      textColor: 'primary',
      variant: 'fullWidth',
      indicatorColor: 'primary',
    },
    quantity: {
      box: {
        style: {
          display: 'flex',
          alignItems: 'center',
        },
      },
      button: {
        style: {
          minWidth: 50,
          fontSize: 18,
          margin: 5,
          '&:hover': {
            backgroundColor: 'transparent',
          },
        },
      },
      variant: 'subtitle1',
    },
  },
  realtimeProduct: {
    styles: {
      padding: 10,
    },
    title: 'Apresentando:',
    titleVariant: 'subtitle2',
  },
  i18n: {
    locale: 'pt-BR',
    currency: 'BRL',
  },
  operator: {},
  system: {
    messages: {
      lgpd: {
        button: {
          variant: 'outlined',
          style: {
            backgroundColor: 'transparent',
            color: '#FFFFFF',
            border: '1px solid',
          },
        },
        box: { background: 'rgba(0,0,0, 0.8)' },
        text: {
          style: { color: '#FFFFFF' },
          content: [
            {
              value:
                'Possuímos uma política de cookies para garantir uma experiência mais segura para você! Ao continuar no site, você estará de acordo com a nossa ',
              type: 'text',
            },
            {
              value: 'Política de Privacidade.',
              type: 'link',
              href: 'https://www.offpremium.com.br/politica/privacidade-e-seguranca',
              title: 'Política de privacidade',
            },
            {
              value: '',
              type: 'text',
            },
            {
              buttonText: 'ACEITAR TERMOS',
              type: 'text',
            },
          ],
        },
      },
    },
  },
  favicon: faviconOffPremium,
  title: 'Premium Live',
};

export default { ...main, ...ptBR };

export { themeTemplate, colorTransform };
