import { makeStyles } from '@material-ui/core';

const useBoxStyle = makeStyles({
  root: {
    height: '100%',
    overflow: 'hidden',
    position: 'relative',
    width: '100%',
    display: 'flex',
    flexDirection: 'column',
    flexWrap: 'nowrap',
  },
});

const useMainStyle = makeStyles({
  root: {
    width: '100%',
    flex: '1 1 100%',
    overflowY: 'scroll',
    overflowX: 'hidden',
  },
});

const useFooterStyle = () =>
  makeStyles({
    root: {
      width: '100%',
      flex: '0 0 auto',
      backgroundColor: '#fff',
      paddingBottom: 5,
    },
  })();

const useEmptyBoxStyle = () =>
  makeStyles({
    root: {
      height: '100%',
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      flexDirection: 'column',
    },
  })();

export default { useBoxStyle, useEmptyBoxStyle, useMainStyle, useFooterStyle };
