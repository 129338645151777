/* eslint-disable no-console */
import { useEffect } from 'react';
import propTypes from 'prop-types';
import TagManager from 'react-gtm-module';

const GTM = (props) => {
  const { gtmId, event } = props;

  useEffect(() => {
    if (!gtmId) return;
    TagManager.initialize({ gtmId });
  }, [gtmId]);

  useEffect(() => {
    if (TagManager.dataLayer) {
      TagManager.dataLayer({ dataLayer: event });
    }
  }, [event]);

  return null;
};

GTM.propTypes = {
  gtmId: propTypes.string.isRequired,
};

export default GTM;
