import React, { useState, useContext } from 'react';
import { Box, Dialog, Button, useTheme, Grid, IconButton } from '@material-ui/core';
import withWidth from '@material-ui/core/withWidth';
import Carousel from 'react-material-ui-carousel';
import CloseIcon from '@material-ui/icons/Close';
import Styles from './styles';
import { useEventDispatch, somaEvents, gtmEvents } from '../../../events';
import { gtmEcommerceProductAdapter } from '../../../helpers/eventHelper';
import { StreamContext } from '../../../hooks/contexts';

const ProductImages = (props) => {
  const { product, isBag, category } = props;
  const { images: imagesArray, width, isShowcasing } = product;
  const theme = useTheme();

  const [showCarousel, setShowCarousel] = useState(false);
  const mainPicture = imagesArray[0];
  const buttonClasses = Styles.useButtonStyles();

  const boxClasses = Styles.useBoxStyles();
  const imageClasses = Styles.useImageStyle();
  const CloseIconClasses = Styles.useCloseIconStyles(theme);
  const CloseIconButtonClasses = Styles.useCloseIconButtonStyle();
  const navButtonClasses = Styles.useNavButtonsStyles;
  const dispatchEvent = useEventDispatch();

  const stream = useContext(StreamContext);

  const [imgWidth, imgHeight] = ['xs', 'sm'].includes(width) ? [233, 350] : [300, 450];
  const minHeight = window.innerHeight / 4;

  if (!mainPicture) return <></>;

  return (
    <Box flex={1} classes={boxClasses}>
      <Button
        onClick={() => {
          if (showCarousel) return;

          setShowCarousel(true);

          dispatchEvent(gtmEvents.onItemGalleryShow, {
            ...gtmEcommerceProductAdapter({
              products: [product],
              category: isBag ? 'cart' : category,
              theme,
              stream,
            }),
          });

          dispatchEvent(somaEvents.onProductClick);
          if (isShowcasing) dispatchEvent(somaEvents.onProductShowcasingClick);
        }}
        classes={buttonClasses}
      >
        <img key={mainPicture} src={mainPicture} alt="" width="100%" style={{ minHeight }} />
      </Button>
      <Dialog
        open={showCarousel}
        onClose={() => {
          setShowCarousel(false);
          dispatchEvent(gtmEvents.onItemGaleryHide, {
            ...gtmEcommerceProductAdapter({
              products: [product],
              category: isBag ? 'cart' : category,
              theme,
              stream,
            }),
          });
        }}
        classes={boxClasses}
      >
        <Grid container justify="flex-end">
          <IconButton classes={CloseIconButtonClasses}>
            <CloseIcon onClick={() => setShowCarousel(false)} classes={CloseIconClasses} />
          </IconButton>
        </Grid>
        <Carousel
          indicators={false}
          navButtonsAlwaysVisible
          autoPlay={false}
          next={() => {
            dispatchEvent(gtmEvents.onItemGaleryNavNext, {
              ...gtmEcommerceProductAdapter({
                products: [product],
                category: isBag ? 'cart' : category,
                theme,
                stream,
              }),
            });
          }}
          prev={() => {
            dispatchEvent(gtmEvents.onItemGaleryNavPrev, {
              ...gtmEcommerceProductAdapter({
                products: [product],
                category: isBag ? 'cart' : category,
                theme,
                stream,
              }),
            });
          }}
          navButtonsProps={{ style: navButtonClasses }}
        >
          {imagesArray.map((picture) => (
            <img
              key={picture}
              src={picture}
              alt=""
              width={imgWidth}
              height={imgHeight}
              className={imageClasses.carouselImage}
            />
          ))}
        </Carousel>
      </Dialog>
    </Box>
  );
};

export default withWidth()(ProductImages);
