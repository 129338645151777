import slugify from 'slugify';
import { useEffect } from 'react';
import TagManager from 'react-gtm-module';

import { useEvent } from '../../events/useEvent';
import { gtmEvents } from '../../events';

const slugfy = (string) => {
  const config = {
    lower: true,
    replacement: '_',
    strict: true,
  };
  return slugify(string, config);
};

const gtmEcommerceProductAdapter = (params = {}) => {
  const { products = [], category, theme, stream } = params;

  if ([products, category, theme, stream].includes(undefined)) {
    return false;
  }

  return {
    item_list_name: slugfy(category),
    ecommerce: {
      currency: theme.i18n.currency,
      items: products.map((product, index) => ({
        item_list_name: slugfy(category),
        item_list_id: product.id_button,
        item_name: product.productName,
        item_id: product.id,
        price: product.price,
        item_brand: stream.brand.name,
        item_variant: product.variant || null,
        sku: product.sku || null,
        index,
        quantity: product.quantity || 1,
      })),
    },
  };
};

export default (props) => {
  const { id, brand, streamName } = props;
  const gtmEventsArr = Object.values(gtmEvents);

  gtmEventsArr.forEach((evt) => {
    useEvent(evt, (params) => {
      if (evt === gtmEvents.onCategoryScroll) return;
      if (evt === gtmEvents.onScroll) return;

      let adaptedParams = params;
      const deveAdaptarEstes = [gtmEvents.onCategoryView, gtmEvents.onItemGalleryShow];
      if (deveAdaptarEstes.includes(evt)) {
        adaptedParams = gtmEcommerceProductAdapter(params);
      }
      const dataLayer = {
        ...adaptedParams,
        event: evt,
        brand,
        stream_name: streamName,
      };

      if (TagManager.dataLayer) TagManager.dataLayer({ dataLayer });
    });
  });

  useEffect(() => {
    if (!id) return;
    TagManager.initialize({ gtmId: id });
  }, [id]);

  return null;
};
