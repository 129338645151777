import React from 'react';
import { Drawer } from '@material-ui/core';
import PropTypes from 'prop-types';
import { HeaderDrawer } from '../../atoms';
import SomaStyles from './Styles';
import { useEventDispatch, gtmEvents } from '../../../events';

const SomaDrawer = (props) => {
  const { anchorDirection, children, isOpen, setIsOpen, titleDrawer, type, icon, scrollTarget } = props;

  const useStyles = type === 'products' ? SomaStyles.useProductsDrawerStyle : SomaStyles.useBagDrawerStyle;

  const classes = useStyles();

  const dispatchEvent = useEventDispatch();

  const closeDrawer = () => {
    setIsOpen(false);

    if (type === 'products') dispatchEvent(gtmEvents.onProductsHide);
    if (type === 'bag') dispatchEvent(gtmEvents.onBagHide);
  };

  return (
    <Drawer open={isOpen} anchor={anchorDirection} onClose={closeDrawer} PaperProps={{ classes, ref: scrollTarget }}>
      <HeaderDrawer titleDrawer={titleDrawer} onClickClose={closeDrawer} icon={icon} />

      {children}
    </Drawer>
  );
};

SomaDrawer.defaultProps = {
  anchorDirection: 'left',
  type: 'products',
  icon: null,
};

SomaDrawer.propTypes = {
  anchorDirection: PropTypes.oneOf(['left', 'right']),
  isOpen: PropTypes.bool.isRequired,
  setIsOpen: PropTypes.func.isRequired,
  titleDrawer: PropTypes.string.isRequired,
  icon: PropTypes.shape({
    src: PropTypes.string,
    alt: PropTypes.string,
  }),
  type: PropTypes.oneOf(['products', 'bag']),
};

export default SomaDrawer;
