import React, { useState, useEffect, useCallback, useRef, useContext } from 'react';
import { Box, Button, Dialog, DialogTitle, Grid, Typography, useTheme, useMediaQuery } from '@material-ui/core';
import { Close } from '@material-ui/icons';
import { SomaTube } from '../../atoms';
import { SomaBadge, SomaChatCard, SomaChatForm, SomaChatMessageDisplay, SomaFAQ } from '../../molecules';
import {
  SomaNavigation,
  SomaDrawer,
  SomaProductsList,
  MenuBadge,
  Welcome,
  SomaBag,
  LiveProduct,
  SomaPoll,
} from '../../organisms';
import { StreamContext, LocalStorageContext, CartSimulationContext } from '../../../hooks/contexts';
import { save } from '../../../helpers/localStorageHelper';
import { useEventDispatch, somaEvents, gtmEvents, newRelicEvents } from '../../../events';
import config from '../../../config';
import { fetchWithTimeout } from '../../../helpers/fetchHelper';
import Style from './style';
import { itemsCounter, buildBagByUrl, getCartSimulation } from '../../../helpers/bagHelper';
import { useVideoContext } from '../../../contexts/videoContext';
import { useRealTimeProductsContext } from '../../../contexts/realTimeProductsContext';
import { orderMessagesByDescendingTime } from '../../../helpers/chatHelper';
import { useSocketContext } from '../../../contexts/socketContext';
import { useEvent } from '../../../events/useEvent';

const Stream = (props) => {
  const { content, user, sendSnack, setShowLGPD } = props;
  const { buttons = [], brand, stream_name: streamName, is_live: isLive, video, sales_channel: salesChannel } = content;

  const [isProductsDrawerOpen, setIsProductsDrawerOpen] = useState(false);
  const [isBagDrawerOpen, setIsBagDrawerOpen] = useState(false);
  const [isChatOpen, setIsChatOpen] = useState(false);
  const [isFaqOpen, setIsFaqOpen] = useState(false);
  const [showWelcome, setShowWelcome] = useState(!user);
  const [player, setPlayer] = useState();
  const [email, setEmail] = useState();
  const [simulatedCart, setSimulatedCart] = useState();
  const scrollTarget = useRef();
  const mobileScrollTarget = useRef();
  const [chatMessages, setChatMessages] = useState([]);
  const userStore = useContext(LocalStorageContext);
  const bagProducts = userStore?.get('bag');
  const { realTimeProductsState, setShowCasing } = useRealTimeProductsContext();
  const { videoState } = useVideoContext();
  const { socket, receivedMessage, setReceivedMessage } = useSocketContext();

  const [keyframeDisplayed, setKeyFrameDisplayed] = useState();

  const [chatBadgeCount, setChatBadgeCount] = useState(0);
  const messagesRef = useRef(chatMessages);
  const [keyboardVisible, setKeyboardVisible] = useState(false);
  const [liftedStateKeyboardShown, setLiftedStateKeyboardShown] = useState(false);
  const [liftedTabValue, setLiftedTabValue] = useState('products');

  const portrait = useMediaQuery('(max-width:500px)');

  const [initialMobileLandscapeWindowHeight, setInitialMobileLandscapeWindowHeight] = useState(window.innerHeight);
  const isMobileLandscape = useMediaQuery('(max-width: 926px) and (orientation: landscape)');

  const theme = useTheme();
  const productClasses = Style.useProductBoxStyle();
  const dialogClasses = Style.useDialogStyle();
  const badgeMenuClasses = Style.useBadgeMenuStyle();
  const mainGridClass = Style.useMainGridStyle({ portrait, showWelcome });
  const chatClasses = Style.useGridChatStyle();

  const landscapeShowcasingClasses = Style.useLandscapeShowcasingStyle();
  const faqStyles = Style.useFaqStyles();

  const dispatchEvent = useEventDispatch();
  const storageId = brand.name + streamName;

  const allProducts = buttons
    .map((button) => button.products)
    .reduce((accumulator, currentProductsList) => accumulator.concat(currentProductsList), []);

  const productsBagFilter = bagProducts.reduce((accumulator, bagProduct) => {
    allProducts.forEach((prod) => {
      if (
        prod.id === bagProduct.productId &&
        prod.items.find((item) => item.isAvailable && bagProduct.bagInfo.selectedItem.sku === item.sku)
      ) {
        accumulator.push(bagProduct);
      }
    });
    return accumulator;
  }, []);

  const contador = itemsCounter(productsBagFilter);
  userStore.set('bag', productsBagFilter);
  const [bagBadgeCount, setBagBadgeCount] = useState(contador);

  useEvent(somaEvents.onSeekToProductMoment, () => setIsProductsDrawerOpen(false));

  const { showCasing, timeline } = realTimeProductsState;
  const { playedSeconds } = videoState;

  const updateReadMessages = (times) => {
    const updateMessages = [
      ...chatMessages?.map((message) => {
        if (times.includes(message.time)) {
          message.userRead = true;
        }
        return message;
      }),
    ];

    setChatMessages(updateMessages);
    const chat = userStore.get('chat');
    chat.set('messages', updateMessages);
    userStore.set('chat', chat);
    save(userStore);
  };

  const verifyShowCaseProducts = () => {
    if (!showCasing) return [];
    try {
      const productsShowCasing = showCasing.map(({ provider }) =>
        allProducts.find((product) => product.provider.productId === provider.productId)
      );

      return productsShowCasing.filter((item) => !!item);
    } catch (e) {
      dispatchEvent(newRelicEvents.log, {
        type: 'Error',
        message: e.message,
        path: 'Stream/index.js -> verifyShowCaseProducts',
      });
      return [];
    }
  };

  const verifiedShowCaseProducts = verifyShowCaseProducts();

  const mutableChatCounter = useRef(0);

  useEffect(() => {
    if (!receivedMessage) return;

    const messages = orderMessagesByDescendingTime([...messagesRef.current, { ...receivedMessage, userRead: false }]);

    setChatMessages(messages);

    const chat = userStore.get('chat');
    chat.set('messages', messages);
    userStore.set('chat', chat);
    setReceivedMessage(null);
    save(userStore);
  }, [receivedMessage, setReceivedMessage, socket, userStore]);

  const simulateCart = useCallback(() => {
    if ([setSimulatedCart, brand.name, theme.bagSimulationExceptions, userStore].includes(undefined)) return;

    const products = userStore.get('bag');

    if (!products?.length) return;

    getCartSimulation(products, brand.name, theme.bagSimulationExceptions, salesChannel).then((response) => {
      setSimulatedCart(response);
    });
  }, [setSimulatedCart, brand.name, theme.bagSimulationExceptions, userStore, salesChannel]);

  useEffect(() => {
    if (!timeline || !timeline.keyframes || timeline.enabled === false) return;
    const keyframe = timeline.keyframes.find((item) => item.rerun.displayAt <= playedSeconds);

    if (!keyframe || keyframe.id === keyframeDisplayed) return;
    setKeyFrameDisplayed(keyframe.id);

    const showCaseArray = keyframe.products.map((product) => ({
      ...allProducts.find((item) => item.provider.productId === product.id_product),
    }));
    setShowCasing(showCaseArray);
  }, [playedSeconds, timeline, setShowCasing, setKeyFrameDisplayed, keyframeDisplayed, allProducts]);

  useEffect(() => {
    if (content) {
      simulateCart();
    }
  }, [content, simulateCart, contador]);

  useEffect(() => {
    mutableChatCounter.current = chatBadgeCount;
  }, [chatBadgeCount]);

  useEffect(() => {
    const chat = userStore.get('chat');
    setEmail(userStore.get('email'));

    if (chat) {
      const messages = chat.get('messages');
      setChatMessages(orderMessagesByDescendingTime(messages));
    }
  }, [storageId, userStore]);

  useEffect(() => {
    setShowLGPD(true);
  }, [setShowLGPD]);

  useEffect(() => {
    window.addEventListener('orientationchange', () => {
      setTimeout(() => {
        setInitialMobileLandscapeWindowHeight(window.innerHeight);
      }, 100);
    });
  }, [setInitialMobileLandscapeWindowHeight]);

  const sendMessage = (messageObj) => {
    if (!socket) return;
    const chat = userStore.get('chat');
    const privateRoom = chat.get('privateRoom');

    const newMessage = {
      ...messageObj,
      privateRoom,
    };

    socket.emit('sendMessage', newMessage, ({ ok, message }) => {
      if (ok) {
        const newMessagesArray = orderMessagesByDescendingTime([...chatMessages, { ...message, userRead: true }]);

        chat.set('messages', newMessagesArray);

        userStore.set('chat', chat);
        userStore.set('email', newMessage.email);
        save(userStore);

        setChatMessages(newMessagesArray);
        setEmail(newMessage.email);
      }

      if (!ok) {
        dispatchEvent(newRelicEvents.log, {
          type: 'Error',
          message: `Failed to send message: ${message}`,
          path: 'Stream/index.js -> sendMessage()',
        });
        sendSnack(`Erro, mensagem não pode ser enviada devido ao erro: ${message}`);
      }
    });
  };

  useEffect(() => {
    messagesRef.current = chatMessages;
    const unreadedMessages = chatMessages.reduce(
      (accumulator, currentMessage) => (currentMessage.userRead ? accumulator : accumulator + 1),
      0
    );

    setChatBadgeCount(unreadedMessages);
  }, [chatMessages]);

  const sendEmail = (emailCaptacao) => {
    const payload = { email: emailCaptacao, brand: brand.name, streamname: streamName };
    const headers = new Headers();
    headers.append('Content-Type', 'application/json');
    const request = new Request(`${config.backendUrl}/customer/email`, {
      headers,
      method: 'POST',
      body: JSON.stringify(payload),
    });

    return fetchWithTimeout(request, { requestTimeout: config.requestTimeout })
      .then(async (response) => (response.ok ? response.json() : Promise.reject(await response.text())))
      .then((customer) => {
        userStore.set('email', customer.email);
        setEmail(customer.email);
        save(userStore);
        return Promise.resolve(customer);
      })
      .catch((e) => {
        dispatchEvent(newRelicEvents.log, {
          type: 'Error',
          message: e.message,
          path: 'Stream/index.js -> sendEmail()',
        });
        if (/failed to fetch/i.test(e.message)) {
          return Promise.reject(new Error(theme.system.messages.email.failedToFetch));
        }
        if (/aborted/.test(e.message)) {
          Promise.reject(new Error('request timeout'));
        }
        return Promise.reject(new Error(theme.system.messages.email.postError));
      });
  };

  const renderWelcome = () => (
    <Grid item xs={12}>
      <Welcome
        onClick={() => {
          setShowWelcome(false);
          player.play();
        }}
        liveActive={!!isLive}
        sendEmail={sendEmail}
        email={email}
      />
    </Grid>
  );

  const renderChat = () => (
    <Grid container direction="column" className={chatClasses.root}>
      {!!email && !keyboardVisible && (
        <Grid item className={chatClasses.messagesWrapper}>
          <SomaChatMessageDisplay
            messages={chatMessages}
            showEmail={!!user}
            liftStateKeyboardShown={liftedStateKeyboardShown}
          />
        </Grid>
      )}
      <Grid item className={chatClasses.formWrapper}>
        <SomaChatForm
          setKeyboardVisible={setKeyboardVisible}
          liftStateKeyboardShown={setLiftedStateKeyboardShown}
          presetEmail={email}
          messageOnly={!!email}
          onSend={sendMessage}
        />
      </Grid>
    </Grid>
  );

  const renderLiveProduct = () => <LiveProduct products={verifiedShowCaseProducts} />;

  const renderBag = () => (
    <SomaBag
      brand={brand.name}
      streamName={streamName}
      closeFunction={() => {
        dispatchEvent(gtmEvents.onBagHide);
        setIsBagDrawerOpen(false);
      }}
    />
  );

  const renderPortrait = () => {
    const showCasingButton = {
      id_button: 'showcasing',
      label: theme.realtimeProduct.label,
      products: verifiedShowCaseProducts,
    };

    const portraitButtons = [showCasingButton, ...buttons];
    return (
      <SomaNavigation
        buttons={portraitButtons}
        faqArgs={{ noDialog: true }}
        bagArgs={{ brand: brand.name, streamName }}
        scrollTarget={mobileScrollTarget}
        chatArgs={{ chatMessages, user, email, sendMessage }}
        liftStateKeyboardShown={setLiftedStateKeyboardShown}
        liftedTabValue={setLiftedTabValue}
      />
    );
  };

  const renderLiveProductBoxClasses = Style.useRenderLiveProductBoxStyles({
    liveProductNumber: verifiedShowCaseProducts.length,
  });
  const renderLandscape = () => (
    <>
      <Box classes={landscapeShowcasingClasses}>
        {verifiedShowCaseProducts.length > 0 && <Box classes={renderLiveProductBoxClasses}>{renderLiveProduct()}</Box>}

        {theme.products.active && (
          <Box classes={productClasses}>
            <Button
              onClick={() => {
                setIsProductsDrawerOpen(true);
                dispatchEvent(somaEvents.onProductBadgeClick);
                dispatchEvent(gtmEvents.onProductsShow);
              }}
            >
              <SomaBadge text={theme.products.text} icon={theme.products.icon} count={0} />
            </Button>
          </Box>
        )}
        <SomaDrawer
          isOpen={isProductsDrawerOpen}
          setIsOpen={setIsProductsDrawerOpen}
          titleDrawer={theme.products.text}
          type="products"
          scrollTarget={scrollTarget}
        >
          <SomaProductsList scrollTarget={scrollTarget} buttons={buttons} user={user} />
        </SomaDrawer>
      </Box>

      <Box classes={badgeMenuClasses}>
        <MenuBadge
          bagText={theme.bag.text}
          onClickBag={() => {
            setIsBagDrawerOpen(true);
            dispatchEvent(gtmEvents.onBagShow);
            dispatchEvent(somaEvents.onBagBadgeClick);
          }}
          chatText={theme.chat.text}
          onClickChat={() => {
            setIsChatOpen(true);
            dispatchEvent(gtmEvents.onChatShow);
            dispatchEvent(somaEvents.onChatBadgeClick);
          }}
          faqText={theme.faq.text}
          onClickFaq={() => {
            setIsFaqOpen(true);
            dispatchEvent(gtmEvents.onFaqShow);
            dispatchEvent(somaEvents.onFaqBadgeClick);
          }}
          haveProduct
        />
      </Box>
      {theme.bag.active && (
        <SomaDrawer
          isOpen={isBagDrawerOpen}
          setIsOpen={setIsBagDrawerOpen}
          anchorDirection="right"
          titleDrawer={theme.bag.text}
          icon={theme.bag.icon}
          type="bag"
          scrollTarget={scrollTarget}
        >
          {renderBag()}
        </SomaDrawer>
      )}

      <SomaChatCard
        open={isChatOpen}
        onClose={() => {
          setIsChatOpen(false);
          dispatchEvent(gtmEvents.onChatHide);
        }}
        raised={false}
        title={theme.chat.text}
      >
        {renderChat()}
      </SomaChatCard>

      <Dialog
        onClose={() => {
          setIsFaqOpen(false);
          dispatchEvent(gtmEvents.onFaqHide);
        }}
        open={isFaqOpen}
        fullWidth
        maxWidth="sm"
        overflow="auto"
      >
        <DialogTitle id="simple-dialog-title" classes={dialogClasses} disableTypography>
          <Typography>&nbsp;</Typography>
          <Button
            onClick={() => {
              setIsFaqOpen(false);
              dispatchEvent(gtmEvents.onFaqHide);
            }}
            classes={faqStyles}
          >
            <Close fontSize="small" />
          </Button>
        </DialogTitle>
        <SomaFAQ />
      </Dialog>
    </>
  );

  const contentClasses = Style.useContentStyle({ liftedTabValue, liftedStateKeyboardShown, email });

  useEffect(() => {
    buildBagByUrl(allProducts, userStore).then((updatedBagProducts) => {
      const productsCount = itemsCounter(updatedBagProducts.bag);

      setBagBadgeCount(productsCount);
    });
  });

  useEffect(() => {
    window.addEventListener('scroll', () => {
      dispatchEvent(gtmEvents.onScroll);
    });
  }, [dispatchEvent]);

  return (
    <StreamContext.Provider
      value={{
        buttons,
        brand: brand.name,
        streamName,
        sendSnack,
        bagBadgeCount,
        setBagBadgeCount,
        chatBadgeCount,
        updateReadMessages,
        salesChannel,
        brandInfo: brand,
      }}
    >
      {showWelcome && renderWelcome()}
      <CartSimulationContext.Provider value={simulatedCart}>
        <Grid
          container
          style={{ height: isMobileLandscape ? initialMobileLandscapeWindowHeight : null }}
          direction={portrait ? 'column' : 'row'}
          className={mainGridClass.root}
        >
          <Grid item className={contentClasses.videoWrapper}>
            <SomaTube
              url={video.url}
              setPlayer={setPlayer}
              player={player}
              rerunStartTime={video.rerunStartTime}
              isRerun={video.isRerun}
            />
          </Grid>

          {!showWelcome && (
            <Grid container item className={contentClasses.contentWrapper}>
              {portrait ? renderPortrait() : renderLandscape()}
              <SomaPoll socket={socket} />
            </Grid>
          )}
        </Grid>
      </CartSimulationContext.Provider>
    </StreamContext.Provider>
  );
};

export default Stream;
