import React, { useContext } from 'react';
import { useTheme } from '@material-ui/core/styles';
import { Box, Button } from '@material-ui/core';
import PropTypes from 'prop-types';
import { SomaBadge } from '../../molecules';
import Styles from './styles';
import { StreamContext } from '../../../hooks/contexts';

const MenuBadge = (props) => {
  const { bagText, chatText, faqText, onClickBag, onClickChat, onClickFaq, haveProduct } = props;
  const theme = useTheme();
  const { faq, chat, bag } = theme;
  const boxClasses = Styles.useBoxStyle();
  const { bagBadgeCount = 0, chatBadgeCount = 0 } = useContext(StreamContext);

  return (
    <Box classes={boxClasses}>
      {haveProduct && theme.bag.active && (
        <Button onClick={onClickBag}>
          <SomaBadge text={bagText} icon={bag.icon} count={bagBadgeCount} badgeColor="error" />
        </Button>
      )}
      {chat.active && (
        <Button onClick={onClickChat}>
          <SomaBadge text={chatText} icon={chat.icon} count={chatBadgeCount} />
        </Button>
      )}
      {theme.faq.active && (
        <Button onClick={onClickFaq}>
          <SomaBadge text={faqText} icon={faq.icon} count={0} />
        </Button>
      )}
    </Box>
  );
};

MenuBadge.defaultProps = {
  haveProduct: true,
};

MenuBadge.propTypes = {
  bagText: PropTypes.string.isRequired,
  faqText: PropTypes.string.isRequired,
  chatText: PropTypes.string.isRequired,
  onClickBag: PropTypes.func.isRequired,
  onClickChat: PropTypes.func.isRequired,
  onClickFaq: PropTypes.func.isRequired,
  haveProduct: PropTypes.bool,
};

export default MenuBadge;
