import React, { useState, useContext, createContext, useCallback } from 'react';

const VideoContext = createContext();

export default function VideoContextProvider({ children }) {
  const state = useState({
    playedSeconds: 0,
  });
  return <VideoContext.Provider value={state}>{children}</VideoContext.Provider>;
}

export const useVideoContext = () => {
  const [videoState, setVideoState] = useContext(VideoContext);

  const setPlayedSeconds = useCallback(
    (seconds) => {
      if (typeof seconds !== 'number') {
        return;
      }

      setVideoState((prevState) => ({
        ...prevState,
        playedSeconds: seconds,
      }));
    },
    [setVideoState]
  );

  return {
    videoState,
    setPlayedSeconds,
  };
};
