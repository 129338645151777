import { makeStyles } from '@material-ui/core';

const useFabStyle = makeStyles(() => ({
  root: {
    backgroundColor: 'white',
    margin: 5,
  },
}));

export default { useFabStyle };
