import { makeStyles } from '@material-ui/core';

const useButtonStyles = makeStyles(() => ({
  text: {
    padding: 0,
  },
}));

const useBoxStyles = makeStyles(() => ({
  root: {
    display: 'flex',
    justifyContent: 'center',
    height: '100%',
  },
}));

const useCloseIconStyles = makeStyles((theme) => ({
  root: {
    padding: '0px',
    color: theme.palette.primary.main,
  },
}));

const useNavButtonsStyles = {
  backgroundColor: 'transparent',
  color: '#494949',
  padding: 0,
  transform: 'scale(1.5)',
  margin: '0 10px',
};

const useImageStyle = makeStyles(() => ({
  productImage: {
    width: '100%',
    borderRadius: '5px',
  },
  carouselImage: {
    objectFit: 'contain',
    margin: '10px 10px 5px 10px',
    paddingBottom: '60px',
  },
}));

const useCloseIconButtonStyle = makeStyles(() => ({
  root: {
    '&:hover': {
      backgroundColor: 'transparent',
    },
  },
}));

export default {
  useButtonStyles,
  useBoxStyles,
  useImageStyle,
  useNavButtonsStyles,
  useCloseIconStyles,
  useCloseIconButtonStyle,
};
