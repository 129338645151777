/* eslint-disable camelcase */
import React, { useState } from 'react';

import { useEvent } from '../../events/useEvent';
import { somaEvents } from '../../events';

import GTM from './gtm';

export default (props) => {
  const { eventsData, id } = props;

  const [event, setEvent] = useState();

  const sendEvent = (eventName, params) => {
    const eventData = eventsData.google[eventName];
    if (!eventData) return;

    const payload = {
      event: eventData.event,
      category: eventData.eventCategory,
      action: eventData.eventAction,
      label: eventData.eventLabel,
    };
    if (eventName === 'timeWatching') {
      payload.value = params;
    }

    setEvent(payload);
  };

  if (eventsData && eventsData.google) {
    Object.values(somaEvents).forEach((eventName) => {
      if (eventName) {
        useEvent(eventName, (params) => sendEvent(eventName, params));
      }
    });
  }

  return (
    <>
      <GTM gtmId={id} event={event} />
    </>
  );
};
