import React from 'react';
import { ButtonGroup, Button } from '@material-ui/core';
import PlayArrowIcon from '@material-ui/icons/PlayArrow';
import PauseIcon from '@material-ui/icons/Pause';
import VolumeMuteIcon from '@material-ui/icons/VolumeMute';
import VolumeUpIcon from '@material-ui/icons/VolumeUp';
import PropTypes from 'prop-types';
import Styles from './style';

const PlayMute = (props) => {
  const { isPlaying, onClickPlay, isMuted, onClickMute, mode } = props;
  const btnGroupClasses = Styles.useButtonGroupStyle();

  return (
    <ButtonGroup disableElevation classes={btnGroupClasses}>
      {mode === 'all' && (
        <Button onClick={onClickPlay} variant="text" color="primary">
          {isPlaying ? <PlayArrowIcon /> : <PauseIcon />}
        </Button>
      )}
      <Button onClick={onClickMute} variant="text" color="primary">
        {isMuted ? <VolumeUpIcon /> : <VolumeMuteIcon />}
      </Button>
    </ButtonGroup>
  );
};

PlayMute.defaultProps = {
  isPlaying: true,
  isMuted: true,
  mode: 'no-play',
};

PlayMute.propTypes = {
  isPlaying: PropTypes.bool,
  isMuted: PropTypes.bool,
  mode: PropTypes.oneOf(['all', 'no-play']),
  onClickPlay: PropTypes.func.isRequired,
  onClickMute: PropTypes.func.isRequired,
};

export default PlayMute;
