import { makeStyles } from '@material-ui/core';

const useSearchFieldStyles = makeStyles({
  root: {
    padding: '20px 10px',
    '& .MuiOutlinedInput-root': {
      background: '#F3F3F3',
      borderRadius: '20px',
    },
    [`& fieldset`]: {
      borderRadius: '20px',
    },
  },
});

const useIconStyles = makeStyles({
  root: {
    marginRight: '10px',
    color: '#ADADAD',
    width: '24px',
    height: '24px',
  },
});

const closeIconStyle = makeStyles({
  root: {
    color: '#ADADAD',
    width: '24px',
    height: '24px',
    marginRight: '-10px',
  },
});

const iconButtonStyles = makeStyles({
  root: {
    '&:hover': {
      background: 'none',
    },
  },
});

const useFormHelperTextStyles = makeStyles((theme) => ({
  root: {
    margin: '145px 0px 0px 55px',
    '@media (max-width: 480px)': {
      margin: '145px 0px 0px 27px',
    },
    fontSize: '13px',
    color: theme.palette.primary.main,
  },
}));

const useInputStyles = makeStyles({
  input: {
    height: '14px',
    color: '#888888 ',
    fontSize: '16px',
    '@media (max-width: 480px)': {
      transform: 'translatey(-1%)',
    },
    '&::-webkit-search-cancel-button': {
      display: 'none',
    },
    '&::placeholder': {
      color: '#888888 ',
      fontSize: '14px',
    },
  },
});

export {
  useSearchFieldStyles,
  useIconStyles,
  useFormHelperTextStyles,
  useInputStyles,
  closeIconStyle,
  iconButtonStyles,
};
