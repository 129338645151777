import { makeStyles } from '@material-ui/core';

export const useDrawerStyle = makeStyles((theme) => ({
  root: {
    width: theme.drawer.width,
    padding: theme.drawer.padding,
  },
}));

const useBagDrawerStyle = makeStyles((theme) => ({
  root: {
    width: '30%',
    padding: theme.drawer.padding,
  },
}));

const useProductsDrawerStyle = makeStyles((theme) => ({
  root: {
    width: theme.drawer.width,
    padding: theme.drawer.padding,
  },
}));

export default {
  useBagDrawerStyle,
  useProductsDrawerStyle,
};
