import React from 'react';
import { useTheme } from '@material-ui/core/styles';
import { Box, Typography, Fab } from '@material-ui/core';
import CloseIcon from '@material-ui/icons/Close';
import PropTypes from 'prop-types';
import Styles from './styles';

const HeaderDrawer = (props) => {
  const theme = useTheme();

  const { titleDrawer, onClickClose, icon } = props;
  const { button } = theme.drawer.header;

  const boxClasses = Styles.useBoxStyle();
  const titleClasses = Styles.useTitleStyle();
  const buttonClasses = Styles.useButtonStyle();

  return (
    <Box classes={boxClasses}>
      {!!icon && (
        <Box classes={titleClasses}>
          <img src={icon.src} alt={icon.alt} />
          <Typography>{titleDrawer}</Typography>
        </Box>
      )}
      {!icon && <Typography>{titleDrawer}</Typography>}
      <Fab size={button.size} onClick={onClickClose} classes={buttonClasses}>
        <CloseIcon fontSize={button.size} />
      </Fab>
    </Box>
  );
};

HeaderDrawer.propTypes = {
  titleDrawer: PropTypes.string.isRequired,
  onClickClose: PropTypes.func.isRequired,
};

export default HeaderDrawer;
