import { makeStyles } from '@material-ui/core';

const useBoxStyle = makeStyles(() => ({
  root: {
    width: '100%',
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
    paddingLeft: 10,
  },
}));

const useTitleStyle = makeStyles(() => ({
  root: {
    width: '100%',
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'start',
    alignItems: 'center',
    '& img': {
      marginRight: 10,
    },
  },
}));

const useButtonStyle = makeStyles((theme) => ({
  root: {
    boxShadow: 'none',
    backgroundColor: 'transparent',
    color: theme.palette.primary.main,
  },
}));

export default { useBoxStyle, useButtonStyle, useTitleStyle };
