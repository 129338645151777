import { ptBR } from '@material-ui/core/locale';
import { themeTemplate, colorTransform } from './somalive';
import config from '../config';

const welcomeBackgroundDesktop = `${config.assetsUrl}/crisbarros/welcomeBackground.jpg`;
const welcomeBackgroundMobile = `${config.assetsUrl}/crisbarros/welcomeBackground.jpg`;
const welcomeIcon = `${config.assetsUrl}/crisbarros/welcomeLogo.png`;
const faqIcon = `${config.assetsUrl}/crisbarros/icon/ajuda.png`;
const bagIcon = `${config.assetsUrl}/crisbarros/icon/sacola.png`;
const productIcon = `${config.assetsUrl}/crisbarros/icon/produtos.png`;
const chatIcon = `${config.assetsUrl}/crisbarros/icon/chat.png`;
const couponImage = `${config.assetsUrl}/crisbarros/cupom.png`;
const saleswomanCouponImage = `${config.assetsUrl}/crisbarros/cupom-vendedora.png`;

const colorIntensity = 50;
const template = themeTemplate({
  mainColor: '#000000',
  secondaryColor: '#F3DA82',
  fontFamily: 'Futura',
  badgeBgColor: '#F3DA82',
  badgeColor: 'white',
  buttonsBorderRadius: 0,
});

const main = {
  palette: {
    primary: colorTransform(template.mainColor, colorIntensity),
    secondary: colorTransform(template.secondaryColor, colorIntensity),
  },
  typography: {
    fontFamily: template.fontFamily,
  },
  overrides: {
    MuiTypography: {
      root: {
        fontFamily: template.fontFamily,
        color: template.mainColor,
      },
    },
    MuiButton: {
      root: {
        borderRadius: template.buttonsBorderRadius,
      },
    },
    MuiAlert: {
      root: {
        color: template.mainColor,
      },
      standardSuccess: {
        color: template.mainColor,
        backgroundColor: '#fff',
        '& $icon': {
          color: template.mainColor,
        },
      },
      standardInfo: {
        color: template.mainColor,
        backgroundColor: '#fff',
        '& $icon': {
          color: template.mainColor,
        },
      },
    },
  },
  faq: {
    text: 'Ajuda',
    icon: {
      src: faqIcon,
      alt: 'Ajuda',
    },
    content: [
      {
        title: 'O que é a Loja Live Cris Barros?',
        description: 'É um evento inédito live de pré lançamento da Coleção SS21 - América Latina.',
      },
      {
        title: 'Como envio uma Mensagem?',
        description: 'Você pode interagir ou enviar uma mensagem ao vivo no “chat”.',
      },
      {
        title: 'Como vejo os Produtos?',
        description: 'Só clicar em um dos ícones que está na tela.',
      },
      {
        title: 'Como escolho um produto?',
        description:
          'Após clicar no ícone de produtos, selecione o tamanho e/ou a cor da peça desejada e clique em “incluir na sacola”.',
      },
      {
        title: 'Como finalizo a compra?',
        description:
          'Clique no ícone da sacola, no canto superior direito da tela e você será redirecionada para o nosso site. É lá que o pagamento será efetuado.',
      },
    ],
  },
  bag: {
    text: 'Sacola',
    emptyBag: 'Sua sacola está vazia',
    emptyBagColor: 'secondary',
    icon: {
      src: bagIcon,
      alt: 'Sacola',
    },
  },
  products: {
    text: 'Produtos',
    icon: {
      src: productIcon,
      alt: 'Produtos',
    },
    size: {
      text: 'Selecione o Tamanho:',
      typography: {
        variant: 'subtitle1',
      },
    },
    details: {
      text: 'Detalhes do Produto',
      button: {
        variant: 'text',
        fullWidth: false,
      },
    },
    button: {
      text: 'INCLUIR NA SACOLA',
      variant: 'outlined',
      color: 'secondary',
    },
    composition: {
      text: 'Composição',
    },
    name: {
      color: 'primary',
    },
    price: {
      sale: {
        color: 'primary',
      },
    },
    quantity: {
      variant: 'subtitle1',
    },
    notAvaiable: {
      text: 'Produto Indisponível',
    },
    search: {
      title: 'Pesquisar produtos',
      notFound: 'Produto não encontrado',
      emptyBrand: 'É necessário fornecer uma brand para pesquisar o produto',
    },
  },
  welcomePage: {
    paper: {
      backgroundImageDesktop: `url(${welcomeBackgroundDesktop})`,
      backgroundImageMobile: `url(${welcomeBackgroundMobile})`,
    },
    container: {
      justify: 'center',
      header: {
        maxHeight: '30%',
        paddingBotton: 0,
        paddingTop: 0,
        maxWidthMobile: '60%',
        maxWidthDesktop: '20%',
        welcomeIcon: {
          src: welcomeIcon,
          alt: 'crisbarros-welcome-background',
        },
      },
      content: {
        maxHeight: '50%',
        boxContainerMaxWidth: 700,
        paddingTop: 20,
        paddingBottom: 20,
        main: {
          text: 'LIVE SOCIAL DE LANÇAMENTO E VENDA EXCLUSIVA DA NOVA EDIÇÃO ORION - COLEÇÃO AW21 - ENTRE CÉU E TERRA',
        },
        additional: {
          fontSize: 16,
          text: [
            'COM THAI BUFREM, CRIS BARROS & CONVIDADAS ESPECIAIS',
            '5% da venda das peças durante a Live serão revertidos para apoiar a ONG Casa do Rio da Amazônia',
          ],
        },
        footer: {
          text: 'REPRISE DIA 14 DE ABRIL ÀS 20H',
          fontSize: 28,
        },
      },
      callToAction: {
        maxHeight: '20%',
        button: {
          text: 'CLIQUE AQUI PARA INGRESSAR NA LIVE',
          color: '#FFF',
          textColor: '#003300',
          variant: 'contained',
        },
      },
    },
  },
  checkout: {
    baseUrl: 'https://www.crisbarros.com.br',
    path: '/checkout/cart/add',
    button: {
      text: 'FECHAR COMPRA',
      variant: 'contained',
      color: 'secondary',
    },
    discountText: 'Outros Descontos',
    totalText: 'Total',
    couponText: 'CUPOM DE 15% OFF',
    subtotalText: 'Subtotal',
    installmentNumber: 12,
    installmentMinValue: 40,
    couponImage,
    saleswomanCouponImage,
  },
  chat: {
    active: false,
    text: 'Chat',
    icon: {
      src: chatIcon,
      alt: 'Chat',
    },
    mainBackgroundColor: template.mainColor,
    headerTextColor: '#FFF',
    headerText: 'Chat!',
    setNameText: 'seja bem-vinda ao nosso atendimento! Para começar digite o seu e-mail abaixo.',
    buttonTextTheme: 'primary',
    setUserNameButtonText: 'Enviar',
    colorTextChatPortrait: '#64473F',
    mainColor: template.mainColor,
    headerBackgroundColor: template.mainColor,
    tooltipEmailText: 'E-mail',
    tooltipMessageText: 'Mensagem',
    emptyMessageErrorText: 'Por favor insira uma mensagem!',
    messageSuccessText: 'Mensagem enviada com sucesso!',
    emptyNameErrorText: 'Por favor insira um nome!',
  },
  drawer: {
    header: {
      button: {
        size: 'small',
      },
    },
    padding: 10,
    width: '40%',
  },
  somaNavigation: {
    appBar: {
      position: 'static',
      elevation: 0,
      color: 'transparent',
    },
    tab: {
      textColor: 'primary',
      variant: 'fullWidth',
      indicatorColor: 'primary',
    },
    quantity: {
      box: {
        style: {
          display: 'flex',
          alignItems: 'center',
        },
      },
      button: {
        style: {
          minWidth: 50,
          fontSize: 18,
          margin: 5,
          '&:hover': {
            backgroundColor: 'transparent',
          },
        },
      },
      variant: 'subtitle1',
    },
  },
  realtimeProduct: {
    styles: {
      padding: 10,
    },
    title: 'Apresentando:',
    titleVariant: 'subtitle2',
  },
  i18n: {
    locale: 'pt-BR',
    currency: 'BRL',
  },
  operator: {},
  system: {
    messages: {
      lgpd: {
        button: {
          variant: 'outlined',
          style: {
            backgroundColor: 'transparent',
            color: '#000',
            border: '1px solid',
          },
        },
        box: { background: 'rgba(255,255,255, 0.8)' },
        text: {
          style: { color: '#000' },
          content: [
            {
              value:
                'Utilizamos cookies para personalizar e melhorar sua experiência em nosso site. Ao clicar em prosseguir, você concorda com a nossa ',
              type: 'text',
            },
            {
              value: 'Política de Privacidade.',
              type: 'link',
              href: 'https://www.crisbarros.com.br/institucional/prazo-de-entrega#privacidade',
              title: 'Política de privacidade',
            },
            {
              value: '',
              type: 'text',
            },
            {
              buttonText: 'PROSSEGUIR',
              type: 'text',
            },
          ],
        },
      },
    },
  },
};

export default { ...main, ...ptBR };

export { themeTemplate, colorTransform };
