import { makeStyles } from '@material-ui/core';

const useBoxStyle = makeStyles(() => ({
  root: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    flexDirection: 'column',
    height: '100vh',
    width: '100%',
  },
}));

export default { useBoxStyle };
