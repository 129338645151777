/* eslint-disable no-console */
import { useEvent } from '../../events/useEvent';
import config from '../../config';

export default () => {
  useEvent('logError', console.error);
  useEvent('log', console.log);
  useEvent('sendNewRelicLog', (payload) => {
    if (config.environment === 'production') {
      fetch(`${config.backendUrl}/newrelic/log`, {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify(payload),
      });
    }
  });

  return null;
};
