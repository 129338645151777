import { makeStyles } from '@material-ui/core';

const useButtonStyles = makeStyles((theme) => ({
  root: {
    textTransform: theme.products.size.typography.textTransform,
  },
}));

const useContainerStyle = (config) =>
  makeStyles({
    root: {
      padding: config.fullWidth ? '0 1.4rem 1.4rem 1.4rem' : '0',
      display: 'flex',
      flexDirection: 'column',
      flexWrap: 'wrap',
    },
  })();

const useGridStyle = (config) =>
  makeStyles({
    root: {
      justifyContent: 'flex-start',
      display: 'flex',
      flexDirection: config.fullWidth ? 'column' : 'row',
      flexWrap: 'wrap',
      padding: config.fullWidth ? '1.5rem' : '0',
    },
  })();

export default { useButtonStyles, useContainerStyle, useGridStyle };
