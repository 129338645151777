export default {
  onSignUp: 'sign_up',
  onEnter: 'enter',

  onVideoStart: 'video_start',
  onVideoPause: 'video_pause',
  onVideoEnd: 'video_complete',
  onVideoProgress: 'video_progress',

  onProductsShow: 'products_open',
  onProductsHide: 'products_close',

  onCategoryView: 'view_item_list',

  onCategoryScroll: 'scroll_item_list',
  onScroll: 'scroll',

  onItemView: 'view_item',
  onItemHide: 'close_item',
  onItemGalleryShow: 'item_gallery_open',
  onItemGaleryHide: 'item_gallery_close',
  onItemGaleryNavNext: 'item_gallery_navigate_next',
  onItemGaleryNavPrev: 'item_gallery_navigate_prev',
  onItemDetailsShow: 'item_details_open',
  onItemDetailsHide: 'item_details_close',

  onBagAdd: 'add_to_cart',
  onBagRemove: 'remove_from_cart',
  onBagCheckout: 'begin_checkout',
  onBagShow: 'cart_open',
  onBagHide: 'cart_close',
  onBagItemIncrease: 'cart_item_increase',
  onBagItemDecrease: 'cart_item_decrease',

  onChatShow: 'chat_open',
  onChatHide: 'chat_close',
  onChatSignUp: 'chat_sign_up',
  onChatMessageSent: 'chat_send_message',

  onFaqShow: 'faq_open',
  onFaqHide: 'faq_close',

  onClickMiniPlayer: 'click_redirect_miniplayer',
};
