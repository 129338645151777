import React, { useContext, useState, useMemo, useEffect } from 'react';
import PropTypes from 'prop-types';
import { useTheme } from '@material-ui/core/styles';
import useScrollTrigger from '@material-ui/core/useScrollTrigger';
import { AppBar, Tabs, Tab, Box, useMediaQuery } from '@material-ui/core';
import SomaGridList from './SomaGridList';
import { LocalStorageContext, StreamContext } from '../../../hooks/contexts';
import { addToBag as addToBagHelper } from '../../../helpers/bagHelper';
import SomaSalesCoupon from '../SalesCoupon/SomaSalesCoupon';
import { useEventDispatch, somaEvents } from '../../../events';
import Styles from './styles';
import ProductSearch from '../ProductSearch';
import SomaIconButton from '../../atoms/SomaIconButton';

const TabPanel = (props) => {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`scrollable-auto-tabpanel-${index}`}
      aria-labelledby={`scrollable-auto-tab-${index}`}
      {...other}
    >
      {value === index && <>{children}</>}
    </div>
  );
};

TabPanel.defaultProps = {
  children: null,
};

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
};

function a11yProps(index) {
  return {
    id: `scrollable-auto-tab-${index}`,
    'aria-controls': `scrollable-auto-tabpanel-${index}`,
  };
}

export default function SomaProductsList(props) {
  const { buttons = [], cols, user, scrollTarget } = props;
  const theme = useTheme();
  const { tab } = theme.somaNavigation;
  const [value, setValue] = useState(0);
  const [productsFilter, setProdutctsFilter] = useState([]);
  const [showScrollbutton, setShowScrollbutton] = useState(false);

  const allProducts = useMemo(
    () =>
      buttons
        .map((button) => button.products)
        .reduce((accumulator, currentProductsList) => accumulator.concat(currentProductsList), []),

    [buttons]
  );

  const tabClasses = Styles.useTabStyles();

  const streamContext = useContext(StreamContext);
  const userStorage = useContext(LocalStorageContext);
  const portrait = useMediaQuery('(orientation: portrait)');

  const dispatchEvent = useEventDispatch();

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  const addToBag = (product, selectedItem) => {
    const { sendSnack, bagBadgeCount, setBagBadgeCount } = streamContext;

    setBagBadgeCount(bagBadgeCount + 1);
    addToBagHelper(product, selectedItem, userStorage);
    sendSnack(theme.products.successBag.text, 'success');
  };

  const isTargetScrolled = useScrollTrigger({ target: scrollTarget?.current ?? window, disableHysteresis: true });
  useEffect(() => {
    setShowScrollbutton(isTargetScrolled);
  }, [isTargetScrolled]);

  const onClickScrollTop = () => {
    setShowScrollbutton(false);
    return scrollTarget.current?.scrollTo({ top: 0, behavior: 'smooth' });
  };

  return (
    <>
      <AppBar {...theme.somaNavigation.appBar} position="relative">
        <Tabs
          value={value}
          onChange={handleChange}
          textColor={tab.textColor}
          indicatorColor={tab.indicatorColor}
          variant="scrollable"
          scrollButtons="auto"
          aria-label="scrollable auto tabs categories buttons"
        >
          {buttons.map((button, index) => (
            <Tab
              label={button.label}
              {...a11yProps(index)}
              key={button.id_button}
              textColor={tab.textColor}
              classes={tabClasses}
              onClick={() => dispatchEvent(somaEvents.onProductCategoriesClick, button.label)}
            />
          ))}
        </Tabs>
      </AppBar>
      {buttons.map((button, index) => {
        const isShowcasing = button.id_button === 'showcasing';
        const showCoupon = button.products.length === 0 && isShowcasing;
        const columns = portrait ? 2 : cols;

        return (
          <TabPanel value={value} index={index} key={button.id_button}>
            {showCoupon ? (
              <Box display="flex" justifyContent="center">
                <SomaSalesCoupon />
              </Box>
            ) : (
              <>
                {!isShowcasing && (
                  <ProductSearch
                    productsData={buttons}
                    allProducts={allProducts}
                    categoryProducts={button.products}
                    sendSearch={setProdutctsFilter}
                  />
                )}
                <SomaGridList
                  category={button.label}
                  cols={columns}
                  products={isShowcasing ? button.products : productsFilter}
                  addToBag={addToBag}
                  user={user}
                  isShowcasing={isShowcasing}
                  scrollTarget={scrollTarget}
                />

                {showScrollbutton && <SomaIconButton onClick={onClickScrollTop} />}
              </>
            )}
          </TabPanel>
        );
      })}
    </>
  );
}
