/* eslint-disable camelcase */
import React, { useState } from 'react';
import { useTheme } from '@material-ui/core';
import Logger from './logger';
import OmniChatWeb from './omniChatWeb';
import OldGoogleTagManager from './oldGoogleTagManager';
import GoogleTagManager from './googleTagManager';
import LGPD from './LGPD';

import { useStreamContext } from '../contexts/streamContext';

export default (props) => {
  const { streamContent } = useStreamContext();

  const { config, showLGPD } = props;

  const { debug } = config;
  const { brand, stream_name, analytics } = streamContent;
  const { gtm } = analytics;

  const theme = useTheme();

  const useBrandGTM = theme.gtm.active && config.googleTagManager.active;
  const useSomaliveGTM = config.googleTagManager.active;

  const localstorageLGPDkey = `${brand.name}${stream_name}_LGPD`;
  const [cookiesAccepted, setCookiesAccepted] = useState(!!localStorage.getItem(localstorageLGPDkey));
  const setAcceptation = (value) => {
    setCookiesAccepted(value);
    localStorage.setItem(localstorageLGPDkey, true);
  };

  return (
    <>
      {debug && <Logger />}
      <OmniChatWeb />
      {useBrandGTM && (
        <>
          <OldGoogleTagManager id={gtm} eventsData={analytics} config={config} />
          <GoogleTagManager brand={brand.name} streamName={stream_name} id={gtm} />
        </>
      )}
      {useSomaliveGTM && (
        <GoogleTagManager brand={brand.name} streamName={stream_name} id={config.googleTagManager.id} />
      )}
      {!cookiesAccepted && showLGPD && <LGPD setAcceptation={setAcceptation} />}
    </>
  );
};
