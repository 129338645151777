import React, { useEffect, useRef, useContext } from 'react';
import { Grid, ListItemText } from '@material-ui/core';
import PropTypes from 'prop-types';
import Styles from './styles';
import { StreamContext } from '../../../../hooks/contexts';

const SomaChatMessageDisplay = (props) => {
  const { messages, showEmail } = props;
  const listRef = useRef();
  const { updateReadMessages } = useContext(StreamContext);

  const listClasses = Styles.useListStyle();
  const brandReplyMessage = Styles.replyMessageHeaderStyle();
  const brandReplyBodyMessage = Styles.replyMessageBodyStyle();

  useEffect(() => {
    if (!Array.isArray(messages)) return;

    if (listRef.current) listRef.current.scrollTop = listRef.current.scrollHeight;
    const unreadMessages = messages.map((message) => (message.userRead ? null : message.time)).filter(Boolean);
    if (unreadMessages.length > 0) updateReadMessages(unreadMessages);
  }, [messages, updateReadMessages]);

  return (
    Array.isArray(messages) && (
      <div className={listClasses.root} ref={listRef}>
        {messages.map((message) => {
          const { text, time, email } = message;
          const isReplyMessage = !!message.replyingTo;
          const messageHour = new Date(time).toLocaleTimeString();
          const listItemTextAttribs = showEmail || isReplyMessage ? { primary: email } : { secondary: messageHour };
          const replyMessageClasses = isReplyMessage ? brandReplyMessage : null;

          return (
            <Grid
              container
              style={{
                padding: '5px 16px',
                borderTop: '1px solid rgba(0,0,0,.12)',
              }}
              key={message.id || message.email + message.time}
            >
              <Grid item xs={12}>
                <ListItemText classes={replyMessageClasses} {...listItemTextAttribs} />
              </Grid>
              <Grid item xs={12}>
                <ListItemText
                  style={{ wordWrap: 'break-word' }}
                  primary={text}
                  classes={isReplyMessage ? brandReplyBodyMessage : null}
                />
              </Grid>
            </Grid>
          );
        })}
      </div>
    )
  );
};

SomaChatMessageDisplay.defaultProps = {
  messages: [],
  showEmail: false,
};

SomaChatMessageDisplay.propTypes = {
  messages: PropTypes.arrayOf(
    PropTypes.shape({
      text: PropTypes.string,
      time: PropTypes.string,
      email: PropTypes.string,
    })
  ),
  showEmail: PropTypes.bool,
};

export default SomaChatMessageDisplay;
