import React, { useState, useContext } from 'react';
import {
  CardHeader,
  Grid,
  IconButton,
  Typography,
  Switch,
  Button,
  Dialog,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Box,
  DialogActions,
} from '@material-ui/core';
import CloseIcon from '@material-ui/icons/Close';
import DeleteOutlineIcon from '@material-ui/icons/DeleteOutline';
import StarBorderIcon from '@material-ui/icons/StarBorder';
import StarIcon from '@material-ui/icons/Star';
import { useTheme } from '@material-ui/core/styles';
import PropTypes from 'prop-types';
import Style from './style';
import { SomaInstallmentText } from '../../molecules';
import { useEventDispatch, somaEvents, gtmEvents } from '../../../events';
import { gtmEcommerceProductAdapter } from '../../../helpers/eventHelper';
import { StreamContext } from '../../../hooks/contexts';
import { ProductDescription } from '../../atoms';

const ProductDetails = (props) => {
  const {
    productName,
    isBag,
    isOperation,
    onRemove,
    isStarred,
    setIsSwitchOn,
    productAvaiable,
    salePrice,
    fullPrice,
    details,
    portrait,
    price,
    product,
    category,
  } = props;

  const stream = useContext(StreamContext);

  const config = {
    xs: 12,
    align: portrait ? 'center' : 'left',
  };

  const alignPortrait = {
    justify: portrait ? 'center' : 'flex-start',
  };

  const fullPriceClasses = Style.useFullTypography();
  const detailBtnClasses = Style.useDetailsButton();
  const productNameClasses = Style.useProductTypography();
  const productNameDesktopClasses = Style.useProductTypographyDesktop();
  const cardClasses = Style.useCardStyle();
  const boldPriceClasses = Style.usePriceTypography();
  const detailsTypeClasses = Style.useDetailsTypography();
  const detailsClasses = Style.useDetailsStyle();
  const closeIconButtonClasses = Style.useCloseIconButtonStyle();
  const dialogActionClasses = Style.useDialogActionStyle();

  const dispatchEvent = useEventDispatch();
  const theme = useTheme();
  const { products } = theme;
  const [detailsIsOpen, setDetailsIsOpen] = useState(false);

  const title = (
    <Typography
      color={products.name.color}
      align={config.align}
      classes={portrait ? productNameClasses : productNameDesktopClasses}
    >
      {productName}
    </Typography>
  );

  const closeDialog = () => {
    setDetailsIsOpen(false);
    dispatchEvent(gtmEvents.onItemDetailsHide, {
      ...gtmEcommerceProductAdapter({
        products: [product],
        category: isBag ? 'cart' : category,
        theme,
        stream,
      }),
    });
  };

  return (
    <Grid container>
      <Grid item xs={config.xs}>
        <CardHeader
          classes={cardClasses}
          title={title}
          action={
            (isBag || isOperation) && (
              <IconButton onClick={() => onRemove(productName)}>
                <DeleteOutlineIcon />
              </IconButton>
            )
          }
        />
      </Grid>
      {isOperation && (
        <Grid container direction="column" alignItems="flex-end">
          <Grid item xs={2}>
            {(isStarred && <StarIcon />) || <StarBorderIcon />}
          </Grid>
          <Grid item xs={2}>
            <Switch onChange={setIsSwitchOn} />
          </Grid>
        </Grid>
      )}
      {productAvaiable ? (
        <>
          <Grid container item xs={12} justify={portrait ? 'center' : 'flex-start'}>
            <Typography
              variant={products.price.variant}
              align={config.align}
              classes={salePrice !== fullPrice ? fullPriceClasses : boldPriceClasses}
              color={salePrice !== fullPrice ? 'primary' : 'initial'}
            >
              {fullPrice}
            </Typography>
            {salePrice !== fullPrice && (
              <Typography
                align={config.align}
                variant={products.price.variant}
                color="error"
                classes={boldPriceClasses}
              >
                {salePrice}
              </Typography>
            )}
          </Grid>
          {!isBag && (
            <Grid item container justify={alignPortrait.justify}>
              <SomaInstallmentText value={price} align={config.align} product={product} />
            </Grid>
          )}
          {!isBag && (
            <Grid container item xs={12} justify={portrait ? 'center' : 'flex-start'}>
              <Button
                fontSize="14"
                classes={detailBtnClasses}
                {...products.details.button}
                onClick={() => {
                  setDetailsIsOpen(true);
                  dispatchEvent(gtmEvents.onItemDetailsShow, {
                    ...gtmEcommerceProductAdapter({
                      products: [product],
                      category: isBag ? 'cart' : category,
                      theme,
                      stream,
                    }),
                  });
                  dispatchEvent(somaEvents.onProductDetailClick);
                }}
              >
                <Typography classes={detailsTypeClasses}>{products.details.text}</Typography>
              </Button>
            </Grid>
          )}
          <Dialog open={detailsIsOpen} onBackdropClick={closeDialog} fullWidth>
            <DialogActions classes={dialogActionClasses}>
              <IconButton classes={closeIconButtonClasses} onClick={closeDialog}>
                <CloseIcon />
              </IconButton>
            </DialogActions>
            <DialogContent className={detailsClasses.container}>
              {details.description && <ProductDescription description={details.description} />}
              {details?.composition && (
                <>
                  <DialogTitle disableTypography className={detailsClasses.title}>
                    {products.composition.text}
                  </DialogTitle>
                  <DialogContentText className={detailsClasses.text}>{details.composition}</DialogContentText>
                </>
              )}
            </DialogContent>
          </Dialog>
        </>
      ) : (
        <Grid item xs={12}>
          <Typography align="center" component="div" variant="subtitle2" gutterBottom style={{ marginTop: 30 }}>
            <Box fontWeight="fontWeightBold">{theme.products.notAvaiable.text}</Box>
          </Typography>
        </Grid>
      )}
    </Grid>
  );
};

export default ProductDetails;

ProductDetails.defaultProps = {
  isBag: false,
  setIsSwitchOn: () => {},
  productAvaiable: false,
  isOperation: false,
  isStarred: false,
  details: null,
  onRemove: null,
  productName: null,
};

ProductDetails.propTypes = {
  productName: PropTypes.string,
  details: PropTypes.shape({
    description: PropTypes.oneOfType([
      PropTypes.string,
      PropTypes.arrayOf(
        PropTypes.shape({
          nodeType: PropTypes.string.isRequired,
          content: PropTypes.string,
        })
      ),
    ]),
    composition: PropTypes.string,
  }),
  isBag: PropTypes.bool,
  onRemove: PropTypes.func,
  setIsSwitchOn: PropTypes.func,
  productAvaiable: PropTypes.bool,
  isOperation: PropTypes.bool,
  isStarred: PropTypes.bool,
};
