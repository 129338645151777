import React, { useEffect, useState, useRef, useCallback, useContext } from 'react';
import { Grid, Dialog, IconButton } from '@material-ui/core';
import PropTypes from 'prop-types';
import { useTheme } from '@material-ui/core/styles';
import CloseIcon from '@material-ui/icons/Close';
import Skeleton from 'react-loading-skeleton';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import { SomaButton, SelectedSize, SomaSelectQuantity } from '../../atoms';
import { PlayOnTimeline, ProductImages, SelectSize } from '../../molecules';
import { formatCurrency } from '../../../helpers/currencyHelper';
import { useEventDispatch, somaEvents, gtmEvents } from '../../../events';
import { useEvent } from '../../../events/useEvent';
import ProductDetails from './Details';
import { gtmEcommerceProductAdapter } from '../../../helpers/eventHelper';
import { StreamContext } from '../../../hooks/contexts';
import style from './style';
import { useRealTimeProductsContext } from '../../../contexts/realTimeProductsContext';

const Product = (props) => {
  const { onRemove, setIsSwitchOn, isOperation, isStarred, product, updateQuantity, addToBag, isShowcasing, category } =
    props;
  const isBag = !!product.bagInfo;

  const theme = useTheme();
  const stream = useContext(StreamContext);

  const { products } = theme;
  const portrait = useMediaQuery('(orientation: portrait)');

  const [productAvaiable, setProductAvaiable] = useState(false);
  const [cartButton, setCartButton] = useState(false);
  const [openDialogSize, setOpenDialogSize] = useState(false);
  const [details, setDetails] = useState({
    description: null,
    composition: null,
  });
  const [isOnView, setIsOnView] = useState(false);

  const { realTimeProductsState } = useRealTimeProductsContext();
  const { timeline, allProductsWithIds } = realTimeProductsState;

  const productRerunTimestamps = allProductsWithIds.get(product.provider.productId?.toString());

  const productRef = useRef();

  const dispatchEvent = useEventDispatch();

  const CloseIconButtonClasses = style.useCloseIconButtonStyle(theme);

  const onSelectItem = (item) => {
    addToBag(product, item);
    setOpenDialogSize(false);

    dispatchEvent(somaEvents.onBagAdd);

    dispatchEvent(gtmEvents.onBagAdd, {
      ...gtmEcommerceProductAdapter({
        products: [
          {
            ...product,
            variant: item.size,
            sku: item.sku,
          },
        ],
        stream,
        theme,
        category,
      }),
    });
  };

  useEffect(() => {
    const avaiable = !!product.items.find((item) => item.availableQuantity > 0);
    setProductAvaiable(avaiable);
    setCartButton(avaiable);
  }, [product]);

  const isOnViewport = (element) => {
    if (!element) return false;

    const windowTop = 0;
    const windowTopToWindowBottom = window.innerHeight;

    const windowTopToElementTop = element.getBoundingClientRect().top;
    const windowTopToElementBottom = element.getBoundingClientRect().bottom;

    const topIsVisible = windowTopToElementTop >= windowTop;
    const bottomIsVisible = windowTopToElementBottom < windowTopToWindowBottom;

    return topIsVisible && bottomIsVisible;
  };

  const onViewEvent = useCallback(() => {
    const productElm = productRef.current;

    if (isOnViewport(productElm) && !isOnView) {
      setIsOnView(true);

      if (!isBag) {
        dispatchEvent(gtmEvents.onItemView, {
          ...gtmEcommerceProductAdapter({
            products: [product],
            category,
            theme,
            stream,
          }),
        });
      }
    } else if (!isOnViewport(productElm) && isOnView) {
      setIsOnView(false);
    }
  }, [category, dispatchEvent, isBag, isOnView, product, stream, theme]);

  useEffect(() => {
    // for some reason (maybe due to browser repaint time)
    // first-time calculations results are wrong
    // so timeout is necessary
    window.setTimeout(onViewEvent, 1000);

    // eslint-disable-next-line
  }, []);

  useEvent(gtmEvents.onCategoryScroll, () => {
    onViewEvent();
  });
  useEvent(somaEvents.onBagScroll, () => {
    onViewEvent();
  });
  useEvent(gtmEvents.onScroll, () => {
    onViewEvent();
  });

  useEffect(() => {
    if (product.composition || product.description)
      setDetails({
        description: product.description,
        composition: product.composition[0],
      });
  }, [product]);

  const config = {
    direction: portrait && !isBag ? 'column' : 'row',
    portraitBool: portrait && !isBag,
  };

  const detailArgs = {
    portrait: config.portraitBool,
    productName: product.productName,
    productAvaiable,
    product,
    category,
    fullPrice: formatCurrency(product.listPrice, theme.i18n),
    salePrice: formatCurrency(product.price, theme.i18n),
    price: product.price,
    details,
    isBag,
    isOperation,
    onRemove: () => {
      onRemove(product);
    },
    isStarred,
    setIsSwitchOn,
  };

  const AddToCartButton = () => (
    <SomaButton
      label={products.button.text}
      color={products.button.color}
      fontSize={12}
      onClick={() => setOpenDialogSize(true)}
    />
  );

  return (
    <Grid container ref={productRef} direction={config.direction} spacing={1}>
      <Grid container item xs={config.portraitBool ? 12 : 5} sm={5}>
        <Grid item xs={12}>
          {(product.images ? (
            <ProductImages
              product={product}
              category={category}
              isShowcasing={isShowcasing}
              isBag={isBag}
              isOnView={isOnView}
              isOnViewport={isOnViewport}
            />
          ) : null) || <Skeleton height={225} />}
          {timeline.enabled && productRerunTimestamps && !isBag && (
            <PlayOnTimeline timestamps={productRerunTimestamps} />
          )}
        </Grid>
      </Grid>
      <Grid container item xs={config.portraitBool ? 12 : 7} sm={7} alignContent="space-between">
        <Grid item xs={12}>
          {<ProductDetails isBag={isBag} {...detailArgs} /> || <Skeleton count={3} height={40} />}
        </Grid>
        {isBag && (
          <>
            <Grid item xs={12}>
              <SelectedSize size={product.bagInfo.selectedItem.size} />
            </Grid>
            <Grid item xs={12}>
              <SomaSelectQuantity
                number={product.bagInfo.quantity}
                onClickMinus={() => {
                  updateQuantity(product.bagInfo.uuid, -1);

                  dispatchEvent(somaEvents.onBagItemDecrease);
                  dispatchEvent(gtmEvents.onBagItemDecrease, {
                    ...gtmEcommerceProductAdapter({
                      products: [
                        {
                          ...product,
                          sku: product.bagInfo.selectedItem.sku,
                          variant: product.bagInfo.selectedItem.size,
                          quantity: product.bagInfo.quantity - 1,
                        },
                      ],
                      category: isBag ? 'cart' : category,
                      stream,
                      theme,
                    }),
                  });
                }}
                onClickPlus={() => {
                  updateQuantity(product.bagInfo.uuid, 1);

                  dispatchEvent(somaEvents.onBagItemIncrease);
                  dispatchEvent(gtmEvents.onBagItemIncrease, {
                    ...gtmEcommerceProductAdapter({
                      products: [
                        {
                          ...product,
                          sku: product.bagInfo.selectedItem.sku,
                          variant: product.bagInfo.selectedItem.size,
                          quantity: product.bagInfo.quantity + 1,
                        },
                      ],
                      category: isBag ? 'cart' : category,
                      stream,
                      theme,
                    }),
                  });
                }}
              />
            </Grid>
          </>
        )}
        <Grid item xs={12}>
          {productAvaiable && !isBag ? (cartButton && <AddToCartButton />) || <Skeleton height={50} /> : null}
        </Grid>
        <Dialog open={openDialogSize} onBackdropClick={() => setOpenDialogSize(false)}>
          <Grid container justify="flex-end">
            <IconButton classes={CloseIconButtonClasses} onClick={() => setOpenDialogSize(false)}>
              <CloseIcon />
            </IconButton>
          </Grid>
          <SelectSize direction="column" items={product.items} onSelect={onSelectItem} />
        </Dialog>
      </Grid>
    </Grid>
  );
};

Product.defaultProps = {
  isBag: false,
  setIsSwitchOn: () => {},
  productAvaiable: false,
  isOperation: false,
  isStarred: false,
  price: 0,
  details: null,
  onRemove: null,
  images: [],
  productName: null,
};

Product.propTypes = {
  images: PropTypes.arrayOf(PropTypes.string),
  productName: PropTypes.string,
  price: PropTypes.number,
  details: PropTypes.shape({
    description: PropTypes.string,
    composition: PropTypes.string,
  }),
  isBag: PropTypes.bool,
  onRemove: PropTypes.func,
  setIsSwitchOn: PropTypes.func,
  productAvaiable: PropTypes.bool,
  isOperation: PropTypes.bool,
  isStarred: PropTypes.bool,
};

export default Product;
