import React from 'react';
import { Grid, TextField } from '@material-ui/core';
import PropTypes from 'prop-types';
import SomaButton from '../../atoms/SomaButton';

const OperatorProduct = (props) => {
  const { fieldLabel, clickAddBtn, clickRmvBtn, addProductText, addBtnText, removeBtnText, clickAddProduct } = props;

  return (
    <Grid container spacing={0}>
      <Grid item xs={6}>
        <TextField label={fieldLabel} variant="outlined" size="small" fullWidth />
      </Grid>

      <Grid item xs={6}>
        <SomaButton label={addBtnText} onClick={clickAddBtn} />
      </Grid>

      <Grid item xs={12}>
        <SomaButton label={removeBtnText} color="secondary" onClick={clickRmvBtn} />
      </Grid>

      <Grid item xs={12}>
        <SomaButton label={addProductText} onClick={clickAddProduct} />
      </Grid>
    </Grid>
  );
};

OperatorProduct.defaultProps = {
  fieldLabel: 'Nome do botão',
  addProductText: 'Adicionar Produto',
  addBtnText: 'Adicionar botão',
  removeBtnText: 'Remover botão',
};

OperatorProduct.propTypes = {
  fieldLabel: PropTypes.string,
  clickAddProduct: PropTypes.func.isRequired,
  addProductText: PropTypes.string,
  clickAddBtn: PropTypes.func.isRequired,
  addBtnText: PropTypes.string,
  removeBtnText: PropTypes.string,
  clickRmvBtn: PropTypes.func.isRequired,
};

export default OperatorProduct;
