import React from 'react';
import Input from '@material-ui/core/Input';
import InputAdornment from '@material-ui/core/InputAdornment';
import PropTypes from 'prop-types';
import { Link, ContentCopy } from '@mui/icons-material';
import { useInputStyles } from './styles';
import SomaButton from '../SomaButton';

const SomaCartUrlInput = (props) => {
  const {
    borderColor,
    url,
    textColor,
    label: buttonLabel,
    fontWeight: buttonFontWeight,
    sendSnack,
    successCopyText,
    errorCopyText,
  } = props;

  const inputClasses = useInputStyles({ color: borderColor });

  const onCopyClick = () => {
    try {
      window.navigator.clipboard.writeText(url);
      sendSnack(successCopyText, 'success');
    } catch (e) {
      sendSnack(errorCopyText, 'error');
    }
  };

  return (
    <Input
      type="text"
      value={url}
      disabled
      classes={inputClasses}
      autoFocus={false}
      disableUnderline
      startAdornment={<Link href style={{ color: textColor }} />}
      endAdornment={
        <InputAdornment position="end">
          <SomaButton
            label={buttonLabel}
            textColor={textColor}
            fontWeight={buttonFontWeight}
            onClick={onCopyClick}
            height={25}
            rlPadding="3em"
            noShadows
            endIcon={<ContentCopy />}
          />
        </InputAdornment>
      }
    />
  );
};

SomaCartUrlInput.defaultProps = {
  borderColor: 'black',
  textColor: 'black',
  fontWeight: 'bold',
};

SomaCartUrlInput.propTypes = {
  borderColor: PropTypes.string,
  url: PropTypes.string.isRequired,
  textColor: PropTypes.string,
  label: PropTypes.string.isRequired,
  fontWeight: PropTypes.string,
};

export default SomaCartUrlInput;
