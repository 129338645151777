import { makeStyles } from '@material-ui/core';

const useBoxStyle = makeStyles(() => ({
  root: {
    display: 'flex',
    alignItems: 'center',
  },
}));

const useButtonStyle = makeStyles(() => ({
  root: {
    minWidth: 50,
    fontSize: 18,
    margin: 5,
    '&:hover': {
      backgroundColor: 'transparent',
    },
  },
}));

const useTextStyle = makeStyles((theme) => ({
  root: {
    fontSize: theme.products.quantity.size,
  },
}));

export default { useButtonStyle, useBoxStyle, useTextStyle };
