import React, { useState } from 'react';
import { Button, Typography, Link, Box, useTheme } from '@material-ui/core';
import { useWrapperStyle, useTextStyle, useLinkStyle, useButtonAcceptStyle } from './styles';

const LGPD = ({ setAcceptation }) => {
  const theme = useTheme();
  const wrapperStyle = useWrapperStyle();
  const textStyle = useTextStyle();
  const linkStyle = useLinkStyle();
  const buttonAcceptStyle = useButtonAcceptStyle();
  const [transform, setTransform] = useState('translateY(0)');

  const handleAcceptation = () => {
    setTransform('translateY(100%)');
    setTimeout(() => setAcceptation(true), 200);
  };

  return (
    <Box className={wrapperStyle.root} style={{ transform }}>
      <Typography classes={textStyle}>
        {theme.system.messages.lgpd.text.content.map((item) => {
          if (item.type === 'text') {
            return item.value;
          }
          if (item.type === 'link') {
            return (
              <Link href={item.href} target="_blank" title={item.title} classes={linkStyle} key={item.value}>
                {item.value}
              </Link>
            );
          }
          return null;
        })}
      </Typography>
      <Button
        variant={theme.system.messages.lgpd.button.variant}
        onClick={handleAcceptation}
        classes={buttonAcceptStyle}
      >
        {theme.system.messages.lgpd.text.content.map((item) => item.buttonText)}
      </Button>
    </Box>
  );
};

export default LGPD;
