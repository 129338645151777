import { makeStyles } from '@material-ui/core';

const useVideoStyle = makeStyles(() => ({
  root: {},
}));

const useMainGridStyle = ({ portrait, showWelcome }) =>
  makeStyles({
    root: {
      display: showWelcome ? 'none' : 'flex',
      height: '100vh',
      flexWrap: portrait ? 'nowrap' : null,
      overflow: 'hidden',
      position: 'relative',
    },
  })();

const useContentStyle = ({ liftedTabValue, liftedStateKeyboardShown, email }) => {
  let contentWrapperOverflow = 'auto';

  if (liftedTabValue === 'chat') {
    contentWrapperOverflow = liftedStateKeyboardShown && !email ? 'visible' : 'hidden';
  }

  return makeStyles({
    root: {
      display: 'block',
      position: 'relative',
      width: '100%',
      overflow: 'auto',
    },
    videoWrapper: {
      position: 'absolute',
      height: '100%',
      left: 80,
      width: 'calc(100% - 80px)',
      '@media(min-width:1280px)': {
        width: 'calc(100% - 180px)',
      },
      '@media(orientation: portrait)': {
        position: 'relative',
        width: '100%',
        height: 'auto',
        paddingBottom: '56.25%',
        left: 0,
      },
    },
    contentWrapper: {
      overflow: contentWrapperOverflow,
      flex: '1 1 100%',
      display: 'flex',
    },
  })();
};

const useLandscapeShowcasingStyle = makeStyles({
  root: {
    position: 'absolute',
    bottom: 10,
    left: 10,
  },
});

const useProductBoxStyle = makeStyles({
  root: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',
    backgroundColor: 'rgba(255, 255, 255, 0.7)',
    maxWidth: 80,
    position: 'absolute',
    flexWrap: 'nowrap',
    bottom: 0,
    left: 0,
    height: 65,
  },
});

const useRenderLiveProductBoxStyles = ({ liveProductNumber }) => {
  const useCustomMakeStyles = makeStyles((theme) => ({
    root: {
      width: liveProductNumber * theme.realtimeProduct.modalWidthMobile,
      '@media(min-width:900px)': {
        width: liveProductNumber * theme.realtimeProduct.modalWidthDesktop,
      },
      backgroundColor: '#FFF',
      maxWidth: `40vw`,
      position: 'absolute',
      bottom: 70,
    },
  }));

  return useCustomMakeStyles();
};

const useDialogStyle = makeStyles({
  root: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
  },
});

const useBadgeMenuStyle = makeStyles({
  root: { position: 'fixed', top: 10, right: 10 },
});

const useGridChatStyle = makeStyles({
  root: {
    display: 'flex',
    flexDirection: 'column',
    flexWrap: 'nowrap',
    overflow: 'hidden',
    height: '100%',
  },
  formWrapper: {
    flex: '0 0 auto',
  },
  messagesWrapper: {
    flex: '1 1 100%',
    overflow: 'hidden',
  },
});

const useFaqStyles = makeStyles((theme) => ({
  root: {
    padding: 1,
    minWidth: 32,
    margin: '5px 0',
    color: theme.palette.primary.main,
  },
}));

export default {
  useMainGridStyle,
  useVideoStyle,
  useContentStyle,
  useProductBoxStyle,
  useDialogStyle,
  useBadgeMenuStyle,
  useLandscapeShowcasingStyle,
  useGridChatStyle,
  useFaqStyles,
  useRenderLiveProductBoxStyles,
};
