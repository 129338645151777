import { makeStyles } from '@material-ui/core';

const useInputStyles = ({ color }) => {
  const border = color ? `2px dotted ${color}` : '2px dotted black';
  return makeStyles({
    root: {
      width: '100%',
      border,
      paddingLeft: 4,
      paddingRight: 4,
    },
  })();
};

export { useInputStyles };
