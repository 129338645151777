import { makeStyles } from '@material-ui/core';

const usePriceTypography = makeStyles({
  root: {
    fontSize: 12,
    fontWeight: 'bold',
    textTransform: 'uppercase',
  },
});

const useFullTypography = makeStyles(() => ({
  root: {
    fontSize: 12,
    textDecoration: 'line-through',
    textTransform: 'uppercase',
    marginRight: 5,
  },
}));

const useProductTypography = makeStyles(() => ({
  root: {
    fontSize: 14,
    whiteSpace: 'nowrap',
    textOverflow: 'ellipsis',
    overflow: 'hidden',
    width: '100%',
  },
}));

const useProductTypographyDesktop = makeStyles(() => ({
  root: {
    fontSize: 14,
    width: '100%',
  },
}));

const useDetailsTypography = makeStyles(() => ({
  root: {
    fontSize: 14,
  },
}));

const useDetailsButton = makeStyles((theme) => ({
  text: {
    fontSize: 14,
  },
  root: {
    fontSize: 14,
    fontWeight: '400',
    textDecoration: 'underline',
    marginTop: 1,
    marginBottom: 10,
    padding: 0,
    '&:hover': {
      textDecoration: 'underline',
    },
    color: theme.palette.primary.main,
  },
}));

const useCardStyle = makeStyles(() => ({
  root: {
    width: '100%',
    padding: 0,
  },
  content: {
    width: '100%',
  },
}));

const useDetailsStyle = makeStyles((theme) => ({
  container: {
    padding: 20,
    paddingTop: 0,
  },
  text: {
    color: theme.palette.primary.main,
    fontSize: 12,
    margin: 0,
  },
  title: {
    color: theme.palette.primary.main,
    fontSize: 15,
    margin: '0 0 12px 0',
  },
}));

const useDialogActionStyle = makeStyles(() => ({
  root: {
    display: 'flex',
    flexDirection: 'row-reverse',
    justifyContent: 'space-between',
    padding: 0,
  },
}));

const useCloseModalSizeIconStyles = makeStyles(() => ({
  root: {
    width: '100%',
    display: 'flex',
    justifyContent: 'end',
  },
}));

const useCloseIconButtonStyle = makeStyles((theme) => ({
  root: {
    padding: 0,
    fontSize: '1rem',
    color: theme.palette.primary.main,
    margin: '10px',
    '&:hover': {
      backgroundColor: 'transparent',
    },
  },
}));

export default {
  useFullTypography,
  useDetailsButton,
  useProductTypography,
  useCardStyle,
  usePriceTypography,
  useDetailsTypography,
  useProductTypographyDesktop,
  useDetailsStyle,
  useDialogActionStyle,
  useCloseModalSizeIconStyles,
  useCloseIconButtonStyle,
};
