const CONFIG = {
  productsTimelineEndpoint: '/products/timeline/',
  backendUrl: process.env.REACT_APP_BACKEND_URL,
  websocketUrl: process.env.REACT_APP_WEBSOCKET_URL,
  assetsUrl: process.env.REACT_APP_ASSETS_URL,
  videoReloadUrlDelayMs: 5000,
  videoTrackingInterval: 60000,
  streamContentUpdateInterval: 60000,
  requestTimeout: 15000,
  debug: false,
  loginUrl: `${process.env.REACT_APP_BACKEND_URL}/auth/login`,
  environment: process.env.REACT_APP_ENVIRONMENT,
  googleTagManager: {
    id: 'GTM-TZ4DBR9',
    active: process.env.REACT_APP_ACTIVE_GTM === 'enabled',
  },
  vendorParam: 'cod=',
};
if (process.env.REACT_APP_ENVIRONMENT === 'development') {
  CONFIG.debug = true;
}
if (process.env.NODE_ENV === 'development') {
  CONFIG.backendUrl = process.env.REACT_APP_BACKEND_URL_LOCAL;
  CONFIG.websocketUrl = process.env.REACT_APP_WEBSOCKET_URL_LOCAL;
  CONFIG.debug = true;
  CONFIG.loginUrl = `${process.env.REACT_APP_BACKEND_URL_LOCAL}/auth/login`;
}

export default CONFIG;
