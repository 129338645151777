import { makeStyles } from '@material-ui/core';

const usePaperStyle = (paper, portrait) =>
  makeStyles({
    root: {
      position: 'fixed',
      left: 0,
      top: 0,
      alignItems: 'center',
      backgroundSize: 'cover',
      backgroundRepeat: 'no-repeat',
      backgroundPosition: 'center center',
      backgroundColor: 'white',
      width: '100%',
      height: '100%',
      backgroundImage: portrait ? paper.backgroundImageMobile : paper.backgroundImageDesktop,
      overflow: 'hidden',
    },
  });

const useContainerStyle = makeStyles({
  container: {
    width: '100%',
    height: '100%',
  },
});

const useLogoStyle = makeStyles((theme) => {
  const { paddingBotton, paddingTop } = theme.welcomePage.container.header;

  return {
    root: {
      paddingBotton,
      paddingTop,
      display: 'none',
    },
  };
});

const useWelcomeContentStyles = makeStyles((theme) => {
  const { paddingTop, paddingRight, paddingLeft, paddingBottom, maxHeight } = theme.welcomePage.container.content;
  return {
    root: {
      display: 'none',
      maxHeight,
      paddingTop,
      paddingRight,
      paddingLeft,
      paddingBottom,
      overflowY: 'auto',
    },
  };
});

const useCallToActionDesktopStyles = makeStyles((theme) => {
  const { maxHeight, margin, width } = theme.welcomePage.container.callToAction;
  const screenWidth = window.screen.width;
  return {
    root: {
      maxHeight,
      margin,
    },
    item: {
      width,
      position: 'absolute',
      top: '50%',
      transform: 'translateY(-50%)',
      marginTop: screenWidth === 1920 ? '290px' : '200px',
      backgroundColor: 'green',
    },
  };
});

const useCallToActionLandscapeStyles = makeStyles((theme) => {
  const { maxHeight, margin, width } = theme.welcomePage.container.callToAction;
  return {
    root: {
      maxHeight,
      margin,
    },
    item: {
      width,
      position: 'absolute',
      top: '50%',
      transform: 'translateY(-290%)',
      marginTop: '200px',
      backgroundColor: 'green',
    },
  };
});

const useCallToActionPortraitStyles = makeStyles((theme) => {
  const { maxHeight, margin, width } = theme.welcomePage.container.callToAction;
  return {
    root: {
      maxHeight,
      margin,
    },
    item: {
      width,
      position: 'absolute',
      top: '50%',
      transform: 'translateY(-50%)',
      marginTop: '200px',
      backgroundColor: 'blue',
    },
  };
});

export default ({ portrait, paper }) => ({
  usePaperStyle: usePaperStyle(paper, portrait),
  useLogoStyle,
  useContainerStyle,
  useWelcomeContentStyles,
  useCallToActionDesktopStyles,
  useCallToActionLandscapeStyles,
  useCallToActionPortraitStyles,
});
