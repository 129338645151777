import React, { useEffect, useState, useRef, useCallback } from 'react';
import { BrowserRouter, Route, Switch } from 'react-router-dom';
import Helmet from 'react-helmet';

import { Grid, Snackbar } from '@material-ui/core';
import CssBaseline from '@material-ui/core/CssBaseline';
import { ThemeProvider } from '@material-ui/core/styles';
import CloseIcon from '@material-ui/icons/Close';
import Alert from '@material-ui/lab/Alert';

import { Stream, EmbedLiveStream } from './components/pages';
import config from './config';
import Theme from './themes/index';
import { decodeLogin } from './helpers/authHelper';
import { get as getStorage, save } from './helpers/localStorageHelper';
import { UserContext, SalesWomanContext, LocalStorageContext } from './hooks/contexts';
import { EventEmitter } from './events/eventEmitter';
import Plugins from './plugins';
import { socketBuilder } from './services/socket';
import { useEventDispatch, newRelicEvents } from './events';
import {
  VideoContextProvider,
  RealTimeProductsContextProvider,
  SocketContextProvider,
  StreamContextProvider,
} from './contexts';
import { useRealTimeProductsContext } from './contexts/realTimeProductsContext';
import { useSocketContext } from './contexts/socketContext';
import { useStreamContext } from './contexts/streamContext';

import SomaliveAPIClient from './services/somaliveAPI';

const [, streamName, embed] = window.location.pathname.split('/');
const isEmbed = embed === 'embed';

const urlSearchParams = new URLSearchParams(window.location.search);
const saleswomanCouponCode = urlSearchParams.get('codigovendedora') || urlSearchParams.get('cod');

const Somalive = new SomaliveAPIClient(streamName);

const spinningLoading = `${config.assetsUrl}/default/loading.gif`;

const path = (/#!(\/.*)$/.exec(window.location.hash) || [])[1];
if (path) {
  window.history.replaceState(null, 'Soma Live', path);
}

const AppContainer = () => {
  const user = decodeLogin();

  const [theme, setTheme] = useState();
  const [brand, setBrand] = useState();
  const [initialData, setInitialData] = useState();
  const [userStorage, setUserStorage] = useState();
  const [showLGPD, setShowLGPD] = useState(false);
  const [openSnackBar, setOpenSnackBar] = useState(false);
  const [snackBarText, setSnackBarText] = useState('');
  const [snackBarTypeAlert, setSnackBarType] = useState('');

  const snackRef = useRef();

  const dispatchEvent = useEventDispatch();
  const { setRealTimeProductsTimeline, setShowCasing } = useRealTimeProductsContext();
  const { socket, setSocket, setReceivedSurvey, setReceivedMessage } = useSocketContext();
  const { streamContent, setStreamContent } = useStreamContext();

  const sendSnack = (text, type) => {
    setSnackBarText(text);
    setOpenSnackBar(true);
    setSnackBarType(type);
  };

  const setStreamInfo = useCallback(
    () =>
      Somalive.getStream('id_stream,brand,is_live,stream_name,sales_channel,video,analytics')
        .then((response) => {
          const { timeline, ...result } = response;
          setShowCasing([]);
          setStreamContent(result);
          setRealTimeProductsTimeline(timeline);
        })
        .catch(() => {
          dispatchEvent(newRelicEvents.log, {
            type: 'Error',
            message: 'Failed to get getStreamInfo',
            path: 'App.js -> useCallback[0]',
          });
          setStreamContent(null);
          setRealTimeProductsTimeline(false);
        }),
    [dispatchEvent, setRealTimeProductsTimeline, setShowCasing, setStreamContent]
  );

  const setEmbedInfo = useCallback(
    () =>
      Somalive.getEmbedInfo()
        .then((response) => {
          setStreamContent(response);
        })
        .catch((e) => {
          dispatchEvent(newRelicEvents.log, {
            type: 'Error',
            message: `Failed to get embed Info: ${e.message}`,
            path: 'App.js -> useCallback[0]',
          });
          setStreamContent(null);
        }),
    [dispatchEvent, setStreamContent]
  );

  const setProductsInfo = useCallback(
    () =>
      Somalive.getStream('buttons')
        .then((response) => {
          if (response.buttons) setStreamContent(response);
        })
        .catch(() => {
          dispatchEvent(newRelicEvents.log, {
            type: 'Error',
            message: 'Failed to request products',
            path: 'App.js -> useCallback[1]',
          });
        }),
    [dispatchEvent, setStreamContent]
  );

  useEffect(() => {
    if (!streamContent || !streamContent.brand || !streamContent.stream_name) return;
    setTheme(Theme(streamContent.brand.name, streamContent.stream_name));
    setBrand(streamContent.brand);
    setUserStorage(getStorage(streamContent.brand.name + streamContent.stream_name));
  }, [streamContent]);

  useEffect(() => {
    if (isEmbed) {
      setEmbedInfo();
      return;
    }
    setStreamInfo();
  }, [setEmbedInfo, setProductsInfo, setStreamInfo]);

  useEffect(() => {
    const intervalId = setInterval(setProductsInfo, config.streamContentUpdateInterval);
    setProductsInfo();
    return () => window.clearInterval(intervalId);
  }, [dispatchEvent, setProductsInfo]);

  useEffect(() => {
    if (!socket) return undefined;

    socket.on('connect', () => {
      socket.emit('getCurrentProduct', 'getCurrentProduct');
      const chat = userStorage?.get('chat');
      if (chat) {
        socket.emit('joinUserPrivateRoom', chat.get('privateRoom'));
      }
    });

    socket.on('receivePrivateReplies', (privateReplies) => {
      const chat = userStorage?.get('chat');
      privateReplies.forEach((reply) => {
        const found = chat.get('messages').find((message) => message.replyingTo === reply.replyingTo);
        if (found) return;

        chat.set('messages', chat.get('messages').concat(reply));
        save(userStorage);
        setUserStorage(new Map(userStorage));
      });
    });

    socket.on('refreshStream', (streamData) => {
      if (streamContent) {
        if (/^\d+$/.test(streamData.video?.url)) {
          return Somalive.getVimeoInfo(streamData.video.url)
            .then((result) => {
              const vimeoData = { ...streamData.video, url: result.liveUrl };
              return setStreamContent({ is_live: streamData.isLive, video: { ...vimeoData } });
            })
            .catch((error) => {
              dispatchEvent(newRelicEvents.log, {
                type: 'Error',
                message: `Error on socket refreshStream :${error.message}`,
                path: 'App.js -> useEffect[1]',
              });
            })
            .finally(() => setStreamContent({ ...streamData, is_live: streamData.isLive }));
        }
        return setStreamContent({ ...streamData, is_live: streamData.isLive });
      }
      return null;
    });

    socket.on('updateRealTimeProducts', (realtimeProducts) => {
      setShowCasing(realtimeProducts);
    });

    socket.on('currentProductData', (currentProducts) => {
      if (currentProducts) {
        setShowCasing(currentProducts);
      }
    });

    socket.on('currentData', (data) => {
      if (user) {
        setInitialData(data);
      }
    });

    socket.on('receiveSurvey', (data) => {
      setReceivedSurvey(data);
    });

    socket.on('receivedMessage', (data) => {
      setReceivedMessage(data);
    });

    return () => {
      socket.removeAllListeners();
    };
  }, [
    socket,
    user,
    streamContent,
    userStorage,
    dispatchEvent,
    setShowCasing,
    setReceivedSurvey,
    setReceivedMessage,
    setStreamContent,
  ]);

  useEffect(() => {
    if (!brand || !streamContent?.stream_name || socket) return;

    const socketProps = { brand: brand.name, streamName: streamContent.stream_name };

    setSocket(socketBuilder(socketProps));
  }, [brand, setSocket, socket, streamContent]);

  return (
    <EventEmitter>
      {streamContent && theme && userStorage && (
        <ThemeProvider theme={theme}>
          <Helmet>
            <link rel="icon" type="favicon.ico" href={theme.favicon} sizes="16x16" />
            <title>{theme.title}</title>
          </Helmet>
          <CssBaseline />
          <Plugins brand={brand} config={config} showLGPD={showLGPD} />
          <Snackbar
            open={openSnackBar}
            anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
            onClose={() => setOpenSnackBar(false)}
            autoHideDuration={3000}
            fullWidth
            ref={snackRef}
          >
            <Alert
              severity={snackBarTypeAlert}
              action={<CloseIcon style={theme.snackbar.iconStyles} onClick={() => setOpenSnackBar(false)} />}
            >
              {snackBarText}
            </Alert>
          </Snackbar>

          <Grid container>
            <UserContext.Provider value={user}>
              <SalesWomanContext.Provider value={{ saleswomanCouponCode }}>
                <BrowserRouter>
                  <Switch>
                    <Route path="/:stream/embed" render={() => <EmbedLiveStream sendSnack={sendSnack} />} />
                    <Route
                      path="/:stream"
                      render={() => (
                        <LocalStorageContext.Provider value={userStorage}>
                          <Grid item xs={12}>
                            <Stream
                              user={user}
                              content={streamContent}
                              sendSnack={sendSnack}
                              initialData={initialData}
                              setShowLGPD={setShowLGPD}
                            />
                          </Grid>
                        </LocalStorageContext.Provider>
                      )}
                    />
                  </Switch>
                </BrowserRouter>
              </SalesWomanContext.Provider>
            </UserContext.Provider>
          </Grid>
        </ThemeProvider>
      )}
      {!streamContent && <img src={spinningLoading} className="somalive-loading" alt="loading..." />}
    </EventEmitter>
  );
};

const App = () => (
  <StreamContextProvider>
    <VideoContextProvider>
      <RealTimeProductsContextProvider>
        <SocketContextProvider>
          <AppContainer />
        </SocketContextProvider>
      </RealTimeProductsContextProvider>
    </VideoContextProvider>
  </StreamContextProvider>
);

export default App;
