import React, { useState, useContext } from 'react';
import { useTheme } from '@material-ui/core/styles';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import { Box, Dialog, Button, Grid, Typography, IconButton } from '@material-ui/core';
import Carousel from 'react-material-ui-carousel';
import PropTypes from 'prop-types';
import CloseIcon from '@material-ui/icons/Close';
import SelectSize from '../SelectSize';
import SomaInstallmentText from '../InstallmentText';
import Styles from './styles';
import { gtmEvents, somaEvents, useEventDispatch } from '../../../events';
import { gtmEcommerceProductAdapter } from '../../../helpers/eventHelper';
import { StreamContext } from '../../../hooks/contexts';
import { ProductDescription } from '../../atoms';

const RealtimeImage = (props) => {
  const { show, direction, productAvaiable, onSelectItem, product } = props;

  const [showCarousel, setShowCarousel] = useState(false);

  const { images: imagesArray, width } = props;
  const mainPicture = imagesArray[0];
  const theme = useTheme();
  const portrait = useMediaQuery('(orientation: portrait)');
  const nameClasses = Styles.useNameStyle();
  const nameBoxClasses = Styles.useNameBoxStyle();
  const boldClasses = Styles.useBoldStyle();
  const imageClasses = Styles.useImageStyle();
  const gridClasses = Styles.useGridStyle();
  const boxClasses = Styles.useBoxStyles();
  const carouselClasses = Styles.useCarouselStyles();
  const formattedSalesPricesClasses = Styles.useFormattedSalesPricesStyle();
  const dispatchEvent = useEventDispatch();
  const formattedPricesClasses = Styles.useFullTypography();
  const formattedPricesBoldClasses = Styles.usePriceTypography();
  const detailsClasses = Styles.useDetailsStyle();
  const navButtonClasses = Styles.useNavButtonsStyles;
  const stream = useContext(StreamContext);
  const CloseIconButtonClasses = Styles.useCloseIconButtonStyle();

  const allProducts =
    stream.buttons
      ?.map((button) => button.products)
      .reduce((accumulator, currentProductsList) => accumulator.concat(currentProductsList), []) || [];
  const productData = allProducts.find((item) => item.id === product.id);

  if (!productData) return null;

  const { images, productName, price, listPrice, items } = productData;

  const formattedPrice = new Intl.NumberFormat(theme.i18n.locale, {
    style: 'currency',
    currency: theme.i18n.currency,
    maximumFractionDigits: 2,
  }).format(listPrice);

  const formattedSalesPrice = new Intl.NumberFormat(theme.i18n.locale, {
    style: 'currency',
    currency: theme.i18n.currency,
    maximumFractionDigits: 2,
  }).format(price);

  const [imgWidth, imgHeight] = ['xs', 'sm'].includes(width) ? [233, 350] : [300, 450];

  const renderDetails = () => (
    <Grid item classes={gridClasses}>
      <Box classes={nameBoxClasses}>
        <Typography classes={nameClasses}>{productName}</Typography>
      </Box>
      {productAvaiable ? (
        <>
          <Box classes={boldClasses}>
            <Typography
              classes={listPrice !== price ? formattedPricesClasses : formattedPricesBoldClasses}
              color={listPrice !== price ? 'primary' : 'initial'}
            >
              {formattedPrice}
            </Typography>
            {listPrice !== price && (
              <Typography color="error" classes={formattedSalesPricesClasses}>
                {formattedSalesPrice}
              </Typography>
            )}
            <SomaInstallmentText value={price} align="left" product={product} />
          </Box>
          <SelectSize
            direction={direction}
            items={items}
            onSelect={(item) => {
              onSelectItem(item);
              setShowCarousel(false);
            }}
          />
          <Box className={detailsClasses.container}>
            {productData.description && <ProductDescription description={productData.description} />}
            {productData?.composition && (
              <Typography className={detailsClasses.title}>{theme.products.composition.text}</Typography>
            )}
            {productData?.composition && (
              <Typography className={detailsClasses.text}>{productData.composition}</Typography>
            )}
          </Box>
        </>
      ) : (
        <Box classes={boldClasses}>
          <Typography classes={nameClasses} align={portrait ? 'center' : 'left'} gutterBottom>
            {theme.products.notAvaiable.text}
          </Typography>
        </Box>
      )}
    </Grid>
  );

  return (
    <Box flex={1} display={show ? 'block' : 'none'} classes={boxClasses}>
      <Button
        onClick={() => {
          if (showCarousel) return;
          setShowCarousel(true);
          dispatchEvent(somaEvents.onProductShowcasingClick);
          dispatchEvent(somaEvents.onProductClick);

          dispatchEvent(gtmEvents.onItemView, {
            ...gtmEcommerceProductAdapter({
              products: [productData],
              category: 'showcasing',
              theme,
              stream,
            }),
          });
        }}
      >
        <img
          key={mainPicture.src || mainPicture}
          src={mainPicture.src || mainPicture}
          alt={mainPicture.alt || mainPicture}
          className={imageClasses.productImage}
        />
      </Button>
      <Dialog
        open={showCarousel}
        onClose={() => {
          setShowCarousel(false);
          dispatchEvent(gtmEvents.onItemHide, {
            ...gtmEcommerceProductAdapter({
              products: [productData],
              category: 'showcasing',
              theme,
              stream,
            }),
          });
        }}
      >
        <Grid container justify="flex-end">
          <IconButton classes={CloseIconButtonClasses}>
            <CloseIcon onClick={() => setShowCarousel(false)} />
          </IconButton>
        </Grid>
        <Grid item container xs={12} direction="row">
          <Grid item xs={portrait ? 12 : 6}>
            <div className={carouselClasses.container}>
              <Carousel
                indicators={false}
                navButtonsAlwaysVisible
                autoPlay={false}
                navButtonsProps={{ style: navButtonClasses }}
                next={() => {
                  dispatchEvent(gtmEvents.onItemGaleryNavNext, {
                    ...gtmEcommerceProductAdapter({
                      products: [productData],
                      category: 'showcasing',
                      theme,
                      stream,
                    }),
                  });
                }}
                prev={() => {
                  dispatchEvent(gtmEvents.onItemGaleryNavPrev, {
                    ...gtmEcommerceProductAdapter({
                      products: [productData],
                      category: 'showcasing',
                      theme,
                      stream,
                    }),
                  });
                }}
              >
                {images?.map((picture) => (
                  <img
                    key={picture.src || picture}
                    src={picture.src || picture}
                    alt={picture.alt || picture}
                    width={imgWidth}
                    height={imgHeight}
                    className={imageClasses.carouselImage}
                  />
                ))}
              </Carousel>
            </div>
          </Grid>
          <Grid item container xs={portrait ? 12 : 6} alignItems="flex-start">
            {renderDetails()}
          </Grid>
        </Grid>
      </Dialog>
    </Box>
  );
};

export default RealtimeImage;

RealtimeImage.defaultProps = {
  show: true,
  direction: 'row',
  productAvaiable: true,
};

RealtimeImage.propTypes = {
  images: PropTypes.arrayOf(
    PropTypes.oneOfType([
      PropTypes.shape({
        src: PropTypes.string,
        alt: PropTypes.string,
      }),
      PropTypes.string,
    ])
  ).isRequired,
  show: PropTypes.bool,
  direction: PropTypes.oneOf(['column', 'row']),
  productAvaiable: PropTypes.bool,
};
