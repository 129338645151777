import React from 'react';
import { Card, CardContent, CardHeader, Slide, useTheme } from '@material-ui/core';
import CloseIcon from '@material-ui/icons/Close';
import ChatIcon from '@material-ui/icons/Chat';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import PropTypes from 'prop-types';
import Styles from './styles';
import css from './styles.module.scss';

const SomaChatCard = (props) => {
  const { open, onClose, raised, children, title } = props;
  const theme = useTheme();

  const headerClasses = Styles.useHeaderStyle(theme);
  const headerClassesMobileLandscape = Styles.useHeaderStyleMobileLandscape(theme);
  const chatTitle = title || theme.chat.text;
  const isMobileLandscape = useMediaQuery('(max-width:926px) and (orientation: landscape)');

  const avatar = () =>
    typeof theme.chat.icon === 'string' ? <img src={theme.chat.icon} alt="chat-avatar" /> : <ChatIcon />;

  return (
    <Slide direction="up" in={open} mountOnEnter unmountOnExit>
      <Card raised={raised} className={css.cardWrapper}>
        <CardHeader
          action={<CloseIcon onClick={() => onClose(false)} />}
          avatar={avatar()}
          title={chatTitle}
          classes={isMobileLandscape ? headerClassesMobileLandscape : headerClasses}
          disableTypography
        />
        <CardContent className={css.cardContent}>{children}</CardContent>
      </Card>
    </Slide>
  );
};

SomaChatCard.defaultProps = {
  open: true,
  raised: false,
  title: 'Chat',
};

SomaChatCard.propTypes = {
  open: PropTypes.bool,
  onClose: PropTypes.func.isRequired,
  raised: PropTypes.bool,
  title: PropTypes.string,
};

export default SomaChatCard;
