import React from 'react';
import { Box } from '@material-ui/core';
import CreateIcon from '@material-ui/icons/Create';
import DoneIcon from '@material-ui/icons/Done';
import VideocamIcon from '@material-ui/icons/Videocam';
import EmojiObjectsIcon from '@material-ui/icons/EmojiObjects';
import PropTypes from 'prop-types';
import OperatorButton from '../../atoms/OperatorButton';
import Styles from './styles';

const createIcon = <CreateIcon color="primary" />;
const doneIcon = <DoneIcon color="primary" />;
const videocamIcon = <VideocamIcon color="primary" />;
const emojiObjectsIcon = <EmojiObjectsIcon color="primary" />;

const OperatorProduct = (props) => {
  const { clickEdit, clickStream, clickActiveLive, isEdit } = props;
  const boxClasses = Styles.useBoxStyle();

  return (
    <Box classes={boxClasses}>
      <OperatorButton onClick={clickEdit} icon={isEdit ? doneIcon : createIcon} />
      {isEdit && (
        <>
          <OperatorButton onClick={clickStream} icon={videocamIcon} />
          <OperatorButton onClick={clickActiveLive} icon={emojiObjectsIcon} />
        </>
      )}
    </Box>
  );
};

OperatorProduct.defaultProps = {
  isEdit: true,
};

OperatorProduct.propTypes = {
  clickEdit: PropTypes.func.isRequired,
  clickStream: PropTypes.func.isRequired,
  clickActiveLive: PropTypes.func.isRequired,
  isEdit: PropTypes.bool,
};

export default OperatorProduct;
