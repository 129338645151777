import { ptBR } from '@material-ui/core/locale';
import Color from 'color';
import config from '../config';

const welcomeBackgroundDesktop = `${config.assetsUrl}/default/bgDesktop.jpeg`;
const welcomeBackgroundMobile = `${config.assetsUrl}/default/bgMobile.jpg`;
const welcomeIcon = `${config.assetsUrl}/default/logo.png`;
const logoSoma = `${config.assetsUrl}/common/logoSoma.png`;
const logoSomaLabs = `${config.assetsUrl}/common/logoSomalabs.png`;
const pageNotFound = `${config.assetsUrl}/common/pagenotfound.png`;
const faqIcon = `${config.assetsUrl}/common/icons/help.png`;
const bagIcon = `${config.assetsUrl}/common/icons/bag.png`;
const productIcon = `${config.assetsUrl}/common/icons/products.svg`;
const chatIcon = `${config.assetsUrl}/common/icons/chat.png`;
const quizIcon = `${config.assetsUrl}/farm/icon/quizIcon.png`;
const couponImage = `${config.assetsUrl}/common/blank-coupon.png`;
const saleswomanCouponImage = `${config.assetsUrl}/common/blank-sales-coupon.png`;
const tapumeMiniplayer = `${config.assetsUrl}/common/miniplayer-tapume.jpg`;
const favicomDefault = `${config.assetsUrl}/common/favicons/faviconSoma.ico`;

const colorTransform = (hex, colorIntensity) => {
  const colorObject = Color.rgb(hex);
  const colorDark = colorObject.color.map((item) => item - colorIntensity);
  const colorLight = colorObject.color.map((item) => item + colorIntensity);

  return {
    dark: `rgba(${colorDark.join()}, ${colorObject.valpha})`,
    light: `rgba(${colorLight.join()}, ${colorObject.valpha})`,
    main: hex,
  };
};

const themeTemplate = ({
  mainColor,
  secondaryColor,
  fontFamily,
  secondaryFontFamily,
  badgeBgColor,
  badgeColor,
  buttonsBorderRadius,
  badgeRightPosition,
  badgeToptPosition,
}) => ({
  mainColor,
  secondaryColor,
  fontFamily,
  secondaryFontFamily,
  badgeBgColor,
  badgeColor,
  buttonsBorderRadius,
  badgeRightPosition,
  badgeToptPosition,
});

const main = {
  overrides: {
    MuiCssBaseline: {
      '@global': {
        html: {
          WebkitTextSizeAdjust: '100%',
        },
      },
    },
    MuiButton: {
      root: {
        textTransform: 'initial',
      },
    },
    MuiBadge: {
      badge: {
        backgroundColor: '#F44336',
        color: '#FFFFFF',
      },
    },
    MuiTypography: {
      caption: {
        fontSize: '0.625rem',
        color: themeTemplate.mainColor,
      },
    },
    MuiDialogTitle: {
      root: {
        padding: 0,
      },
    },
    MuiSnackbar: {
      anchorOriginTopRight: {
        marginRight: '60px',
      },
    },
  },
  gtm: { active: true },
  faq: {
    active: true,
    text: 'Ajuda',
    icon: {
      src: faqIcon,
      alt: 'Ajuda',
    },
    content: [
      {
        title: `Como vejo outras FAQs?`,
        description: `Selecione no addon acima (ícone lápis) a brand que deseja visualizar.`,
      },
    ],
  },
  survey: {
    openModalColor: themeTemplate.mainColor,
    buttonChoiceColor: themeTemplate.mainColor,
    buttonChoiceTextColor: '#fff',
    icon: {
      src: quizIcon,
      alt: 'Enquete',
    },
  },
  bag: {
    active: true,
    text: 'Sacola',
    emptyBag: 'Sua sacola está vazia',
    emptyBagColor: 'secondary',
    urlSharer: {
      color: themeTemplate.mainColor,
      textFirstStep: 'Compartilhar sacola',
      errorCopyText: 'Não foi possivel copiar o link do carrinho, tente novamente mais tarde',
      successCopyText: 'Link copiado com sucesso!',
      textSecondStep: 'Copiar link',
      background: 'default',
      buttonFontWeight: 'bold',
    },
    icon: {
      src: bagIcon,
      alt: 'Sacola',
    },
    continueShopping: {
      text: 'Continuar comprando',
      button: {
        color: 'primary',
      },
    },
  },
  bagSimulationExceptions: {
    itemQuantityNotAvailable: 'itemQuantityNotAvailable',
    itemNotAvailable: 'ORD027',
  },
  products: {
    active: true,
    text: 'Produtos',
    successBag: {
      text: 'Produto adicionado!',
    },
    successBagFromUrl: {
      text: 'Produtos adicionados!',
    },
    icon: {
      src: productIcon,
      alt: 'Produtos',
    },
    size: {
      text: 'Selecione o Tamanho:',
      typography: {
        variant: 'subtitle1',
        textTransform: 'uppercase',
        size: 14,
      },
    },
    details: {
      text: 'Detalhes do Produto',
      button: {
        variant: 'text',
        fullWidth: false,
      },
    },
    button: {
      text: 'Adicionar na sacola',
      variant: 'contained',
      color: 'secondary',
      hoverBackground: '#333',
    },
    composition: {
      text: 'Composição',
    },
    name: {
      color: 'primary',
    },
    price: {
      variant: 'body2',
      installment: {
        variant: 'caption',
      },
      sale: {
        color: 'secondary',
      },
      textTransform: 'uppercase',
    },
    quantity: {
      variant: 'subtitle1',
      size: 12,
    },
    notAvaiable: {
      text: 'Produto Indisponível',
    },
    search: {
      title: 'Pesquisar produtos',
      notFound: 'Produto não encontrado',
      emptyBrand: 'É necessário fornecer uma brand para pesquisar o produto',
    },
  },
  liveNotFound: {
    image: {
      src: pageNotFound,
      alt: 'Page 404',
    },
    title: 'Ops, algo deu errado...',
    subtitle: ' Por favor, verifique se o link está correto ou tente novamente em instantes.',
  },
  welcomePage: {
    paper: {
      backgroundImageDesktop: `url(${welcomeBackgroundDesktop})`,
      backgroundImageMobile: `url(${welcomeBackgroundMobile})`,
      backgroundPosition: 'center center',
    },
    container: {
      justify: null,
      header: {
        maxHeight: '30%',
        maxWidthMobile: '80%',
        maxWidthDesktop: '50%',
        paddingBottom: 0,
        paddingTop: 0,
        letterSpacing: 0,
        welcomeIcon: {
          src: welcomeIcon,
          alt: 'brand-welcome-background',
        },
      },
      content: {
        maxHeight: '50%',
        paddingTop: 0,
        paddingRight: 10,
        paddingLeft: 10,
        paddingBottom: 0,
        spacing: 0,
        boxContainerMaxWidth: 750,
        main: {
          text: 'Bem vindo à Somalive, este é um texto exemplo da página de Welcome com o tema default. Você pode ver um tema customizado selecionando uma brand no ícone de tema na toolbar.',
          textAlign: 'center',
          fontSize: 22,
          color: '#FFF',
          fontWeight: '400',
          margin: 0,
          letterSpacing: 0,
        },
        additional: {
          text: '',
          textAlign: 'center',
          fontSize: 16,
          color: '#FFF',
          fontWeight: '400',
          letterSpacing: 0,
        },
        footer: {
          text: new Date().toLocaleDateString(),
          textAlign: 'center',
          fontSize: 32,
          fontWeight: '700',
          color: '#FFF',
          letterSpacing: 0,
        },
      },
      callToAction: {
        mode: 'button',
        maxHeight: '20%',
        margin: 0,
        width: 'auto',
        button: {
          text: 'Assistir Live',
          color: 'secondary',
          fullWidth: false,
          variant: 'contained',
          custom: false,
          weight: 'normal',
        },
        input: {
          text: 'preencha seu e-mail para entrar',
          color: 'primary',
          fullWidth: false,
          variant: 'standard',
          custom: false,
          weight: 'normal',
          fontSize: '10px',
        },
      },
    },
  },
  checkout: {
    baseUrl: '',
    path: '',
    params: '',
    button: {
      text: 'Finalizar',
    },
    discountText: 'Desconto',
    totalText: 'Total',
    couponText: 'CUPOM DE 10% OFF',
    subtotalText: 'Subtotal',
    installmentNumber: 1,
    installmentMinValue: 0,
    couponImage,
    saleswomanCouponImage,
    cupomCodeText: 'adicione o cupom "<CUPOMCODEVALUE>" no checkout',
    cupomCodeValue: '',
  },
  chat: {
    active: true,
    text: 'Chat',
    icon: {
      src: chatIcon,
      alt: 'Chat',
    },
    mainBackgroundColor: themeTemplate.mainColor,
    headerTextColor: '#fff',
    headerText: 'Chat!',
    setNameText:
      "The chat is off-line. But don't worry, you can ask questions at hello@farmrio.com or chat with us at farmrio.com. See you there!",
    buttonTextTheme: 'primary',
    setUserNameButtonText: 'Enviar',
    colorTextChatPortrait: 'black',
    mainColor: '#000',
    headerBackgroundColor: themeTemplate.mainColor,
    tooltipEmailText: 'E-mail',
    tooltipMessageText: 'Mensagem',
    emptyMessageErrorText: 'Por favor insira uma mensagem!',
    messageSuccessText: 'Mensagem enviada com sucesso!',
    emptyNameErrorText: 'Por favor insira um nome!',
    productDetailText: 'Detalhes do Produto',
  },
  drawer: {
    header: {
      button: {
        size: 'small',
      },
    },
    padding: 10,
    width: '40%',
  },
  productSearch: {
    errorText: 'Ops, nenhum produto encontrado para essa pesquisa.',
  },
  SomaIconButton: {
    text: 'topo',
  },
  somaNavigation: {
    appBar: {
      position: 'static',
      elevation: 0,
      color: 'transparent',
      display: 'flex',
    },
    tab: {
      textColor: 'primary',
      variant: 'fullWidth',
      indicatorColor: 'primary',
      fontSize: 12,
      textTransform: 'inherit',
      fontWeight: 'bold',
    },
    quantity: {
      box: {
        style: {
          display: 'flex',
          alignItems: 'center',
        },
      },
      button: {
        style: {
          minWidth: 50,
          fontSize: 18,
          margin: 5,
          '&:hover': {
            backgroundColor: 'transparent',
          },
        },
      },
      variant: 'subtitle1',
    },
  },
  realtimeProduct: {
    styles: {
      padding: 10,
    },
    title: 'Incluir na sacola:',
    label: 'apresentando',
    titleVariant: 'subtitle2',
    rerun: {
      playOnVideoTitle: 'Ir para o momento da apresentação',
    },
    modalWidthDesktop: 150,
    modalWidthMobile: 111,
  },
  i18n: {
    locale: 'pt-BR',
    currency: 'BRL',
  },
  operator: {},
  login: {
    header: {
      logo: {
        url: logoSoma,
        alt: 'Grupo SOMA',
      },
      text: {
        variant: 'h5',
      },
    },
    form: {
      inputUser: {
        text: 'Usuário',
      },
      inputPassword: {
        text: 'Senha',
      },
      button: {
        text: 'Entrar',
      },
    },
    footer: {
      text: 'Criado e desenvolvido por SomaLabs',
      logo: {
        url: logoSomaLabs,
        alt: 'SomaLabs',
      },
    },
  },
  system: {
    messages: {
      email: {
        postError: 'Falha ao salvar o email, tente novamente em instantes.',
        timeout: 'Falha ao salvar o email, tente novamente em instantes.',
        failedToFetch: 'Falha ao salvar o e-mail, verifique sua conexão e tente novamente em instantes.',
      },
      embedLiveStream: 'não foi possivel executar esta ação, tente novamente mais tarde!',
      refreshProductFailed: 'Não foi possivel atualizar os produtos',
      offline: 'No momento estamos offline',
      lgpd: {
        button: {
          variant: 'contained',
          style: {
            backgroundColor: 'blue',
            color: '#FFFFFF',
            border: '1px solid transparent',
          },
        },
        box: { background: 'rgba(255,255,255, 0.8)' },
        text: {
          style: { color: '#FFFFFF', fontWeight: '400' },
          content: [
            {
              value: 'usamos',
              type: 'text',
            },
            {
              value: 'cookies',
              type: 'link',
              href: '/institucional/politicas#seguranca',
            },
            {
              value: 'no site para dar a você uma experiencia melhor de compra',
              type: 'text',
            },
            {
              buttonText: 'aceitar cookies',
              type: 'text',
            },
          ],
        },
      },
    },
  },
  miniPlayer: {
    URLParams: 'utm_live=miniplayer',
    tapumeImageURL: tapumeMiniplayer,
  },
  favicon: favicomDefault,
  title: 'Soma Live',
  snackbar: {
    iconStyles: {
      color: themeTemplate.mainColor,
      height: '50%',
    },
  },
};

export default { ...main, ...ptBR };

export { themeTemplate, colorTransform };
