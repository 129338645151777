import { makeStyles } from '@material-ui/core';

export const useButtonStyle = makeStyles({
  root: {
    position: 'fixed',
    left: '7px',
    top: '5px',
    '@media(orientation: portrait)': {
      width: '20%',
    },
  },
});

export const useDialogTitleStyle = makeStyles({
  root: {
    margin: 5,
    fontWeight: 'bold',
    textAlign: 'center',
  },
});

export const useDialogContentStyle = makeStyles({
  root: {
    width: '80%',
    height: '100%',
  },
});

export const useCloseIconButtonStyles = makeStyles((theme) => ({
  root: {
    padding: 0,
    fontSize: '1rem',
    color: theme.palette.primary.main,
    margin: '10px',
    '&:hover': {
      backgroundColor: 'transparent',
    },
  },
}));
