import { makeStyles } from '@material-ui/core';

const useTabStyle = makeStyles((theme) => ({
  root: {
    fontSize: theme.somaNavigation.tab.fontSize,
    textTransform: theme.somaNavigation.tab.textTransform,
    color: theme.palette.primary.main,
    height: '60px',
  },
  selected: {
    fontWeight: theme.somaNavigation.tab.fontWeight,
  },
  wrapper: {
    height: '100%',
    justifyContent: 'flex-end',
    '& img': {
      height: '32px',
      objectFit: 'contain',
      width: '32px',
    },
  },
}));

const useTabPanelStyle = makeStyles(() => ({
  root: {
    minHeight: 0,
    padding: 0,
    flex: 1,
  },
  container: { width: '100vw', height: 'auto' },
}));

const useTabPanelChatStyle = makeStyles(() => ({
  root: {
    minHeight: 0,
    padding: 0,
    flex: 1,
    height: '100%',
  },
}));

const useTabPanelBagStyle = makeStyles(() => ({
  root: {
    minHeight: 0,
    padding: 0,
    flex: 1,
    height: '100%',
  },
}));

const useAppBarStyle = makeStyles(() => ({
  root: {
    elevation: 0,
    backgroundColor: '#FFF',
    top: 0,
    zIndex: 999,
    maxHeight: 75,
    position: 'relative',
  },
}));

const useBadgeStyles = makeStyles(() => ({
  root: {
    position: 'static',
  },
  badge: {
    top: 0,
    right: 40,
    transform: 'scale(1) translate(100%, 10%)',
    transformOrigin: '100% 0%',
    padding: 0,
  },
}));

export default {
  useTabStyle,
  useAppBarStyle,
  useTabPanelStyle,
  useTabPanelChatStyle,
  useTabPanelBagStyle,
  useBadgeStyles,
};
