import React, { useEffect, useState, useContext } from 'react';
import { useTheme } from '@material-ui/core/styles';
import { Tabs, Tab, AppBar, Grid, Badge } from '@material-ui/core';
import { TabContext, TabPanel } from '@material-ui/lab';
import PropTypes from 'prop-types';
import Styles from './styles';
import { SomaChatForm, SomaChatMessageDisplay, SomaFAQ } from '../../molecules';
import SomaProductsList from '../ProductsList';
import Bag from '../Bag';
import { useEventDispatch, somaEvents, gtmEvents } from '../../../events';
import { StreamContext } from '../../../hooks/contexts';

import css from './styles.module.scss';

const SomaNavigation = (props) => {
  const {
    textColor,
    indicatorColor,
    variant,
    faqArgs,
    buttons,
    bagArgs,
    chatArgs,
    scrollTarget,
    liftedTabValue,
    liftStateKeyboardShown,
  } = props;

  const theme = useTheme();

  const tabsMap = new Map();
  tabsMap.set('products', { value: 'products', active: theme.products.active });
  tabsMap.set('chat', { value: 'chat', active: theme.chat.active });
  tabsMap.set('faq', { value: 'faq', active: theme.faq.active });
  tabsMap.set('bag', { value: 'bag', active: theme.bag.active });

  const initialTab = [...tabsMap.values()].find((tab) => tab.active);

  const [value, setValue] = useState(initialTab.value);
  const { bagBadgeCount = 0, chatBadgeCount = 0 } = useContext(StreamContext);

  const [tab, setTab] = useState(theme.somaNavigation.tab);

  const newBagArgs = { ...bagArgs, closeFunction: () => setValue('products') };

  useEffect(() => {
    if (textColor || indicatorColor || variant) {
      setTab({ textColor, indicatorColor, variant });
    }
  }, [textColor, indicatorColor, variant]);

  useEffect(() => {
    setTab(theme.somaNavigation.tab);
  }, [theme.somaNavigation.tab]);

  const tabClasses = Styles.useTabStyle();
  const tabPanelClasses = Styles.useTabPanelStyle();
  const tabPanelChatClasses = Styles.useTabPanelChatStyle();
  const tabPanelBagClasses = Styles.useTabPanelBagStyle();
  const badgeClasses = Styles.useBadgeStyles(theme);

  const dispatchEvent = useEventDispatch();

  const onHandleChange = (e, tabValue) => {
    setValue(tabValue);
    liftedTabValue(tabValue);
  };

  const appBarClasses = Styles.useAppBarStyle();

  const renderAppBar = () => {
    const BadgeBag = (
      <Badge badgeContent={bagBadgeCount} color="error" classes={badgeClasses}>
        {theme.bag.text}
      </Badge>
    );

    const BadgeChat = (
      <Badge badgeContent={chatBadgeCount} color="error" classes={badgeClasses}>
        {theme.chat.text}
      </Badge>
    );

    return (
      <AppBar classes={appBarClasses}>
        <Tabs
          value={value}
          onChange={onHandleChange}
          aria-label="tab navigation portrait"
          textColor={tab.textColor}
          variant={tab.variant}
          indicatorColor={tab.indicatorColor}
          centered
        >
          {theme.products.active && (
            <Tab
              label={theme.products.text}
              icon={<img src={theme.products.icon.src} alt={theme.products.icon.src} />}
              classes={tabClasses}
              value={tabsMap.get('products').value}
              onClick={() => {
                dispatchEvent(somaEvents.onProductBadgeClick);
                dispatchEvent(gtmEvents.onProductsShow);
              }}
            />
          )}
          {theme.chat.active && (
            <Tab
              label={BadgeChat}
              icon={<img src={theme.chat.icon.src} alt={theme.chat.icon.src} />}
              classes={tabClasses}
              value={tabsMap.get('chat').value}
              onClick={() => {
                dispatchEvent(somaEvents.onChatBadgeClick);
                dispatchEvent(gtmEvents.onChatShow);
              }}
            />
          )}
          {theme.faq.active && (
            <Tab
              label={theme.faq.text}
              icon={<img src={theme.faq.icon.src} alt={theme.faq.icon.src} />}
              classes={tabClasses}
              value={tabsMap.get('faq').value}
              onClick={() => {
                dispatchEvent(somaEvents.onFaqBadgeClick);
                dispatchEvent(gtmEvents.onFaqShow);
              }}
            />
          )}
          {theme.bag.active && (
            <Tab
              label={BadgeBag}
              icon={<img src={theme.bag.icon.src} alt={theme.bag.icon.src} />}
              classes={tabClasses}
              value={tabsMap.get('bag').value}
              onClick={() => {
                dispatchEvent(somaEvents.onBagBadgeClick);
                dispatchEvent(gtmEvents.onBagShow);
              }}
            />
          )}
        </Tabs>
      </AppBar>
    );
  };

  const renderChat = () => {
    const { chatMessages, user, email, sendMessage } = chatArgs;

    return (
      <Grid container direction="column" className={css.chatContainer}>
        {!!email && email !== '' && (
          <Grid item className={css.messagesWrapper}>
            <SomaChatMessageDisplay messages={chatMessages} showEmail={!!user} />
          </Grid>
        )}
        <Grid item className={css.formWrapper}>
          <SomaChatForm
            liftStateKeyboardShown={liftStateKeyboardShown}
            presetEmail={email}
            messageOnly={!!email}
            onSend={sendMessage}
          />
        </Grid>
      </Grid>
    );
  };

  return (
    <TabContext value={value}>
      <Grid container style={{ flexWrap: 'nowrap', height: '100%', position: 'relative' }} direction="column">
        <Grid item className={tabPanelClasses.container}>
          {renderAppBar()}
        </Grid>
        <Grid
          item
          style={{ position: 'relative', zIndex: 5, overflow: 'auto' }}
          ref={scrollTarget}
          onScroll={() => {
            dispatchEvent(gtmEvents.onCategoryScroll);
          }}
          xs={12}
          className={tabPanelClasses.root}
        >
          {theme.products && (
            <TabPanel value="products" className={tabPanelClasses.root}>
              {buttons && <SomaProductsList scrollTarget={scrollTarget} buttons={buttons} cols={2} />}
            </TabPanel>
          )}
          {theme.chat.active && (
            <TabPanel value="chat" className={tabPanelChatClasses.root}>
              {renderChat()}
            </TabPanel>
          )}
          {theme.faq.active && (
            <TabPanel value="faq" className={tabPanelClasses.root}>
              {faqArgs && <SomaFAQ {...faqArgs} />}
            </TabPanel>
          )}
          {theme.bag.active && (
            <TabPanel value="bag" className={tabPanelBagClasses.root}>
              {newBagArgs && <Bag {...newBagArgs} />}
            </TabPanel>
          )}
        </Grid>
      </Grid>
    </TabContext>
  );
};

SomaNavigation.defaultProps = {
  products: [],
  textColor: 'primary',
  indicatorColor: 'primary',
  variant: 'fullWidth',
};

SomaNavigation.propTypes = {
  products: PropTypes.arrayOf(PropTypes.object),
  textColor: PropTypes.oneOf(['primary', 'secondary', 'inherit']),
  indicatorColor: PropTypes.oneOf(['primary', 'secondary']),
  variant: PropTypes.oneOf(['fullWidth', 'scrollable', 'standard']),
};

export default SomaNavigation;
