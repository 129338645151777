import _ from 'lodash';
import { createMuiTheme } from '@material-ui/core/styles';
import defaultTheme from './somalive';
import animale from './animale';
import farm from './farm';
import farmUS from './farmus';
import farmatacado from './farmatacado';
import crisbarros from './crisbarros';
import crisbarrosvip from './crisbarrosVip';
import mariafilo from './mariafilo';
import offpremium from './offpremium';
import fabula from './fabula';
import foxton from './foxton';
import animalememorias from './animaleMemorias';
import animaleicarai2 from './animaleIcarai2';
import animaleriodesignbarra from './animaleRioDesignBarra';
import animaleipanema from './animaleIpanema';
import animalejk from './animaleJk';
import animaleiguatemisp from './animaleIguatemiSp';
import animaleiguatemicampinas from './animaleIguatemiCampinas';
import crisbarrosss22 from './crisbarrosSs22';
import mariafilopremiere from './mariafiloPremiere';
import bynv from './bynv';

export default (brand, streamName) => {
  const brandsTheme = {
    animale,
    farm,
    farmUS,
    crisbarros,
    crisbarrosvip,
    mariafilo,
    offpremium,
    farmatacado,
    fabula,
    foxton,
    animalememorias,
    animaleicarai2,
    animaleriodesignbarra,
    animaleipanema,
    animalejk,
    'animaleiguatemi-sp': animaleiguatemisp,
    'animaleiguatemi-campinas': animaleiguatemicampinas,
    crisbarrosss22,
    mariafilopremiere,
    bynv,
  };

  const brandTheme = _.merge(brandsTheme[brand], brandsTheme[brand + streamName] || {});
  return createMuiTheme(_.merge(defaultTheme, brandTheme));
};
