import { ptBR } from '@material-ui/core/locale';
import config from '../config';
import { themeTemplate, colorTransform } from './somalive';

const welcomeBackgroundDesktop = `${config.assetsUrl}/farm/live-1114repriseDesktop.jpg`;
const welcomeBackgroundMobile = `${config.assetsUrl}/farm/live-1114repriseMobile.jpg`;
const welcomeIcon = `${config.assetsUrl}/farm/logotipo-novo-farm.png`;
const faqIcon = `${config.assetsUrl}/farm/icon/helpIcon.svg`;
const bagIcon = `${config.assetsUrl}/farm/icon/bagIcon.svg`;
const productIcon = `${config.assetsUrl}/farm/icon/productsIcon.svg`;
const chatIcon = `${config.assetsUrl}/farm/icon/chatIcon.svg`;
const quizIcon = `${config.assetsUrl}/farm/icon/quizIcon.png`;
const couponImage = `${config.assetsUrl}/farm/live-1114cupomReprise.png`;
const saleswomanCouponImage = `${config.assetsUrl}/farm/live-1114salesWoman.png`;
const tapumeMiniplayer = `${config.assetsUrl}/farm/miniplayer-tapume.jpg`;
const faviconFarm = `${config.assetsUrl}/common/favicons/farm.png`;

const colorIntensity = 50;
const template = themeTemplate({
  mainColor: '#8D6742',
  secondaryColor: '#F44336',
  secondaryFontFamily: 'GalanoGrotesqueAlt',
  fontFamily: 'GalanoLight',
  badgeBgColor: '#F44336',
  badgeColor: 'white',
  buttonsBorderRadius: 0,
});

const main = {
  palette: {
    primary: colorTransform(template.mainColor, colorIntensity),
    secondary: colorTransform(template.secondaryColor, colorIntensity),
  },
  typography: {
    fontFamily: template.fontFamily,
  },
  overrides: {
    MuiTypography: {
      root: {
        fontFamily: template.fontFamily,
        color: template.mainColor,
        textTransform: 'lowercase',
      },
    },
    MuiButton: {
      root: {
        borderRadius: template.buttonsBorderRadius,
        textTransform: 'lowercase',
      },
    },
    MuiAlert: {
      root: {
        color: template.mainColor,
        textTransform: 'lowercase',
      },
      standardSuccess: {
        color: template.mainColor,
        backgroundColor: '#fff',
        '& $icon': {
          color: template.mainColor,
        },
      },
      standardInfo: {
        color: template.mainColor,
        backgroundColor: '#fff',
        '& $icon': {
          color: template.mainColor,
        },
      },
    },
  },
  faq: {
    text: 'ajuda',
    icon: {
      src: faqIcon,
      alt: 'ajuda',
    },
    content: [
      {
        title: 'o que é lojix?',
        description:
          'lojix é a nossa primeira loja virtual 100% interativa. uma experiência única com a curadoria especial do time farm pra você.',
      },
      {
        title: 'posso tirar minhas dúvidas?',
        description: 'sim! você pode interagir ou enviar uma mensagem ao vivo pelo ícone "chat lojix"',
      },
      {
        title: 'como vejo os produtos apresentados?',
        description:
          'clique no ícone "produtos" no canto esquerdo da tela e navegue pelas categorias pra melhor visualização.',
      },
      {
        title: 'posso comprar em tempo real?',
        description:
          'sim! escolha o produto, clique em "incluir na mochila" e em seguida selecione o seu tamanho. o produto será imediatamente adicionado à mochila, que está localizada no canto superior direito da tela.',
      },
      {
        title: 'como finalizo minha compra?',
        description:
          'clique no ícone "mochila" no canto superior direito da tela e depois em "fechar compra". a gente vai te direcionar pro nosso site pra efetuar o pagamento de forma rápida e segura.',
      },
    ],
  },
  survey: {
    openModalColor: template.mainColor,
    buttonChoiceColor: template.mainColor,
    buttonChoiceTextColor: '#fff',
    icon: {
      icon: quizIcon,
      alt: 'Enquete',
    },
  },
  bag: {
    text: 'mochila',
    emptyBag: 'sua mochila está vazia',
    emptyBagColor: 'secondary',
    urlSharer: {
      color: template.mainColor,
      textFirstStep: 'Compartilhar mochila',
      textSecondStep: 'Copiar link',
      background: 'default',
      buttonFontWeight: 'bold',
    },
    icon: {
      src: bagIcon,
      alt: 'sacola',
    },
  },
  products: {
    text: 'produtos',
    icon: {
      src: productIcon,
      alt: 'produtos',
    },
    size: {
      text: 'selecione o tamanho:',
      typography: {
        variant: 'subtitle1',
      },
    },
    details: {
      text: 'detalhes do produto',
      button: {
        variant: 'text',
        fullWidth: false,
      },
    },
    button: {
      text: 'incluir na mochila',
      variant: 'contained',
      color: 'primary',
      hoverBackground: '#553717',
    },
    composition: {
      text: 'composição',
    },
    name: {
      color: 'primary',
    },
    price: {
      sale: {
        color: 'primary',
      },
    },
    quantity: {
      variant: 'subtitle1',
    },
    notAvaiable: {
      text: 'produto indisponível',
    },
    search: {
      title: 'pesquisar produtos',
      notFound: 'produto não encontrado',
      emptyBrand: 'é necessário fornecer uma brand para pesquisar o produto',
    },
  },
  welcomePage: {
    paper: {
      backgroundImageDesktop: `url(${welcomeBackgroundDesktop})`,
      backgroundImageMobile: `url(${welcomeBackgroundMobile})`,
      backgroundPosition: 'center bottom',
    },
    container: {
      header: {
        maxWidthDesktop: '18%',
        maxWidthMobile: '58%',
        maxHeight: '35%',
        paddingTop: 20,
        welcomeIcon: {
          src: welcomeIcon,
          alt: 'farm-welcome-logo',
        },
      },
      content: {
        paddingTop: 20,
        paddingBottom: 20,
        paddingRight: 0,
        paddingLeft: 0,
        boxContainerMaxWidth: 650,
        main: {
          text: ['a reprise da live já tá disponível!', 'aproveita pra assistir quantas vezes quiser 🤍'],
          textAlign: 'center',
          fontSize: '1.75rem',
          fontWeight: '200',
          lineHeight: 1,
        },
        additional: {
          text: '',
          textAlign: 'center',
          fontSize: 24,
          fontWeight: '200',
          maxHeight: '',
          letterSpacing: 1,
        },
        footer: {
          text: '',
          fontSize: '1.75rem',
          fontWeight: '700',
          letterSpacing: 1,
        },
      },
      callToAction: {
        mode: 'button',
        width: 150,
        button: {
          text: 'vem assistir',
          textColor: '#000',
          color: '#FFF',
          variant: 'contained',
          maxHeight: '',
          custom: true,
          fullWidth: true,
        },
      },
    },
  },
  checkout: {
    baseUrl: 'https://www.farmrio.com.br',
    path: '/checkout/cart/add',
    button: {
      text: 'fechar compra',
    },
    discountText: 'outros descontos',
    totalText: 'Total',
    couponText: 'cupom de 15% off',
    subtotalText: 'subtotal',
    installmentNumber: 10,
    installmentMinValue: 10,
    couponImage,
    saleswomanCouponImage,
  },
  chat: {
    active: true,
    text: 'chat lojix',
    icon: {
      src: chatIcon,
      alt: 'chat',
    },
    mainBackgroundColor: template.mainColor,
    headerTextColor: '#FFF',
    headerText: 'chat!',
    setNameText: 'seja bem-vinda ao nosso atendimento! para começar digite o seu e-mail abaixo.',
    buttonTextTheme: 'primary',
    setUserNameButtonText: 'enviar',
    colorTextChatPortrait: '#64473F',
    mainColor: template.mainColor,
    headerBackgroundColor: template.mainColor,
    tooltipEmailText: 'e-mail',
    tooltipMessageText: 'mensagem',
    emptyMessageErrorText: 'por favor insira uma mensagem!',
    messageSuccessText: 'mensagem enviada com sucesso!',
    emptyNameErrorText: 'por favor insira um nome!',
  },
  drawer: {
    header: {
      button: {
        size: 'small',
      },
    },
    padding: 10,
    width: '40%',
  },
  somaNavigation: {
    appBar: {
      position: 'static',
      elevation: 0,
      color: 'transparent',
    },
    tab: {
      textColor: 'primary',
      variant: 'fullWidth',
      indicatorColor: 'primary',
      textTransform: 'lowercase',
    },
    quantity: {
      box: {
        style: {
          display: 'flex',
          alignItems: 'center',
        },
      },
      button: {
        style: {
          minWidth: 50,
          fontSize: 18,
          margin: 5,
          '&:hover': {
            backgroundColor: 'transparent',
          },
        },
      },
      variant: 'subtitle1',
    },
  },
  realtimeProduct: {
    styles: {
      padding: 10,
    },
    title: 'Apresentando:',
    titleVariant: 'subtitle2',
    rerun: {
      playOnVideoTitle: 'Ir para o momento da apresentação',
    },
  },
  i18n: {
    locale: 'pt-BR',
    currency: 'BRL',
  },
  miniPlayer: {
    typography: {
      fontFamily: template.secondaryFontFamily,
      titleFontSize: '0.875rem',
    },
    tapumeImageURL: tapumeMiniplayer,
  },

  operator: {},
  system: {
    messages: {
      lgpd: {
        button: {
          style: {
            backgroundColor: template.mainColor,
          },
        },
        text: {
          style: { color: template.mainColor, fontWeight: '700' },
          content: [
            {
              value: 'usamos ',
              type: 'text',
            },
            {
              value: 'cookies',
              type: 'link',
              href: 'https://www.farmrio.com.br/institucional/politicas#seguranca',
              title: 'Política de privacidade',
            },
            {
              value:
                ' no site pra dar a você uma melhor experiência de compra :) pra continuar navegando, é só clicar no botão: ',
              type: 'text',
            },
          ],
        },
      },
    },
  },
  favicon: faviconFarm,
  title: 'LOJIX: a live interativa da FARM',
  snackbar: {
    iconStyles: {
      color: themeTemplate.mainColor,
      height: '50%',
    },
  },
};

export default { ...main, ...ptBR };

export { themeTemplate, colorTransform };
