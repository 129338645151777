import { makeStyles } from '@material-ui/core';

const useHeaderStyle = makeStyles((theme) => ({
  root: {
    backgroundColor: theme.chat.headerBackgroundColor,
    color: theme.chat.headerTextColor,
    height: 60,
    flex: '0 0 60px',
    alignItems: 'center',
    padding: '16px',
  },
  content: {
    color: theme.mainColor,
  },
  avatar: {
    color: theme.chat.headerTextColor,
  },
  action: {
    marginRight: 0,
    marginTop: 0,
    cursor: 'pointer',
    stroke: theme.chat.headerTextColor,
    color: theme.chat.headerTextColor,
  },
}));

const useHeaderStyleMobileLandscape = makeStyles((theme) => ({
  root: {
    backgroundColor: theme.chat.headerBackgroundColor,
    color: theme.chat.headerTextColor,
    height: 45,
    flex: '0 0 45px',
    alignItems: 'flex-start',
    padding: '11px 16px',
  },
  action: {
    marginRight: 0,
    marginTop: 0,
  },
}));

export default {
  useHeaderStyle,
  useHeaderStyleMobileLandscape,
};
