import React from 'react';
import { Fab } from '@material-ui/core';
import Styles from './styles';

const OperatorButton = (props) => {
  const { onClick, icon } = props;

  const fabClasses = Styles.useFabStyle();

  return (
    <Fab onClick={onClick} classes={fabClasses} disableRipple>
      {icon}
    </Fab>
  );
};

export default OperatorButton;
