import { makeStyles } from '@material-ui/core';

const scrollButtonStyles = makeStyles((theme) => ({
  root: {
    position: 'fixed',
    backgroundColor: '#FFFFFF',
    color: theme.palette.primary.main,
    boxShadow: '0px 1px 7px 0px rgba(0, 0, 0, 0.5)',
    bottom: '2%',
    left: '32.5%',
    transform: 'scale(0.7)',
    '@media (max-width: 480px)': {
      left: '50%',
      transform: 'translatex(-50%) scale(0.66)',
    },
    '& .MuiIconButton-label': {
      flexDirection: 'column',
      fontSize: '16px',
      fontWeight: 'bold',
      '@media (max-width: 480px)': {
        fontSize: '14px',
        margin: '0px 3px 0px 3px',
      },
    },
    '&:hover': {
      backgroundColor: '#FFFFFF',
    },
  },
}));

const iconButtonStyles = makeStyles((theme) => ({
  root: {
    color: theme.palette.primary.main,
    transform: 'scale(2.3)',
    marginTop: '-6px',
  },
}));
export { scrollButtonStyles, iconButtonStyles };
