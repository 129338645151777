import React, { useContext, useEffect } from 'react';
import { useTheme } from '@material-ui/core/styles';
import { Grid, Typography } from '@material-ui/core';
import PropTypes from 'prop-types';
import { RealtimeImages } from '../../molecules';
import Styles from './styles';
import { StreamContext, LocalStorageContext } from '../../../hooks/contexts';
import { addToBag as addToBagHelper } from '../../../helpers/bagHelper';
import { useEventDispatch, gtmEvents, somaEvents } from '../../../events';
import { gtmEcommerceProductAdapter } from '../../../helpers/eventHelper';

const LiveProduct = (props) => {
  const { products = [], title } = props;
  const streamContext = useContext(StreamContext);
  const userStorage = useContext(LocalStorageContext);

  const theme = useTheme();
  const { titleVariant } = theme.realtimeProduct;

  const firstProduct = products[0];
  const secondProduct = products[1];

  const gridClasses = Styles.useGridStyle();
  const titleClasses = Styles.useTitleStyle();

  const dispatchEvent = useEventDispatch();

  const addToBag = (product, selectedItem) => {
    const { sendSnack, bagBadgeCount, setBagBadgeCount } = streamContext;

    addToBagHelper(product, selectedItem, userStorage);
    setBagBadgeCount(bagBadgeCount + 1);
    dispatchEvent(somaEvents.onBagAdd);
    sendSnack(theme.products.successBag.text, 'success');
  };

  const xsCols = secondProduct ? 6 : 12;

  useEffect(() => {
    if (products.length) {
      dispatchEvent(gtmEvents.onCategoryView, {
        ...gtmEcommerceProductAdapter({
          products,
          category: 'showcasing',
          theme,
          stream: streamContext,
        }),
      });
    }
  }, [products, dispatchEvent, theme, streamContext]);

  return (
    <Grid container classes={gridClasses}>
      <Typography variant={titleVariant} classes={titleClasses}>
        {title}
      </Typography>
      <Grid item container justify="center">
        {firstProduct && (
          <Grid item xs={xsCols}>
            <RealtimeImages
              show
              {...firstProduct}
              product={firstProduct}
              onSelectItem={(item) => {
                addToBag(firstProduct, item);

                dispatchEvent(gtmEvents.onBagAdd, {
                  ...gtmEcommerceProductAdapter({
                    products: [
                      {
                        ...firstProduct,
                        variant: item.size,
                        sku: item.sku,
                      },
                    ],
                    category: 'showcasing',
                    theme,
                    stream: streamContext,
                  }),
                });
              }}
            />
          </Grid>
        )}
        {secondProduct && (
          <Grid item xs={xsCols}>
            <RealtimeImages
              show
              {...secondProduct}
              product={secondProduct}
              onSelectItem={(item) => {
                addToBag(secondProduct, item);

                dispatchEvent(gtmEvents.onBagAdd, {
                  ...gtmEcommerceProductAdapter({
                    products: [
                      {
                        ...secondProduct,
                        seller: item.sellerId,
                        variant: item.size,
                        sku: item.sku,
                      },
                    ],
                    category: 'showcasing',
                    theme,
                    stream: streamContext,
                  }),
                });
              }}
            />
          </Grid>
        )}
      </Grid>
    </Grid>
  );
};

LiveProduct.defaultProps = {
  title: 'Incluir na Sacola:',
  showFirstProduct: true,
  showSecondProduct: true,
};

LiveProduct.propTypes = {
  title: PropTypes.string,
  showFirstProduct: PropTypes.bool,
  showSecondProduct: PropTypes.bool,
};
export default LiveProduct;
