import { makeStyles } from '@material-ui/core';

const useGridStyles = makeStyles(() => ({
  root: {
    flex: 1,
    // Promote the list into his own layer on Chrome. This cost memory but helps keeping high FPS.
    transform: 'translateZ(0)',
    margin: 0,
    justifyContent: 'space-around',
    padding: 0,
  },
}));

const useProductStyles = makeStyles({
  root: {
    margin: 0,
  },
  tile: {
    padding: 5,
  },
});

const useFormattedPricesClasses = makeStyles((theme) => ({
  root: {
    textTransform: theme.products.price.textTransform,
  },
}));

const useTabStyles = makeStyles((theme) => ({
  root: {
    color: theme.palette.primary.main,
  },
  selected: {
    fontWeight: 'bold',
  },
}));

export default {
  useGridStyles,
  useFormattedPricesClasses,
  useProductStyles,
  useTabStyles,
};
