import { makeStyles } from '@material-ui/core';

const useTypographyStyles = makeStyles(() => ({
  root: {
    alignText: 'center',
    width: 'inherit',
    wordBreak: 'break-all',
  },
}));

const useButtonStyles = makeStyles(() => ({
  root: {
    minWidth: 40,
    maxWidth: 50,
    padding: '7px 20px',
    margin: 4,
  },
}));

const useIconTextStyles = makeStyles(() => ({
  root: {
    marginTop: '6px',
    fontSize: '15px',
  },
}));

const useIconStyles = makeStyles({
  root: {
    padding: '5px',
    width: '32px',
  },
});

const useBadgeStyles = makeStyles((theme) => ({
  badge: {
    top: theme.badgeToptPosition,
    right: theme.badgeRightPosition,
    transformOrigin: '100% 0%',
    padding: 0,
  },
}));

export default {
  useTypographyStyles,
  useButtonStyles,
  useIconTextStyles,
  useIconStyles,
  useBadgeStyles,
};
