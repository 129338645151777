import React from 'react';
import { useTheme } from '@material-ui/core/styles';
import { Box, Typography } from '@material-ui/core';
import PropTypes from 'prop-types';
import Styles from './styles';

const SomaProductCounter = (props) => {
  const theme = useTheme();
  const { number, text = theme.products.text } = props;

  const boxClasses = Styles.useBoxStyle();

  return (
    <Box p={2} classes={boxClasses}>
      <Typography variant="subtitle1">{`${number} ${text}`}</Typography>
    </Box>
  );
};

SomaProductCounter.defaultProps = {
  number: 1,
};

SomaProductCounter.propTypes = {
  number: PropTypes.number,
};

export default SomaProductCounter;
