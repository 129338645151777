import { makeStyles } from '@material-ui/core';

const useCardStyle = makeStyles((theme) => ({
  root: {
    boxShadow: 'none',
    backgroundColor: 'transparent',
  },
  container: {
    padding: '5px 10px',
    '&:last-child': {
      paddingBottom: 10,
    },
  },
  title: {
    color: theme.palette.primary.main,
    fontSize: 14,
    fontWeight: 'bold',
  },
  text: {
    color: theme.palette.primary.main,
    fontSize: 14,
  },
}));

export default {
  useCardStyle,
};
