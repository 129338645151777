import axios from 'axios';

import config from '../config';

export default class SomaliveAPIClient {
  constructor(streamName) {
    this.client = axios.create({ baseURL: config.backendUrl });
    this.streamName = `/${streamName}`;
    this.stream = `/stream`;
    this.stream_timeline = `/products/timeline`;
    this.stream_videoURL = `/vimeo-liveurl/`;
    this.embed = `/embed/stream`;
  }

  getStream(fq) {
    const filterQueryString = fq ? `?fq=${fq}` : '';
    return this.client
      .get(`${this.stream}${this.streamName}${filterQueryString}`)
      .then((response) => {
        if (response.data.id_stream && response.data.video.isRerun) {
          return this.getTimeline(response.data.id_stream).then((timeline) => ({ ...response.data, timeline }));
        }
        return response.data;
      })
      .catch((e) => {
        throw new Error(e);
      });
  }

  getEmbedInfo() {
    return this.client
      .get(`${this.embed}${this.streamName}`)
      .then((response) => response.data)
      .catch((e) => {
        throw new Error(e);
      });
  }

  getTimeline(streamID) {
    return this.client
      .get(`${this.stream}/${streamID}${this.stream_timeline}`)
      .then((response) => response.data)
      .catch((e) => {
        throw new Error(e);
      });
  }

  getVimeoInfo(videoURL) {
    return this.client
      .get(`${this.stream}${this.stream_videoURL}/${videoURL}`)
      .then((response) => response.data)
      .catch((e) => {
        throw new Error(e);
      });
  }
}
