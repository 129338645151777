import { makeStyles } from '@material-ui/core';

const useBoxStyle = makeStyles(() => ({
  root: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',
    backgroundColor: 'rgba(255, 255, 255, 0.7)',
    maxWidth: 90,
    width: 90,
  },
}));

export default { useBoxStyle };
