import { makeStyles } from '@material-ui/core';

const useGridStyle = makeStyles(() => ({
  root: {
    margin: '0 10px 10px 20px',
  },
}));

const useNameStyle = makeStyles(() => ({
  root: {
    fontSize: 18,
  },
}));

const useNameBoxStyle = makeStyles(() => ({
  root: {
    fontWeight: '400',
    marginTop: 10,
    marginBottom: 5,
  },
}));

const usePriceStyle = makeStyles(() => ({
  root: {
    fontSize: 18,
    fontFamily: 'inherit',
  },
}));

const useBoldStyle = makeStyles(() => ({
  root: {
    fontWeight: '700',
  },
}));

const useFormattedPricesStyle = makeStyles((theme) => ({
  root: {
    textTransform: theme.products.price.textTransform,
    fontWeight: 'bold',
  },
}));

const useFormattedSalesPricesStyle = makeStyles((theme) => ({
  root: {
    textTransform: theme.products.price.textTransform,
    fontWeight: 'bold',
  },
}));

const usePriceTypography = makeStyles({
  root: {
    fontWeight: 'bold',
    textTransform: 'uppercase',
  },
});

const useFullTypography = makeStyles(() => ({
  root: {
    textDecoration: 'line-through',
    textTransform: 'uppercase',
    marginRight: 5,
  },
}));

const useBoxStyles = makeStyles(() => ({
  root: {
    display: 'flex',
    justifyContent: 'center',
  },
}));

const useCarouselStyles = makeStyles(() => ({
  container: {
    width: '100%',
    margin: 10,
  },
}));

const useImageStyle = makeStyles(() => ({
  productImage: {
    width: '100%',
    borderRadius: '5px',
  },
  carouselImage: {
    objectFit: 'contain',
    marginBottom: '-5px',
    borderRadius: 0,
  },
}));

const useNavButtonsStyles = {
  backgroundColor: 'transparent',
  color: '#494949',
  padding: 0,
  transform: 'scale(1.5)',
  margin: 0,
};

const useDetailsStyle = makeStyles((theme) => ({
  container: {
    marginTop: 10,
  },
  text: {
    color: theme.palette.primary.main,
    fontSize: 10,
    margin: 0,
  },
  root: {
    fontSize: 20,
  },
  title: {
    color: theme.palette.primary.main,
    fontSize: 12,
    margin: '5px 0',
  },
}));

const useCloseIconButtonStyle = makeStyles((theme) => ({
  root: {
    color: theme.palette.primary.main,
    '&:hover': {
      backgroundColor: 'transparent',
    },
  },
}));

export default {
  useNameStyle,
  useNameBoxStyle,
  usePriceStyle,
  useBoldStyle,
  useImageStyle,
  useGridStyle,
  useFormattedPricesStyle,
  useFormattedSalesPricesStyle,
  usePriceTypography,
  useFullTypography,
  useBoxStyles,
  useNavButtonsStyles,
  useCarouselStyles,
  useDetailsStyle,
  useCloseIconButtonStyle,
};
