import { makeStyles } from '@material-ui/core';

const useBoxStyle = makeStyles(() => ({
  root: {
    height: 35,
    padding: '0 10px',
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
  },
}));

const useTitleClasses = makeStyles({
  root: {
    fontSize: 12,
  },
});

const useTextClasses = makeStyles({
  root: {
    fontSize: 12,
  },
});

const useFormattedPricesClasses = makeStyles((theme) => ({
  root: {
    fontSize: 12,
    textTransform: theme.products.price.textTransform,
  },
}));

export default {
  useBoxStyle,
  useTitleClasses,
  useFormattedPricesClasses,
  useTextClasses,
};
