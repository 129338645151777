import React, { useEffect, useRef, useState } from 'react';
import { Button, Collapse, Grid, Tooltip, useMediaQuery, useTheme } from '@material-ui/core';
import { PlayArrow } from '@mui/icons-material';
import Styles from './styles';
import { formatTimeStamp } from '../../../services/timeline';
import { somaEvents, useEventDispatch } from '../../../events';

const PlayOnTimeline = ({ timestamps }) => {
  const isMobilePortrait = useMediaQuery('(max-width:480px)');
  const playButtonClasses = Styles.usePlayOnVideoButtonStyles(isMobilePortrait);
  const playByTimestampClasses = Styles.usePlayOnVideoByTimeButtonStyles(isMobilePortrait);
  const playByTimestampIconClasses = Styles.useButtonPlayByTimeIconStyles();
  const playOnVideoGridClasses = Styles.usePlayOnVideoGridStyles();
  const minutesCollapseRef = useRef();
  const openCollapseButtonRef = useRef();
  const dispatchEvent = useEventDispatch();

  const theme = useTheme();

  const [optionsOpen, setOptionsOpen] = useState(false);

  const onPlayButtonClick = () => {
    if (timestamps.length > 1) {
      setOptionsOpen(!optionsOpen);
      return;
    }
    dispatchEvent(somaEvents.onSeekToProductMoment, timestamps[0] / 1000);
  };

  const onPlayButtonByTimeClick = (timestamp) => {
    dispatchEvent(somaEvents.onSeekToProductMoment, timestamp / 1000);
  };

  const handleClickOutside = (e) => {
    if (
      minutesCollapseRef.current &&
      !minutesCollapseRef.current.contains(e.target) &&
      !openCollapseButtonRef.current.contains(e.target)
    ) {
      setOptionsOpen(false);
    }
  };

  useEffect(() => {
    document.addEventListener('mousedown', handleClickOutside, true);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside, true);
    };
  }, []);

  return (
    <Grid container direction="column" classes={playOnVideoGridClasses}>
      <Tooltip
        title={theme.realtimeProduct.rerun.playOnVideoTitle}
        disableTouchListener
        disableHoverListener={isMobilePortrait}
      >
        <Button classes={playButtonClasses} variant="contained" onClick={onPlayButtonClick} ref={openCollapseButtonRef}>
          <PlayArrow />
        </Button>
      </Tooltip>

      <Collapse in={optionsOpen} ref={minutesCollapseRef}>
        <Grid container direction="column">
          {timestamps.length > 1 &&
            timestamps
              .sort((a, b) => a - b)
              .map((timestamp) => (
                <Button
                  key={timestamp}
                  classes={playByTimestampClasses}
                  onClick={() => onPlayButtonByTimeClick(timestamp)}
                  endIcon={<PlayArrow classes={playByTimestampIconClasses} />}
                >
                  {formatTimeStamp(timestamp)}
                </Button>
              ))}
        </Grid>
      </Collapse>
    </Grid>
  );
};

export default PlayOnTimeline;
