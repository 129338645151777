import React from 'react';
import { Button } from '@material-ui/core';
import PropTypes from 'prop-types';
import { useButtonStyles } from './styles';

const SomaButton = (props) => {
  const {
    label,
    fullWidth,
    variant,
    color,
    onClick,
    textColor,
    fontWeight,
    fontSize,
    height,
    letterSpacing,
    endIcon,
    startIcon,
    rlPadding,
    noShadows,
  } = props;
  const buttonClasses = useButtonStyles({
    height,
    textColor,
    color,
    weight: fontWeight,
    size: fontSize,
    variant,
    letterSpacing,
    rlPadding,
    noShadows,
  });

  return (
    <Button
      onClick={onClick}
      fullWidth={fullWidth}
      variant={variant}
      color={textColor ? 'default' : color}
      classes={buttonClasses}
      fontWeight={fontWeight}
      fontSize={fontSize}
      endIcon={endIcon}
      startIcon={startIcon}
    >
      {label}
    </Button>
  );
};

SomaButton.defaultProps = {
  variant: 'contained',
  color: 'primary',
  label: 'Botão',
  fullWidth: true,
  fontWeight: 'normal',
  fontSize: 'inherit',
};

SomaButton.propTypes = {
  variant: PropTypes.oneOf(['contained', 'text', 'outlined']),
  color: PropTypes.string,
  label: PropTypes.string,
  fullWidth: PropTypes.bool,
  fontWeight: PropTypes.string,
  fontSize: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  onClick: PropTypes.func.isRequired,
};

export default SomaButton;
