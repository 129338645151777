import React, { useContext, useState, useEffect } from 'react';
import { useTheme, Typography } from '@material-ui/core';
import { formatCurrency } from '../../../helpers/currencyHelper';
import Styles from './styles';
import { StreamContext } from '../../../hooks/contexts';

const InstallmentText = ({ align, product, bagInstallments }) => {
  const theme = useTheme();
  const streamContext = useContext(StreamContext);
  const formattedPricesClasses = Styles.useFormattedPricesClasses();
  const [productInstallments, setProductInstallments] = useState();

  useEffect(() => {
    if (!product || !Array.isArray(streamContext.buttons)) return;

    const allProducts = streamContext.buttons
      .map((prod) => prod.products)
      .reduce((accumulator, currentProductsList) => accumulator.concat(currentProductsList), []);

    const productFinded = allProducts.find((prod) => prod.id === product.id);

    if (productFinded) {
      const installmentFinded = [...productFinded.items].find((item) => !!item.installments);

      if (installmentFinded) {
        setProductInstallments({
          installments: installmentFinded.installments.numberOfInstallments,
          value: installmentFinded.installments.value,
        });
      }
    }
  }, [product, streamContext.buttons]);

  const installmentsNumber = productInstallments || bagInstallments;
  const installmentFormattedPrice = formatCurrency(installmentsNumber?.value, theme.i18n);

  if (!installmentsNumber) return null;
  return (
    <Typography align={align} variant={theme.products.price.installment.variant}>
      {`ou em ${installmentsNumber.installments}x de `}
      <Typography classes={formattedPricesClasses} component="span">
        {installmentFormattedPrice}
      </Typography>
    </Typography>
  );
};

export default InstallmentText;
