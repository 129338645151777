import { makeStyles } from '@material-ui/core';

const useListStyle = makeStyles({
  root: {
    width: '100%',
    height: '100%',
    overflowY: 'scroll',
  },
});

const replyMessageHeaderStyle = makeStyles({
  primary: {
    fontWeight: 'bold',
    textTransform: 'uppercase',
  },
  secondary: {
    fontWeight: 'bold',
    marginLeft: '8px',
  },
  root: {
    display: 'flex',
  },
});

const replyMessageBodyStyle = makeStyles({
  primary: {
    fontWeight: 'bold',
  },
});

export default { useListStyle, replyMessageHeaderStyle, replyMessageBodyStyle };
