import React, { useRef, useState } from 'react';
import { Box, Container, Typography, Button, useTheme, IconButton } from '@material-ui/core';
import ReactPlayer from 'react-player';
import VolumeOffIcon from '@material-ui/icons/VolumeOff';
import VolumeUpIcon from '@material-ui/icons/VolumeUp';
import { isIOS, deviceType } from 'react-device-detect';
import { useEventDispatch, gtmEvents, newRelicEvents } from '../../../events';

import {
  useMainBoxStyles,
  usePlayerWrapperStyles,
  usePlayerStyles,
  useTitleBoxStyles,
  useTitleTypographyStyles,
  useCtaBoxStyles,
  useOfflineContainerStyles,
  useVideoControlsBoxStyles,
  useVideoControlsIconButtonStyles,
  useVideoControlsIconStyles,
} from './styles';

import { useStreamContext } from '../../../contexts/streamContext';

const EmbedLiveStream = ({ sendSnack }) => {
  const { streamContent } = useStreamContext();
  const { video, is_live: isLive, stream_name: streamName, brand } = streamContent;
  const player = useRef();
  const theme = useTheme();
  const { URLParams, tapumeImageURL } = theme.miniPlayer;
  const mainBoxClasses = useMainBoxStyles();
  const playerWrapperClasses = usePlayerWrapperStyles();
  const playerClasses = usePlayerStyles();
  const titleBoxClasses = useTitleBoxStyles();
  const titleTypographyClasses = useTitleTypographyStyles(theme);
  const ctaBoxClasses = useCtaBoxStyles();
  const offlineContainerClasses = useOfflineContainerStyles();
  const videoControlsBoxClasses = useVideoControlsBoxStyles();
  const videoControlsIconButtonClasses = useVideoControlsIconButtonStyles();
  const videoControlsIconClasses = useVideoControlsIconStyles();

  const isLiveStream = isLive && video.isRerun === 0;

  const [muted, setMuted] = useState(true);

  const dispatchEvent = useEventDispatch();

  const redirectUrl = `${brand.frontend_url}/${streamName}?${URLParams}`;

  const reactPlayerConfig = isIOS && deviceType === 'mobile' ? {} : { file: { forceHLS: true } };

  const renderVideo = () => (
    <Box classes={mainBoxClasses}>
      <Box classes={playerWrapperClasses}>
        <ReactPlayer
          ref={player}
          url={video.url}
          width="100%"
          height="100%"
          className={playerClasses.root}
          playing
          playsinline
          volume={1}
          muted={muted}
          config={reactPlayerConfig}
        />
        <Box classes={titleBoxClasses}>
          <Typography variant="h6" classes={titleTypographyClasses}>
            {video.title}
          </Typography>
          <Typography variant="subtitle2" classes={titleTypographyClasses}>
            ao vivo
          </Typography>
        </Box>

        <Button
          classes={ctaBoxClasses}
          onClick={() => {
            setMuted(true);
            dispatchEvent(gtmEvents.onClickMiniPlayer, {
              redirectUrl,
            });
            try {
              window.parent.postMessage(
                {
                  redirectUrl,
                  type: 'CTARedirectionRequest',
                },
                brand.ecommerce_url
              );
            } catch (error) {
              sendSnack(theme.system.messages.embedLiveStream, 'error');
              dispatchEvent(newRelicEvents.log, {
                type: 'Error',
                message: error.message,
                path: 'EmbedLiveStream/index.js -> useEffect[0]',
              });
            }
          }}
        />
        <Box classes={videoControlsBoxClasses}>
          <IconButton onClick={() => setMuted(!muted)} classes={videoControlsIconButtonClasses}>
            {muted && <VolumeOffIcon classes={videoControlsIconClasses} />}
            {!muted && <VolumeUpIcon classes={videoControlsIconClasses} />}
          </IconButton>
        </Box>
      </Box>
    </Box>
  );

  const renderOffline = () => (
    <Container fixed classes={offlineContainerClasses}>
      <img src={tapumeImageURL} alt="" />
    </Container>
  );

  return isLiveStream ? renderVideo() : renderOffline();
};

export default EmbedLiveStream;
