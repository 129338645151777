import React, { useState, useEffect } from 'react';
import { IconButton, TextField, useTheme, InputAdornment } from '@material-ui/core';
import { get, debounce } from 'lodash';
import SearchIcon from '@material-ui/icons/Search';
import CloseIcon from '@material-ui/icons/Close';
import PropTypes from 'prop-types';
import {
  useSearchFieldStyles,
  useIconStyles,
  useFormHelperTextStyles,
  useInputStyles,
  closeIconStyle,
  iconButtonStyles,
} from './styles';

const ProductSearch = (props) => {
  const { sendSearch, allProducts, categoryProducts, debounceTime } = props;
  const theme = useTheme();

  const searchTitle = get(theme, 'products.search.title', 'Pesquisar produtos');

  const [helperText, setHelperText] = useState();
  const [textValue, setTextValue] = useState('');

  const searchFieldClasses = useSearchFieldStyles();
  const searchFieldIconClasses = useIconStyles();
  const formHelperTextClasses = useFormHelperTextStyles(theme);
  const innerInputClasses = useInputStyles();
  const closeIconClasses = closeIconStyle();
  const inputButtonClasses = iconButtonStyles();

  const filterProducts = debounce((searchedProduct) => {
    if (searchedProduct) {
      const uniqueProducts = new Map();
      const searchRegex = new RegExp(searchedProduct, 'i');

      allProducts.forEach((product) => {
        const { productId, reference } = product.provider;
        const { productName } = product;

        if (searchRegex.test(productName) || searchRegex.test(reference)) {
          uniqueProducts.set(productId, product);
        }
      });

      sendSearch([...uniqueProducts.values()]);

      const updateHelperText = uniqueProducts.size === 0 ? theme.productSearch.errorText : '';

      setHelperText(updateHelperText);
    } else {
      sendSearch(categoryProducts);
      setHelperText(null);
    }
  }, debounceTime);

  useEffect(() => {
    filterProducts(textValue);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [textValue, allProducts]);

  const textFieldHandleChange = (e) => {
    const searchedProduct = e.target.value;
    setTextValue(searchedProduct);
  };

  const clearSearchText = () => {
    setTextValue('');
    sendSearch(categoryProducts);
    setHelperText(null);
  };

  const hideKeyboardOnSearch = (event) => {
    if (event.key === 'Enter') {
      event.preventDefault();
      event.target.blur();
    }
  };

  return (
    <>
      <TextField
        onKeyPress={hideKeyboardOnSearch}
        placeholder={searchTitle}
        variant="outlined"
        size="small"
        fullWidth
        type="search"
        helperText={helperText}
        FormHelperTextProps={{
          classes: formHelperTextClasses,
        }}
        value={textValue}
        onChange={textFieldHandleChange}
        classes={searchFieldClasses}
        InputProps={{
          classes: innerInputClasses,
          startAdornment: (
            <InputAdornment position="start">
              <SearchIcon classes={searchFieldIconClasses} />
            </InputAdornment>
          ),
          endAdornment: textValue && (
            <InputAdornment position="end">
              <IconButton onClick={clearSearchText} classes={inputButtonClasses}>
                <CloseIcon classes={closeIconClasses} />
              </IconButton>
            </InputAdornment>
          ),
        }}
      />
    </>
  );
};

ProductSearch.defaultProps = {
  debounceTime: 400,
};

ProductSearch.propTypes = {
  debounceTime: PropTypes.number,
};

export default ProductSearch;
