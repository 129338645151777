const fetchWithTimeout = async (resource, options = { requestTimeout: 8000 }) => {
  const controller = new AbortController();
  const id = setTimeout(() => controller.abort(), options.requestTimeout);

  const response = await fetch(resource, {
    signal: controller.signal,
    ...options,
  });
  clearTimeout(id);

  return response;
};

export { fetchWithTimeout };
