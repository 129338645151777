import { makeStyles } from '@material-ui/core';

const usePlayOnVideoButtonStyles = (isMobile) =>
  makeStyles((theme) => {
    const background = isMobile ? `${theme.palette.primary.main}FF` : theme.products.button.hoverBackground;
    return {
      root: {
        backgroundColor: `${theme.palette.primary.main}CC`,
        minWidth: '40px',
        maxWidth: '40px',
        height: '40px',
        borderRadius: '50%',
        marginBottom: '6px',
        color: 'white',
        zIndex: 2,
        '&:hover': {
          background,
        },
      },
    };
  })();

const usePlayOnVideoByTimeButtonStyles = (isMobile) =>
  makeStyles((theme) => {
    const background = isMobile ? `${theme.palette.primary.main}FF` : theme.products.button.hoverBackground;
    return {
      endIcon: {
        width: '20%',
      },
      label: {
        fontSize: '11px',
      },
      root: {
        backgroundColor: `${theme.palette.primary.main}CC`,
        maxWidth: 65,
        minWidth: 65,
        height: '20px',
        borderRadius: '30px',
        margin: 2,
        color: 'white',
        padding: '2px',
        zIndex: 2,
        textAlign: 'center',
        '&:hover': {
          background,
        },
      },
    };
  })();

const useButtonPlayByTimeIconStyles = makeStyles(() => ({
  root: {
    fontSize: '10px !important',
  },
}));

const usePlayOnVideoGridStyles = makeStyles(() => ({
  root: {
    position: 'absolute',
    top: 15,
    left: 27,
    maxWidth: 20,
    height: 'auto',
  },
}));

export default {
  usePlayOnVideoButtonStyles,
  usePlayOnVideoByTimeButtonStyles,
  usePlayOnVideoGridStyles,
  useButtonPlayByTimeIconStyles,
};
