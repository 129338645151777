import { makeStyles } from '@material-ui/core';

const useButtonStyles = ({ textColor, color, weight, height, size, variant, letterSpacing, rlPadding, noShadows }) =>
  makeStyles({
    root: {
      color: textColor,
      background: variant === 'outlined' ? 'transparent' : color,
      borderColor: color,
      height,
      letterSpacing,
      paddingLeft: rlPadding,
      paddingRight: rlPadding,
      boxShadow: noShadows ? 'none' : '',
    },
    label: {
      fontWeight: weight,
      fontSize: size,
    },
  })();

export { useButtonStyles };
