import { makeStyles } from '@material-ui/core';

const useButtonGroupStyle = makeStyles(() => ({
  root: {
    backgroundColor: 'white',
    padding: 10,
  },
}));

export default {
  useButtonGroupStyle,
};
