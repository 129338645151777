import React from 'react';
import { useTheme } from '@material-ui/core/styles';
import { Container, Typography, Button, Grid } from '@material-ui/core';
import PropTypes from 'prop-types';

import Styles from './styles';

const SelectSize = (props) => {
  const { direction, items, onSelect } = props;
  const config = {
    fullWidth: direction === 'column',
    display: direction === 'column' ? 'block' : 'flex',
    variant: direction === 'column' ? 'text' : 'outlined',
    textAlign: direction === 'column' ? 'center' : 'left',
  };
  const theme = useTheme();
  const { products } = theme;
  const containerClasses = Styles.useContainerStyle(config);
  const gridClasses = Styles.useGridStyle(config);
  const buttonClasses = Styles.useButtonStyles();

  return (
    <Container classes={containerClasses} disableGutters={config.fullWidth}>
      <Typography variant={products.size.typography.variant} align={config.textAlign} m={2}>
        {products.size.text}
      </Typography>
      {Array.isArray(items) && (
        <Grid classes={gridClasses}>
          {items.map((item) => (
            <Grid item key={item.size}>
              <Button
                key={item.size}
                fullWidth={config.fullWidth}
                onClick={() => {
                  onSelect(item);
                }}
                variant={config.variant}
                disabled={!item.availableQuantity}
                size="large"
                classes={buttonClasses}
                color="primary"
              >
                {item.size}
              </Button>
            </Grid>
          ))}
        </Grid>
      )}
    </Container>
  );
};

SelectSize.defaultProps = {
  direction: 'row',
};

SelectSize.propTypes = {
  items: PropTypes.arrayOf(
    PropTypes.shape({
      sku: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
      size: PropTypes.string,
      availableQuantity: PropTypes.number,
    })
  ).isRequired,
  direction: PropTypes.oneOf(['column', 'row']),
  onSelect: PropTypes.func.isRequired,
};

export default SelectSize;
