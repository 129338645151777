import { makeStyles } from '@material-ui/core';

const useTextFieldStyles = makeStyles(() => ({
  root: {
    borderRadius: 0,
    backgroundColor: '#fff',
    padding: 10,
  },
}));

const useButtonStyles = (theme) =>
  makeStyles({
    root: {
      backgroundColor: theme.mainColor,
      borderRadius: 0,
      transition: '0.5s',
      '&:hover': {
        opacity: '0.7',
      },
    },
  })();

export default { useTextFieldStyles, useButtonStyles };
