import slugify from 'slugify';

export const slugfy = (string) => {
  const config = {
    lower: true,
    replacement: '_',
    strict: true,
  };
  return slugify(string, config);
};

export const gtmEcommerceProductAdapter = ({ products = [], category, theme, stream }) => ({
  item_list_name: slugfy(category),
  ecommerce: {
    currency: theme.i18n.currency,
    items: products.map((product, index) => ({
      item_list_name: slugfy(category),
      item_list_id: product.id_button,
      item_name: product.productName,
      item_id: product.id,
      price: product.price,
      item_brand: stream.brand.name,
      item_variant: product.variant || null,
      sku: product.sku || null,
      index,
      quantity: product.quantity || 1,
    })),
  },
});
