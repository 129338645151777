import { makeStyles } from '@material-ui/core';

const useFormattedPricesClasses = makeStyles((theme) => ({
  root: {
    textTransform: theme.products.price.textTransform,
    fontSize: 'inherit',
  },
}));

export default { useFormattedPricesClasses };
