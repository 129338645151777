import React from 'react';
import { Typography, Box } from '@material-ui/core';
import PropTypes from 'prop-types';
import Styles from './styles';

const InfoCheckout = (props) => {
  const { colorText, variant, textValue, priceValue, couponValue, borderColor, contentAlign } = props;

  const boxClasses = Styles.useBoxStyle();
  const titleClasses = Styles.useTitleClasses();
  const textClasses = Styles.useTextClasses();
  const formattedPricesClasses = Styles.useFormattedPricesClasses();

  const borderConfig = (color) => ({
    border: 1,
    borderBottom: 0,
    borderLeft: 0,
    borderRight: 0,
    borderColor: color,
  });

  return (
    <Box classes={boxClasses} {...borderConfig(borderColor)} justifyContent={contentAlign}>
      {couponValue ? (
        <Typography variant={variant} color={colorText} classes={textClasses}>
          {couponValue}
        </Typography>
      ) : (
        <>
          <Typography component="div" variant={variant} color={colorText} classes={titleClasses}>
            <Box fontWeight="fontWeightBold">{textValue}</Box>
          </Typography>
          <Typography variant={variant} color={colorText} classes={formattedPricesClasses}>
            {priceValue}
          </Typography>
        </>
      )}
    </Box>
  );
};

InfoCheckout.defaultProps = {
  borderColor: 'primary.light',
  variant: 'body1',
  colorText: 'primary',
  couponValue: null,
  contentAlign: 'space-between',
  textValue: '0,00',
  priceValue: '0,00',
};

InfoCheckout.propTypes = {
  textValue: PropTypes.string,
  priceValue: PropTypes.string,
  couponValue: PropTypes.string,
  borderColor: PropTypes.oneOf(['default', 'inherit', 'primary.main', 'primary.light']),
  colorText: PropTypes.oneOf(['initial', 'inherit', 'primary', 'secondary']),
  variant: PropTypes.oneOf(['body1', 'body2']),
  contentAlign: PropTypes.oneOf(['space-between', 'center']),
};

export default InfoCheckout;
