import React from 'react';
import { Box, Button, Typography, useTheme } from '@material-ui/core';
import PropTypes from 'prop-types';
import Styles from './styles';

const CountProduct = (props) => {
  const theme = useTheme();
  const { number, onClickPlus, onClickMinus } = props;
  const buttonClasses = Styles.useButtonStyle();
  const boxClasses = Styles.useBoxStyle();
  const textClasses = Styles.useTextStyle(theme);

  return (
    <Box classes={boxClasses}>
      <Button classes={buttonClasses} disableRipple onClick={onClickMinus}>
        -
      </Button>
      <Typography classes={textClasses}>{number}</Typography>
      <Button classes={buttonClasses} disableRipple onClick={onClickPlus}>
        +
      </Button>
    </Box>
  );
};

CountProduct.defaultProps = {
  number: 1,
};

CountProduct.propTypes = {
  number: PropTypes.number,
  onClickPlus: PropTypes.func.isRequired,
  onClickMinus: PropTypes.func.isRequired,
};

export default CountProduct;
