export default {
  onEnter: 'enter',

  onBagAdd: 'addToCart',
  onBagRemove: 'removeFromCart',
  onBagUpdate: 'cartItemUpdate',
  onBagBadgeClick: '',
  onBagCheckout: 'checkout',
  onBagScroll: 'on_bag_scroll',
  onBagItemIncrease: 'cartItemIncrease',
  onBagItemDecrease: 'cartItemDecrease',

  onChatBadgeClick: '',
  onChatFirstMessageSent: 'contact',

  onFaqBadgeClick: '',

  onSeekToProductMoment: 'seekToSelectedProduct',

  onProductBadgeClick: 'seeProducts',
  onProductShowcasingClick: 'featuredProducts',
  onProductClick: 'seeImages',
  onProductDetailClick: 'seeDetails',
  onProductCategoriesClick: 'seeCategories',

  onVideoStart: 'startedWatching',
  onVideoPause: '',
  onVideoElapsedTime: 'timeWatching',

  onRefreshProducts: 'refresh_products',
};
