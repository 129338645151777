import { makeStyles } from '@material-ui/core';

const useBoxStyle = makeStyles(() => ({
  root: {
    display: 'flex',
    flexDirection: 'column',
  },
}));

export default { useBoxStyle };
