import { makeStyles } from '@material-ui/core';

const aspectRatio = '56.25%'; // 16:9

const usePlayerWrapper = makeStyles({
  root: {
    position: 'absolute',
    bottom: 0,
    paddingTop: aspectRatio,
    width: '100%',
  },
});

export { usePlayerWrapper };
