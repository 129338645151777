import React, { useState, useContext, createContext } from 'react';

const SocketContext = createContext();

export default function SocketContextProvider({ children }) {
  const socketState = useState();
  const surveyState = useState();
  const messageState = useState();

  return <SocketContext.Provider value={{ socketState, surveyState, messageState }}>{children}</SocketContext.Provider>;
}

export const useSocketContext = () => {
  const { socketState, surveyState, messageState } = useContext(SocketContext);
  const [receivedSurvey, setReceivedSurvey] = surveyState;
  const [receivedMessage, setReceivedMessage] = messageState;
  const [socket, setSocket] = socketState;

  return {
    socket,
    receivedSurvey,
    receivedMessage,
    setSocket,
    setReceivedSurvey,
    setReceivedMessage,
  };
};
