import { makeStyles } from '@material-ui/core';

const useGridStyle = makeStyles({
  root: {
    padding: 10,
  },
});

const useTitleStyle = makeStyles({
  root: {
    fontWeight: '400',
  },
});

export default {
  useGridStyle,
  useTitleStyle,
};
