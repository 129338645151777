import React, { useEffect, useState } from 'react';
import { Button, Grid, Typography, useTheme, Box, TextField, IconButton, InputAdornment } from '@material-ui/core';
import SendIcon from '@material-ui/icons/Send';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import PropTypes from 'prop-types';
import Styles from './styles';
import { useEventDispatch, somaEvents, gtmEvents } from '../../../../events';
import { emailRegexTest } from '../../../../helpers/emailHelper';

const SomaChatForm = (props) => {
  const { presetEmail, messageOnly, onSend, liftStateKeyboardShown } = props;

  const [email, setEmail] = useState('');
  const [justify, setJustify] = useState('space-between');
  const [message, setMessage] = useState('');
  const [emailError, setEmailError] = useState();
  const [emailValid, setEmailValid] = useState(false);
  const [messageError, setMessageError] = useState();
  const [firstMessageSent, setFirstMessageSent] = useState(false);
  const theme = useTheme();
  const dispatchEvent = useEventDispatch();
  const buttonClasses = Styles.useButtonStyles(theme.chat);
  const portrait = useMediaQuery('(orientation: portrait)');
  const isMobileLandscape = useMediaQuery('(max-width:926px) and (orientation: landscape)');

  useEffect(() => {
    setJustify(messageOnly ? 'flex-end' : 'flex-start');
    if (presetEmail) {
      setEmail(presetEmail);
      setEmailError(false);
    }
  }, [messageOnly, presetEmail]);

  const sendMessage = () => {
    if (emailError || emailError === undefined) {
      setEmailError(true);
    }
    if (!message) {
      setMessageError(true);
    }
    if (emailError === false && message !== '' && message !== undefined) {
      const formatedMessage = {
        text: message,
        email,
      };
      onSend(formatedMessage);
      setMessageError(false);
      setMessage('');
      liftStateKeyboardShown(false);
      if (!firstMessageSent) {
        setFirstMessageSent(true);
        dispatchEvent(somaEvents.onChatFirstMessageSent);

        if (!messageOnly) {
          dispatchEvent(gtmEvents.onChatSignUp);
        }
      }
      dispatchEvent(gtmEvents.onChatMessageSent);
    }
  };

  const getMessageValue = (e) => {
    e.preventDefault();
    setMessage(e.target.value);
    setMessageError(false);
  };

  const setEmailValue = () => {
    const valid = emailRegexTest(String(email).toLowerCase());
    setEmailError(!valid);
    setEmailValid(valid);
  };

  return (
    <Grid item container style={{ height: '100%' }} direction="column" justify={justify}>
      <Box style={{ height: '100%', padding: portrait ? '16px' : '10px 0' }}>
        {((!messageOnly && !isMobileLandscape) ||
          (isMobileLandscape && !messageOnly && (email.length === 0 || !emailValid))) && (
          <>
            <Grid item>
              <Typography variant="subtitle2" style={{ marginBottom: '10px' }}>
                {theme.chat.setNameText}
              </Typography>
            </Grid>
            <Grid item>
              <TextField
                required
                label={theme.chat.tooltipEmailText}
                aria-label="inserir email"
                onFocus={() => {
                  liftStateKeyboardShown(true);
                }}
                value={email}
                error={emailError}
                onChange={(e) => {
                  e.preventDefault();
                  setEmail(e.target.value);
                }}
                onBlur={() => {
                  setEmailValue();
                  liftStateKeyboardShown(false);
                }}
                onKeyPress={(e) => {
                  if (e.key === 'Enter') setEmailValue();
                }}
                variant="outlined"
                fullWidth
                size={isMobileLandscape ? 'small' : 'medium'}
              />
            </Grid>
          </>
        )}
        {(!isMobileLandscape || messageOnly || (isMobileLandscape && emailValid)) && (
          <>
            <Grid
              item
              style={{
                marginTop: messageOnly ? 0 : 10,
                marginBottom: messageOnly ? 0 : 10,
              }}
            >
              <TextField
                required
                label={theme.chat.tooltipMessageText}
                aria-label="inserir mensagem"
                onFocus={() => {
                  liftStateKeyboardShown(true);
                }}
                value={message}
                error={messageError}
                onChange={getMessageValue}
                variant="outlined"
                fullWidth
                size={isMobileLandscape ? 'small' : 'medium'}
                InputProps={
                  messageOnly
                    ? {
                        endAdornment: (
                          <InputAdornment disablePointerEvents={false}>
                            <IconButton onClick={sendMessage} color="primary">
                              <SendIcon />
                            </IconButton>
                          </InputAdornment>
                        ),
                      }
                    : null
                }
                onKeyUp={({ key }) => {
                  if (String(key).toLowerCase() === 'enter') {
                    sendMessage();
                    liftStateKeyboardShown(false);
                  }
                }}
              />
            </Grid>
            {!messageOnly && (
              <Grid item>
                <Button fullWidth variant="contained" color="primary" classes={buttonClasses} onClick={sendMessage}>
                  {theme.chat.setUserNameButtonText}
                </Button>
              </Grid>
            )}
          </>
        )}
      </Box>
    </Grid>
  );
};

SomaChatForm.defaultProps = {
  presetEmail: '',
  messageOnly: false,
};

SomaChatForm.propTypes = {
  presetEmail: PropTypes.string,
  messageOnly: PropTypes.bool,
  onSend: PropTypes.func.isRequired,
};

export default SomaChatForm;
