import { makeStyles } from '@material-ui/core';

const useButtonStyle = makeStyles(() => ({
  root: {
    borderRadius: 0,
    height: 50,
  },
}));

export default { useButtonStyle };
