import React, { useState, useContext, createContext, useCallback } from 'react';
import { getAllProductsTimestamps } from '../services/timeline';

const RealTimeProductsContext = createContext();

export default function RealTimeProductsContextProvider({ children }) {
  const state = useState({
    timeline: null,
    showCasing: [],
    allProductsWithIds: new Map(),
  });
  return <RealTimeProductsContext.Provider value={state}>{children}</RealTimeProductsContext.Provider>;
}

export const useRealTimeProductsContext = () => {
  const [realTimeProductsState, setRealTimeProductsState] = useContext(RealTimeProductsContext);

  const setTimelineLoading = useCallback(
    (value) => {
      if (typeof value !== 'boolean') return;

      setRealTimeProductsState((previousState) => ({ ...previousState, loading: value }));
    },
    [setRealTimeProductsState]
  );

  const setRealTimeProductsTimeline = useCallback(
    (timeline) => {
      const allProductsWithIds = getAllProductsTimestamps(timeline);

      setRealTimeProductsState((previousState) => ({
        ...previousState,
        timeline,
        allProductsWithIds,
      }));
    },
    [setRealTimeProductsState]
  );

  const setShowCasing = useCallback(
    (showCasing) => {
      if (!Array.isArray(showCasing)) return;
      setRealTimeProductsState((previousState) => ({
        ...previousState,
        showCasing,
      }));
    },
    [setRealTimeProductsState]
  );

  return {
    realTimeProductsState,
    setTimelineLoading,
    setRealTimeProductsTimeline,
    setShowCasing,
  };
};
